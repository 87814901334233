import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../my-jobs.css";
import AppContext from "../../../context/app-context.ts";
import { IoMdArrowBack } from "react-icons/io";
import { TACompletedJob } from "../../../context/interface.ts";
import { TMyJobNavs } from "../my-jobs.tsx";
import { FiRefreshCcw } from "react-icons/fi";
import { ClaimsTable } from "../../../components/claims-table/claims-table.tsx";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TMyJobNavs>>;
  display: boolean;
  screen: TMyJobNavs;
};

type TClaimsTableMenu = "All" | "Approved" | "Pending" | "Rejected" | "Duplicated" | "";

export const ClaimsTableScreen = function ({ display, setScreen, screen }: Props) {
  const [page, setPage] = useState(0);
  const [completedJobData, setCompletedJobData] = useState<TACompletedJob[]>([]);
  const [originalData, setOriginalData] = useState<TACompletedJob[]>([]);

  const [claimsTableActive, setclaimsTableActive] = useState<TClaimsTableMenu>("All");

  const { openNotification, setIsLoading, usersData } = useContext(AppContext);

  useEffect(() => {
    getCompletedJobs();
  }, [screen?.data, screen?.screen, usersData]);

  async function getCompletedJobs() {
    if (!screen?.data || screen?.screen !== "claims-table") return;
    try {
      setPage(0);
      setclaimsTableActive("All");
      setIsLoading(true);

      const foundJob = usersData?.jobs?.find((e) => e?._id === screen?.data);

      setCompletedJobData(foundJob!.completed);
      setOriginalData(foundJob!.completed);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification(
        "We are unable to fetch the details of your claims. Try again after some time."
      );
    }
  }

  if (!display) return <></>;

  return (
    <div className="screen-container job-list-container">
      <p
        className="small cursor fix-back-button"
        onClick={() => {
          setScreen({
            screen: "home",
            data: null,
          });
          setCompletedJobData([]);
        }}
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <div className="flex-row space-between" style={{ alignItems: "flex-end" }}>
        <h3 className="vbig-2 removemargin blackText">Claims Submitted</h3>
        <div className="myjob-details-card-container submit-new-claim-alert-box">
          <p className="small boldText blackText">Submit a New Job Claim</p>
          <button
            className="themeBtn"
            onClick={() =>
              setScreen({
                screen: "submit-claim",
                data: screen?.data,
              })
            }
          >
            Proceed
          </button>
        </div>
      </div>

      <div className="myjobs-table-container">
        <div className="table-utilities jobs-table-utilities">
          <div className="table-utilities-extra">
            <div className="flex-row claims-table-nav">
              <p
                className={`claims-table-nav-item makeTextALittleBigger boldText ${
                  claimsTableActive === "All" && "claims-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setclaimsTableActive("All");
                  setPage(0);
                  setCompletedJobData(originalData);
                }}
              >
                All <span className="no-bold">({originalData?.length})</span>
              </p>

              <p
                style={{ color: "#07B93980" }}
                className={`claims-table-nav-item makeTextALittleBigger boldText ${
                  claimsTableActive === "Approved" && "claims-table-nav-item-active-Approved"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setclaimsTableActive("Approved");
                  setPage(0);
                  setCompletedJobData(originalData?.filter((e) => e?.status === "approved"));
                }}
              >
                Approved{" "}
                <span className="no-bold">
                  ({originalData?.filter((e) => e?.status === "approved")?.length})
                </span>
              </p>
              <p
                style={{ color: "#F1C21B" }}
                className={`claims-table-nav-item makeTextALittleBigger boldText ${
                  claimsTableActive === "Pending" && "claims-table-nav-item-active-Pending"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setclaimsTableActive("Pending");
                  setPage(0);
                  setCompletedJobData(originalData?.filter((e) => e?.status === "pending"));
                }}
              >
                Pending{" "}
                <span className="no-bold">
                  ({originalData?.filter((e) => e?.status === "pending")?.length})
                </span>
              </p>
              <p
                style={{ color: "#EE544E" }}
                className={`claims-table-nav-item makeTextALittleBigger ${
                  claimsTableActive === "Rejected" && "claims-table-nav-item-active-Rejected"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setclaimsTableActive("Rejected");
                  setPage(0);
                  setCompletedJobData(originalData?.filter((e) => e?.status === "cancelled"));
                }}
              >
                Rejected{" "}
                <span className="no-bold">
                  ({originalData?.filter((e) => e?.status === "cancelled")?.length})
                </span>
              </p>
              <p
                className={`claims-table-nav-item makeTextALittleBigger ${
                  claimsTableActive === "Duplicated" && "claims-table-nav-item-active-Duplicated"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setclaimsTableActive("Duplicated");
                }}
              >
                Duplicated <span className="no-bold">(0)</span>
              </p>
            </div>
          </div>
          <div className="table-utilities-icons">
            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={() => getCompletedJobs()}
            />
          </div>
        </div>
        <ClaimsTable page={page} setPage={setPage} data={completedJobData} setScreen={setScreen} />
      </div>
    </div>
  );
};
