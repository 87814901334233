import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../banks.css";
import AppContext from "../../../context/app-context.ts";
import { TBankNavs } from "../banks.tsx";
import { IoMdArrowBack } from "react-icons/io";
import { IJobs } from "../../../context/interface.ts";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TBankNavs>>;
  display: boolean;
  screen: TBankNavs;
};

export const SubmitJobClaim = function ({ display, setScreen, screen }: Props) {
  const [jobsData, setJobsData] = useState<IJobs>({} as IJobs);

  const { backendServer, openNotification, setIsLoading } = useContext(AppContext);

  useEffect(() => {
    getJobDetails();
  }, [screen?.data, screen?.screen]);

  async function getJobDetails() {
    if (!screen?.data || screen?.screen !== "job-claim") return;
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/jobs/get?id=${screen?.data?.child_job}`, {
          method: "GET",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setJobsData(returned?.data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification(
        "We are unable to fetch the details for ths job at the moment. Try again in a few minutes."
      );
    }
  }

  if (!display) return <></>;

  return (
    <div className="screen-container job-list-container">
      <p
        className="small cursor fix-back-button"
        onClick={() =>
          setScreen({
            screen: "job-details",
            data: screen?.data?.parent_job,
          })
        }
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <h3 className="vbig-2">Submit Claim for {jobsData?.job_name}</h3>

      <p className="small greyText">Please upload the proof of completed jobs for verification.</p>
    </div>
  );
};
