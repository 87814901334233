import { TAppPlans } from "../../context/interface";
import "../../styles/index.css";
import "./subscriptions-modal.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

type Prop = {
  setShowTermsOFServiceModal: React.Dispatch<React.SetStateAction<boolean>>;
  showTermsOfServiceModal: boolean;
  plan?: TAppPlans;
  proceedBtn: React.ReactNode;
};

export const SubscriptionTermsOfServicesModal = function ({
  showTermsOfServiceModal,
  setShowTermsOFServiceModal,
  plan,
  proceedBtn,
}: Prop) {
  return (
    <div
      className="modal-container"
      style={{ display: `${showTermsOfServiceModal ? "flex" : "none"}` }}
    >
      <div className="modal-body subscription-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => setShowTermsOFServiceModal(false)}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="subscription-modal-cont sub-terms-of-service-modal">
          <h3 className="vbig blackText boldText">Terms of Service</h3>
          <div>
            <section>
              <h3 className="big blackText">1. Introduction</h3>
              <p className="small">
                Welcome to Direct Digital Sales Agent ("Company", "we", "our", "us"), a subsidiary
                of D-Degree Digital Hub. These Terms of Service ("Terms") govern your use of our
                software platform ("Platform") and the services provided through it ("Services"). By
                accessing or using our Platform, you agree to be bound by these Terms. If you do not
                agree to these Terms, please do not use our Platform.
              </p>
            </section>
            <section>
              <h3 className="big blackText">2. Definitions</h3>
              <ul>
                <li className="small">
                  <strong className="small">Agent</strong>: An individual or entity that subscribes
                  to our Platform to access and perform various jobs for our clients.
                </li>
                <li className="small">
                  <strong className="small">Client</strong>: Banks, insurance companies, and other
                  entities that use our Platform to post jobs.
                </li>
                <li className="small">
                  <strong className="small">Subscription Plans</strong>: The different levels of
                  access to our Platform, including Basic, Premium, and Ultimate.
                </li>
              </ul>
            </section>
            <section>
              <h3 className="big blackText">3. Eligibility</h3>
              <p className="small">
                To use our Platform, you must be at least 18 years old and capable of entering into
                a legally binding agreement. By using our Platform, you represent and warrant that
                you meet these requirements.
              </p>
            </section>
            <section>
              <h3 className="big blackText">4. Subscription Plans</h3>
              <p className="small">We offer three subscription plans:</p>
              <ul>
                <li className="small">
                  <strong className="small">Basic</strong>: Limited access to jobs and features.
                </li>
                <li className="small">
                  <strong className="small">Premium</strong>: Extended access to jobs and additional
                  features.
                </li>
                <li className="small">
                  <strong className="small">Ultimate</strong>: Full access to all jobs and features.
                </li>
              </ul>
            </section>
            <section>
              <h3 className="big blackText">5. Account Registration</h3>
              <p className="small">
                To access our Platform, you must create an account. You agree to provide accurate,
                current, and complete information during the registration process and to update such
                information to keep it accurate, current, and complete. You are responsible for
                safeguarding your password and for all activities that occur under your account.
              </p>
            </section>
            <section>
              <h3 className="big blackText">6. Payment Terms</h3>
              <ul>
                <li className="small">
                  <strong className="small">Fees</strong>: Subscription fees are billed on a monthly
                  or annual basis, as selected by you.
                </li>
                <li className="small">
                  <strong className="small">Payment Method</strong>: You must provide a valid
                  payment method. By providing a payment method, you authorize us to charge the
                  applicable subscription fees to your payment method.
                </li>
                <li className="small">
                  <strong className="small">Non-Refundable</strong>: All fees are non-refundable,
                  except as required by law.
                </li>
              </ul>
            </section>
            <section>
              <h3 className="big blackText">7. Use of the Platform</h3>
              <p className="small">
                You agree to use the Platform in accordance with these Terms and all applicable laws
                and regulations. You agree not to:
              </p>
              <ul>
                <li className="small">Use the Platform for any unlawful purpose.</li>
                <li className="small">Interfere with or disrupt the operation of the Platform.</li>
                <li className="small">
                  Attempt to gain unauthorized access to the Platform or its related systems or
                  networks.
                </li>
              </ul>
            </section>
            <section>
              <h3 className="big blackText">8. Intellectual Property</h3>
              <p className="small">
                All content, trademarks, service marks, logos, and other intellectual property
                displayed on the Platform are the property of the Company or its licensors. You
                agree not to use any of our intellectual property without our prior written consent.
              </p>
            </section>
            <section>
              <h3 className="big blackText">9. Termination</h3>
              <p className="small">
                We may terminate or suspend your account and access to the Platform at any time,
                without prior notice or liability, for any reason, including if you breach these
                Terms. Upon termination, your right to use the Platform will immediately cease.
              </p>
            </section>
            <section>
              <h3 className="big blackText">10. Limitation of Liability</h3>
              <p className="small">
                To the maximum extent permitted by law, the Company shall not be liable for any
                indirect, incidental, special, consequential, or punitive damages, or any loss of
                profits or revenues, whether incurred directly or indirectly, or any loss of data,
                use, goodwill, or other intangible losses, resulting from (i) your use or inability
                to use the Platform; (ii) any unauthorized access to or use of our servers and/or
                any personal information stored therein; (iii) any interruption or cessation of
                transmission to or from the Platform.
              </p>
            </section>
            <section>
              <h3 className="big blackText">11. Governing Law</h3>
              <p className="small">
                These Terms shall be governed and construed in accordance with the existing laws,
                without regard to its conflict of law provisions.
              </p>
            </section>
            <section>
              <h3 className="big blackText">12. Changes to Terms</h3>
              <p className="small">
                We reserve the right to modify or replace these Terms at any time. If a revision is
                material, we will provide at least 30 days' notice prior to any new terms taking
                effect. What constitutes a material change will be determined at our sole
                discretion.
              </p>
            </section>
            <section>
              <h3 className="big blackText">13. Contact Us</h3>
              <p className="small">
                If you have any questions about these Terms, please contact us at
                info@d-degreedigital.com.
              </p>
            </section>
          </div>

          <div className="flex-row sub-terms-of-services-actions">
            <button
              onClick={() => setShowTermsOFServiceModal(false)}
              className="themeBtn whiteBg blackText themeBorder"
              style={{ border: "1.42px solid #4F4F4F" }}
            >
              Cancel
            </button>
            {proceedBtn}
          </div>
        </div>
      </div>
    </div>
  );
};
