import React from "react";
import "../../styles/index.css";
import "./stat-card.css";

type Prop = {
  actionPlan: React.ReactNode;
  count: string | number;
  iconSrc: string;
};

export const StatCard = function ({ actionPlan, count, iconSrc }: Prop) {
  return (
    <div className="flex-column a-stat-card-container">
      <img
        className="stat-card-container-icon"
        src={iconSrc}
        alt="ddsa-icons"
        title="Pending Jobs"
      />

      <h3 className="vbig">{count}</h3>

      {actionPlan}
    </div>
  );
};
