import { TAWalletTransaction } from "../../context/interface";
import { capitalizeEachWord, formatNumber, getDateValue } from "../../utils";

const columns: any = [
  { id: "transaction_type", label: "Transaction Type", minWidth: 120 },
  {
    id: "job_type",
    label: "Job Type (ID)",
    minWidth: 150,
    align: "left",
  },
  {
    id: "initiated_on",
    label: "Date",
    minWidth: 120,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
  {
    id: "amount",
    label: "Earning (Cr)",
    minWidth: 100,
    align: "center",
  },
];

function sortData(data?: TAWalletTransaction[]) {
  if (!data) return [];
  const finalArray = data?.map((e: TAWalletTransaction) => {
    return {
      transaction_type: e?.mode === "job" ? "Credit" : capitalizeEachWord(e?.mode),
      job_type: e?.paymentRef?.reference?.job_name ? (
        <span>
          {e?.paymentRef?.reference?.job_name}
          <br></br>/ {e?.paymentRef?.reference?.job_id}
        </span>
      ) : (
        "-"
      ),
      initiated_on: getDateValue(e?.initiated_on),
      status: (
        <div
          className={`${
            e?.status === "completed"
              ? "t-status-approved"
              : e?.status === "pending"
              ? "t-status-pending"
              : "t-status-rejected"
          }`}
        >
          <div>{`${
            e?.status === "completed"
              ? "Successful"
              : e?.status === "pending"
              ? "Pending"
              : "Failed"
          }`}</div>
        </div>
      ),
      amount: formatNumber(e?.amount),
    };
  });

  return finalArray;
}

export { sortData, columns };
