import { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../wallet.css";
import AppContext from "../../../context/app-context.ts";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FloralDivider } from "../../../components/floral-divider/floral-divider.tsx";
import { WalletTransactionTable } from "../../../components/wallet-transaction-table/wallet-transaction-table.tsx";
import { CSVLink } from "react-csv";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import APopupModal from "../../../components/a-typical-popup-container/a-typical-popup-container.tsx";
import { Radio } from "antd";
import { IoOptionsOutline } from "react-icons/io5";
import BankAccountsModal, {
  TBankModalActivity,
} from "../../../components/bank-list-modal/bank-list-modal.tsx";
import {
  capitalizeEachWord,
  formatNumber,
  getDateValue,
  getTotalNumberFromObjectArray,
  padAccountNumber,
} from "../../../utils/index.ts";
import { TAWalletTransaction } from "../../../context/interface.ts";
import { TWalletNavs } from "../wallet.tsx";
import { GoArrowUpRight } from "react-icons/go";

type Props = {
  display: boolean;
  setScreen: React.Dispatch<React.SetStateAction<TWalletNavs>>;
};

type TtableMenu = "All" | "Pending" | "Failed" | "Successful";
export type TWalletTrasactionFilters =
  | "Bonus/Others"
  | "Withdrawals"
  | "Reversal"
  | "Credit"
  | "none";

export const WalletHome = function ({ display, setScreen }: Props) {
  const [page, setPage] = useState(0);
  const [transactionTableActive, setTransactionTableActive] = useState<TtableMenu>("All");

  const [bankAccountModal, setBankAccountModal] = useState<TBankModalActivity>({
    display: false,
    data: [],
  });
  const [showFilters, setShowFilters] = useState({
    transaction_type: {
      show: true,
      filtersState: "none" as TWalletTrasactionFilters,
    },
  });
  const [originalData, setOriginalData] = useState<TAWalletTransaction[]>([]);
  const [walletTableData, setWalletTableData] = useState<TAWalletTransaction[]>([]);
  const [csvData, setcsvData] = useState<any[]>([]);

  const { usersData, openNotification } = useContext(AppContext);

  function resetAllState() {
    setTransactionTableActive("All");
    setShowFilters({
      transaction_type: {
        show: true,
        filtersState: "none",
      },
    });

    const reversed = [...usersData?.wallet?.transactions].reverse();

    setOriginalData(reversed);
    setWalletTableData(reversed);
    workCSV(reversed);
  }

  useEffect(() => {
    resetAllState();
  }, [usersData]);

  function runFilter() {
    let data = [...originalData];

    if (transactionTableActive === "All") {
      data = [...originalData];
    }

    if (transactionTableActive === "Failed") {
      data = data?.filter((e) => e.status === "cancelled");
    }

    if (transactionTableActive === "Pending") {
      data = data?.filter((e) => e.status === "pending");
    }

    if (transactionTableActive === "Successful") {
      data = data?.filter((e) => e.status === "completed");
    }

    if (showFilters?.transaction_type?.filtersState === "Credit") {
      data = data?.filter((e) => e.mode === "job");
    }
    if (showFilters?.transaction_type?.filtersState === "Bonus/Others") {
      data = data?.filter((e) => e.mode === "bonus");
    }
    if (showFilters?.transaction_type?.filtersState === "Reversal") {
      data = data?.filter((e) => e.mode === "others");
    }
    if (showFilters?.transaction_type?.filtersState === "Withdrawals") {
      data = data?.filter((e) => e.mode === "withdrawal");
    }

    setWalletTableData(data);
    workCSV(data);
  }

  useEffect(() => {
    runFilter();
  }, [transactionTableActive, showFilters?.transaction_type]);

  function workCSV(data: any) {
    setcsvData([
      ["transaction_type", "job_type", "initiated_on", "status", "amount"],
      ...data?.map(({ mode, paymentRef, initiated_on, status, amount }: any) => {
        return [
          mode === "job" ? "Credit" : capitalizeEachWord(mode),
          paymentRef?.reference?.job_name
            ? `${paymentRef?.reference?.job_name} / ${paymentRef?.reference?.job_id}`
            : "-",
          getDateValue(initiated_on),
          status === "completed" ? "Successful" : status === "pending" ? "Pending" : "Failed",
          formatNumber(amount),
        ];
      }),
    ]);
  }

  if (!display) return <></>;

  return (
    <div className="screen-container wallet-container">
      <div className="flex-row space-between small-gap wallet-intro-container">
        <div className="wa-stat-left">
          <div className="flex-column wa-stat-left-inner-one">
            <p className="small whiteText removemargin">Total Balance</p>
            <p className="vbig boldText whiteText removemargin">₦{usersData?.wallet?.balance}</p>
          </div>

          <div className="flex-column wa-stat-left-inner-two">
            <div>
              <p className="small whiteText removemargin" style={{ textAlign: "right" }}>
                Bank Account
              </p>
              <p
                onClick={() => setBankAccountModal({ display: true, data: [] })}
                style={{ textAlign: "right" }}
                className="small boldText whiteText removemargin cursor"
              >
                {usersData?.bank_accounts?.length
                  ? padAccountNumber(
                      usersData?.bank_accounts?.find((e) => e?.is_primary)?.account_number
                    ) ||
                    padAccountNumber(
                      usersData?.bank_accounts?.find((e) => e?.account_number)?.account_number
                    )
                  : "Add Bank Account"}{" "}
                <MdOutlineKeyboardArrowDown size={20} />
              </p>
            </div>

            <button
              className="themeBtn"
              style={{ background: "#E70A02", padding: "0px 45px 0px 45px" }}
            >
              Withdraw
            </button>
          </div>
        </div>

        <div className="flex-row wa-stat-right">
          <div className="flex-column wa-stat-card">
            <img
              className="wa-stat-card-container-icon"
              src={"/images/dashboard/tjd.png"}
              alt="ddsa-icons"
              title="Total Earned"
            />

            <h3 className="big boldText">
              ₦
              {formatNumber(
                getTotalNumberFromObjectArray(
                  usersData?.wallet?.transactions?.filter(
                    (e: TAWalletTransaction) => e?.transaction_type === "credit"
                  ),
                  "amount"
                )
              )}
            </h3>

            <p className="small">Total Earned</p>
          </div>

          <div className="flex-column wa-stat-card">
            <img
              className="wa-stat-card-container-icon"
              src={"/images/dashboard/tjd.png"}
              alt="ddsa-icons"
              title="Recent Withdrawal"
            />

            <h3 className="big boldText">
              ₦
              {formatNumber(
                getTotalNumberFromObjectArray(
                  usersData?.wallet?.transactions?.filter(
                    (e: TAWalletTransaction) =>
                      e?.transaction_type === "debit" && e?.mode === "withdrawal"
                  ),
                  "amount"
                )
              )}
            </h3>

            <p className="small">Recent Withdrawal</p>
          </div>
        </div>
      </div>

      <p
        onClick={() => {
          if (!usersData?.billing_details?.subscription_code)
            return openNotification("You have no active subscription.");
          setScreen({ screen: "subscriptions", data: null });
        }}
        className="biggerText blueText cursor"
        style={{ marginTop: 50 }}
      >
        Manage Subscriptions <GoArrowUpRight size={20} />
      </p>

      <FloralDivider
        customStyles={{ justifyContent: "flex-start", backgroundImage: "none" }}
        header={"Transaction History"}
      />

      <div className="w-table-container">
        <div className="table-utilities">
          <div className="table-utilities-extra">
            <div className="flex-row wallet-table-nav">
              <p
                className={`wallet-table-nav-item makeTextALittleBigger cursor ${
                  transactionTableActive === "All" && "wallet-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setTransactionTableActive(e.currentTarget.textContent as TtableMenu);
                }}
              >
                All
              </p>
              <p
                className={`wallet-table-nav-item makeTextALittleBigger cursor ${
                  transactionTableActive === "Pending" && "wallet-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setTransactionTableActive(e.currentTarget.textContent as TtableMenu);
                }}
              >
                Pending
              </p>
              <p
                className={`wallet-table-nav-item makeTextALittleBigger cursor ${
                  transactionTableActive === "Failed" && "wallet-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setTransactionTableActive(e.currentTarget.textContent as TtableMenu);
                }}
              >
                Failed
              </p>
              <p
                className={`wallet-table-nav-item makeTextALittleBigger cursor ${
                  transactionTableActive === "Successful" && "wallet-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setTransactionTableActive(e.currentTarget.textContent as TtableMenu);
                }}
              >
                Successful
              </p>
            </div>
          </div>
          <div className="table-utilities-icons">
            <APopupModal
              trigger="click"
              button={
                <IoOptionsOutline
                  className="cursor"
                  title="Filter Data"
                  size={25}
                  onClick={() => {}}
                />
              }
              content={
                <div className="flex-column wallet-table-sort-dropdown">
                  <p
                    className="makeTextALittleBigger removemargin boldText cursor"
                    onClick={() =>
                      setShowFilters((p) => ({
                        ...p,
                        transaction_type: {
                          ...p?.transaction_type,
                          show: !p?.transaction_type?.show,
                        },
                      }))
                    }
                  >
                    Transaction Type &nbsp;
                    <MdOutlineKeyboardArrowDown size={25} style={{ marginTop: -3 }} />
                  </p>
                  {showFilters?.transaction_type?.show && (
                    <div className="flex-column wallet-table-sort-dropdown-radios">
                      <Radio
                        checked={showFilters?.transaction_type?.filtersState === "Credit"}
                        onClick={() =>
                          setShowFilters((p) => ({
                            ...p,
                            transaction_type: {
                              ...p?.transaction_type,
                              filtersState: "Credit",
                            },
                          }))
                        }
                        className="small"
                      >
                        Credit
                      </Radio>
                      <Radio
                        checked={showFilters?.transaction_type?.filtersState === "Reversal"}
                        onClick={() =>
                          setShowFilters((p) => ({
                            ...p,
                            transaction_type: {
                              ...p?.transaction_type,
                              filtersState: "Reversal",
                            },
                          }))
                        }
                        className="small"
                      >
                        Reversal
                      </Radio>
                      <Radio
                        checked={showFilters?.transaction_type?.filtersState === "Withdrawals"}
                        onClick={() =>
                          setShowFilters((p) => ({
                            ...p,
                            transaction_type: {
                              ...p?.transaction_type,
                              filtersState: "Withdrawals",
                            },
                          }))
                        }
                        className="small"
                      >
                        Withdrawals
                      </Radio>
                      <Radio
                        checked={showFilters?.transaction_type?.filtersState === "Bonus/Others"}
                        onClick={() =>
                          setShowFilters((p) => ({
                            ...p,
                            transaction_type: {
                              ...p?.transaction_type,
                              filtersState: "Bonus/Others",
                            },
                          }))
                        }
                        className="small"
                      >
                        Bonus/Others
                      </Radio>
                    </div>
                  )}
                </div>
              }
            />

            <CSVLink className="cursor small csv-link" filename={`Transaction Data`} data={csvData}>
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>

            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={resetAllState}
            />
          </div>
        </div>
        <WalletTransactionTable page={page} setPage={setPage} data={walletTableData} />
      </div>

      <BankAccountsModal
        bankAccountModal={bankAccountModal}
        setBankAccountModal={setBankAccountModal}
      />
    </div>
  );
};
