import { IBanks } from "../../context/interface";
import { CiLock, CiUnlock } from "react-icons/ci";

const columns: any = [
  { id: "id", label: "", minWidth: 0 },
  { id: "logo", label: "", minWidth: 120 },
  { id: "bank_name", label: "Bank Name", minWidth: 120 },
  {
    id: "jobs",
    label: "Available Jobs",
    minWidth: 150,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    align: "left",
  },
];

function sortData(data?: IBanks[]) {
  if (!data) return [];
  const finalArray = data?.map((e: IBanks) => {
    return {
      id: <span style={{ display: "none" }}>{e?._id}</span>,
      logo: <img className="banks-logo" title={e?.bank_name} src={e?.logo} alt="DDSA Bank Logo" />,
      bank_name: e?.bank_name,
      jobs: e?.jobs?.length,
      status: e?.is_active ? (
        <CiUnlock data-active={e?.is_active} color="#0D17174D" size={20} />
      ) : (
        <CiLock data-active={e?.is_active} color="#2b2d2d" size={20} />
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
