import "../../styles/index.css";
import "./bank-list-modal.css";
import { TABankAccount } from "../../context/interface";
import { TEditBankAccountModalActivity } from "./edit-a-bank-modal";
import AppContext from "../../context/app-context";
import { useContext } from "react";

type Prop = {
  data: TABankAccount;
  setEditBankModal: React.Dispatch<React.SetStateAction<TEditBankAccountModalActivity>>;
};

const BankAccountCard = function ({ data, setEditBankModal }: Prop) {
  const { backendServer, setIsLoading, openNotification, usersData, updateUsersData } =
    useContext(AppContext);

  async function setBankAccountAsPrimary() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/users/bank/update/${usersData?._id}/${data?._id}`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ddsatoken") || "",
          },
          body: JSON.stringify({
            is_primary: true,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      updateUsersData();

      openNotification("This bank account has been set as primary...", "Update successful!");

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification("We can't update your bank details right now. Try again after some time.");
    }
  }

  async function deleteBankAccount() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/users/bank/delete/${usersData?._id}/${data?._id}`, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      updateUsersData();
      openNotification("Account deleted!");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification("We can't update your bank details right now. Try again after some time.");
    }
  }

  return (
    <div className="a-bank-card-cont">
      <div className="a-bank-card-cont-left">
        <div className="bank-account-status-cont">
          {data?.is_primary && (
            <img
              title="Primary Account"
              src="/images/icons/verified-icon.png"
              alt="DDSA Verified"
            />
          )}{" "}
          &nbsp;
          {data?.is_primary ? (
            <div className="account-type-status-primary">Primary Account</div>
          ) : (
            <div className="account-type-status-normal">Secondary Account</div>
          )}
        </div>
        <p className="removemargin">
          <span className="biggerText removemargin blackText">{data?.bank_name}</span> <br></br>
          <span className="small removemargin">{data?.account_number}</span>
          <br></br>
          <span className="small removemargin">{data?.account_name}</span>
        </p>
      </div>

      <div className="a-bank-card-cont-right">
        {!data?.is_primary && (
          <span onClick={setBankAccountAsPrimary} className="account-type-status-normal bl-sep cursor">
            Set as Primary
          </span>
        )}
        <img
          className="cursor"
          title="Edit Account"
          src="/images/icons/edit-icon.png"
          alt="Edit Bank Account"
          onClick={() =>
            setEditBankModal({
              display: true,
              data: data,
            })
          }
        />
        <img
          onClick={deleteBankAccount}
          className="cursor"
          title="Delete Account"
          src="/images/icons/delete-icon.png"
          alt="Delete Bank Account"
        />
      </div>
    </div>
  );
};

export default BankAccountCard;
