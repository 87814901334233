import { useState } from "react";
import "../../styles/index.css";
import "./wallet.css";
import { WalletHome } from "./screens/home.tsx";
import { WalletManageSubscriptions } from "./screens/subscriptions.tsx";

export type WalletScreenOptions = "home" | "subscriptions";

export type TWalletNavs = {
  screen: WalletScreenOptions;
  data: any;
};

export const Wallet = function ({ display }: { display: boolean }) {
  const [screen, setScreen] = useState<TWalletNavs>({
    screen: "home",
    data: null,
  });

  if (!display) return;

  return (
    <div>
      <WalletHome setScreen={setScreen} display={screen.screen === "home" && true} />
      <WalletManageSubscriptions
        setScreen={setScreen}
        display={screen.screen === "subscriptions" && true}
      />
    </div>
  );
};
