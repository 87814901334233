import { TACompletedJob } from "../../context/interface";
import { getDateValue, shortenText } from "../../utils";
import APopupModal from "../a-typical-popup-container/a-typical-popup-container";

const columns: any = [
  { id: "id", label: "", minWidth: 0 },
  { id: "total_number_claimed_done", label: "Total Done", minWidth: 100 },
  { id: "createdAt", label: "Date Submitted", minWidth: 120 },
  {
    id: "completedAt",
    label: "Date Approved",
    minWidth: 120,
    align: "left",
  },
  {
    id: "admin_comment",
    label: "Admin Comment",
    minWidth: 150,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "left",
  },
];

function sortData(data?: TACompletedJob[]) {
  if (!data) return [];
  const finalArray = data?.map((e: TACompletedJob) => {
    return {
      id: <span style={{ display: "none" }}>{e?._id}</span>,
      total_number_claimed_done: e?.total_number_claimed_done,
      createdAt: getDateValue(e?.createdAt),
      completedAt: getDateValue(e?.completedAt) || "-",
      admin_comment: (
        <div className="normal-flex-row">
          <APopupModal
            button={
              <div className="normal-flex-row">
                <p className="small removemargin" style={{ textAlign: "center" }}>
                  {shortenText({ text: e?.admin_comment || "", maxLen: 30 }).text || "-"}
                </p>
              </div>
            }
            content={
              <div className="popup-common-filter-dropdown claims-table-popup">
                <div className="normal-flex-column small-gap">
                  <span className="small">{e?.admin_comment}</span>
                </div>
              </div>
            }
            showArrow={true}
            trigger="hover"
          />
        </div>
      ),
      status:
        e?.status === "approved" ? (
          <div className="flex-column">
            <img src="/images/icons/approved.png" alt="DDSA Claims" />
            <span className="small greyText">Approved</span>
          </div>
        ) : e?.status === "pending" ? (
          <div className="flex-column">
            <img src="/images/icons/review.png" alt="DDSA Claims" />
            <span className="small greyText">In Review</span>
          </div>
        ) : (
          <div className="flex-column">
            <img src="/images/icons/rejected.png" alt="DDSA Claims" />
            <span className="small greyText">Rejected</span>
          </div>
        ),
    };
  });

  return finalArray;
}

export { sortData, columns };
