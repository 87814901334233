import { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../my-jobs.css";
import { TMyJobNavs } from "../my-jobs.tsx";
import AppContext from "../../../context/app-context.ts";
import { TAJob } from "../../../context/interface.ts";
import {
  capitalizeEachWord,
  getDateValue,
  getLatestDate,
  patternMatching,
} from "../../../utils/index.ts";
import { IoIosSearch } from "react-icons/io";
import { CSVLink } from "react-csv";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { MyJobsTable } from "../../../components/my-jobs-table/my-jobs-table.tsx";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TMyJobNavs>>;
  display: boolean;
  screen: TMyJobNavs;
};

export const Home = function ({ display, setScreen, screen }: Props) {
  const [page, setPage] = useState(0);
  const [originalData, setOriginalData] = useState<TAJob[]>([]);
  const [csvData, setcsvData] = useState<any[]>([]);
  const [usersJobs, setUsersJobs] = useState<TAJob[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const {
    usersData,
    setIsLoading,
    backendServer,
    openNotification,
    changeScreen,
    contextBanks,
    getBasedOnSectorReferenceSmart,
  } = useContext(AppContext);

  useEffect(() => {
    getUsersJob();
  }, [contextBanks, display]);

  async function getUsersJob() {
    try {
      if (!display || !contextBanks?.length) return;
      setPage(0);
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/users/get?email=${usersData?.email}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setUsersJobs(returned?.data?.jobs);
      setOriginalData(returned?.data?.jobs);
      workCSV(returned?.data?.jobs);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification("We are unable to fetch the your jobs right now. Please try again.");
    }
  }

  async function searchPlease(e: any) {
    setPage(0);
    const query = e.target.value;

    if (!query) {
      setUsersJobs(originalData);
      workCSV(originalData);
      return;
    }

    const foundJobs = originalData?.filter((e: TAJob) => {
      return (
        patternMatching(capitalizeEachWord(query), capitalizeEachWord(e?.job_id?.job_name)) ||
        patternMatching(query, e?.job_id?.job_description) ||
        patternMatching(query, e?.job_id?.job_category) ||
        patternMatching(capitalizeEachWord(query), capitalizeEachWord(e?.job_id?.for_sector)) ||
        patternMatching(
          query,
          getBasedOnSectorReferenceSmart("banks", e?.job_id?.based_on)?.data[
            getBasedOnSectorReferenceSmart("banks", e?.job_id?.based_on)?.fieldForName
          ]
        )
      );
    });

    setUsersJobs(foundJobs);
    workCSV(foundJobs);
  }

  function workCSV(data: any) {
    setcsvData([
      [
        "Job Name",
        "Job Description",
        "Date of Last Claim Submitted",
        "Date of Last Approved Claimed",
        "Earnings (₦)",
      ],
      ...data?.map(({ completed, job_id }: any) => {
        return [
          job_id?.job_name +
            ` (${(function () {
              const fieldName =
                getBasedOnSectorReferenceSmart &&
                getBasedOnSectorReferenceSmart("banks", job_id?.based_on)?.fieldForName;

              return (
                getBasedOnSectorReferenceSmart &&
                getBasedOnSectorReferenceSmart("banks", job_id?.based_on)?.data[fieldName]
              );
            })()})`,
          job_id?.job_description,
          completed?.length
            ? getDateValue(getLatestDate(completed?.map((e: any) => e?.createdAt)))
            : "",
          completed?.length
            ? getDateValue(
                getLatestDate(
                  completed
                    ?.filter((e: any) => e?.status === "approved")
                    ?.map((e: any) => e?.createdAt)
                )
              )
            : "",
          (function () {
            let total = 0;
            usersData?.wallet?.transactions?.forEach((tr) => {
              if (
                tr?.mode === "job" &&
                tr?.transaction_type === "credit" &&
                tr?.status === "completed" &&
                tr?.paymentRef?.reference?.job_id === job_id?._id
              )
                total = total + tr?.amount;
            });
            return total;
          })(),
        ];
      }),
    ]);
  }

  if (!display) return;

  return (
    <div className="screen-container jobs-home-container">
      <h3 className="big removemargin blackText">Overview of Subscribed Jobs</h3>

      {!usersData?.jobs?.length ? (
        <div className="flex-column my-jobs-empty-cont">
          <p className="big">You Have No Subscribed Jobs</p>
          <p className="biggerText">Click to Get Started</p>
          <button onClick={() => changeScreen("banks")} className="themeBtn my-job-no-btn">
            Proceed
          </button>
        </div>
      ) : (
        ""
      )}

      <div className="myjobs-table-container">
        <div className="table-utilities jobs-table-utilities">
          <div className="table-utilities-extra">
            <p className="biggerText blackText removemargin alignTextCenter hide-in-mobile">
              Subscribed Jobs ({usersData?.jobs?.length})
            </p>
            <div className="attached-in-cont-with-icon-no-button jobs-table-search-input">
              <div>
                <IoIosSearch />
              </div>
              <input
                type="text"
                className="in"
                placeholder="Search jobs..."
                onChange={(e) => {
                  setSearchValue(e?.target?.value);
                  searchPlease(e);
                }}
                value={searchValue}
              />
            </div>
          </div>
          <div className="table-utilities-icons">
            <CSVLink className="cursor small csv-link" filename={`Your Jobs`} data={csvData}>
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>

            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={() => getUsersJob()}
            />
          </div>
        </div>
        <MyJobsTable page={page} setPage={setPage} data={usersJobs} setScreen={setScreen} />
      </div>
    </div>
  );
};
