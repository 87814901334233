import { useContext, useEffect, useState } from "react";
import "../../styles/index.css";
import "./dashboard.css";
import AppContext from "../../context/app-context.ts";
import { VerificationIndicators } from "../../components/verification-indicators/verification-indicators.tsx";
import { StatCard } from "../../components/stat-card/stat-card.tsx";
import { FloralDivider } from "../../components/floral-divider/floral-divider.tsx";
import { LineChart } from "../../components/dashboard-line-chart/dashboard-line-chart.tsx";
import { dashboardLineChartData } from "./line-chart-data.ts";
import APopupModal from "../../components/a-typical-popup-container/a-typical-popup-container.tsx";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { formatNumber, getTotalNumberFromObjectArray } from "../../utils/index.ts";
import { TACompletedJob, TAJob, TAWalletTransaction } from "../../context/interface.ts";

export const Dashboard = function ({ display }: { display?: boolean }) {
  const [totals, setTotals] = useState({
    pending: 0,
    succesful: 0,
    all: 0,
  });

  const { usersData, contextBanks, getBasedOnSectorReference } = useContext(AppContext);

  useEffect(() => {
    setTotals((p) => ({
      ...p,
      all: getTotalNumberFromObjectArray(
        usersData?.jobs?.flatMap((e) => e?.completed),
        "total_number_claimed_done"
      ),
    }));

    setTotals((p) => ({
      ...p,
      pending: getTotalNumberFromObjectArray(
        usersData?.jobs
          ?.flatMap((e: TAJob) => e?.completed)
          ?.filter((e: TACompletedJob) => e.status === "pending"),
        "total_number_claimed_done"
      ),
    }));

    setTotals((p) => ({
      ...p,
      succesful: getTotalNumberFromObjectArray(
        usersData?.jobs
          ?.flatMap((e: TAJob) => e?.completed)
          ?.filter((e: TACompletedJob) => e.status === "approved"),
        "total_number_claimed_done"
      ),
    }));
  }, [usersData, contextBanks]);

  if (!display) return <></>;

  return (
    <div className="screen-container">
      <div className="dashboard-intro-container">
        <div className="dashboard-intro-container-main">
          <div className="dsh-intro-left flex-column align-column-left">
            <h3 className="vBig blackText">
              Welcome, {usersData?.first_name}
            </h3>
            <p className="biggerText greyText boldText">Agent: {usersData?.platform_id}</p>{" "}
            <p className="small greyText boldText">
              Unlock your potential within the DDSA network. Seamlessly engage with customers,
              efficiently process transactions, and effortlessly monitor your performance. Gain
              access to all the essential tools required to excel and advance as an agent.
            </p>
          </div>

          <div className="dsh-intro-right flex-column">
            <VerificationIndicators />
          </div>
        </div>
      </div>

      <div className="flex-row ds-stat-container-row">
        <StatCard
          iconSrc="/images/dashboard/tjd.png"
          count={totals?.all}
          actionPlan={
            <APopupModal
              trigger="click"
              button={
                <p className="small cursor">
                  Total Jobs Done
                  <MdOutlineKeyboardArrowDown onClick={() => {}} size={20} />
                </p>
              }
              content={
                <div className="popup-common-filter-dropdown">
                  <div className="normal-flex-column small-gap">
                    <p
                      onClick={() => {
                        setTotals((p) => ({
                          ...p,
                          all: getTotalNumberFromObjectArray(
                            usersData?.jobs?.flatMap((e) => e?.completed),
                            "total_number_claimed_done"
                          ),
                        }));
                      }}
                      key={0}
                      className="small removemargin cursor"
                    >
                      All Jobs
                    </p>
                    {usersData?.jobs?.map((e: TAJob, i: number) => (
                      <p
                        onClick={() => {
                          setTotals((p) => ({
                            ...p,
                            all: getTotalNumberFromObjectArray(
                              usersData?.jobs
                                ?.filter(
                                  (el: TAJob) => el?.job_id?._id === e?.job_id?._id
                                )
                                ?.flatMap((e) => e?.completed),
                              "total_number_claimed_done"
                            ),
                          }));
                        }}
                        key={i + 1}
                        className="small removemargin cursor"
                      >
                        {e?.job_id?.job_name}{" "}
                        {`(${getBasedOnSectorReference("banks", e?.job_id?.based_on)?.bank_name})`}
                      </p>
                    ))}
                  </div>
                </div>
              }
            />
          }
        />
        <StatCard
          iconSrc="/images/dashboard/pj.png"
          count={totals?.pending}
          actionPlan={
            <APopupModal
              trigger="click"
              button={
                <p className="small cursor">
                  Pending Jobs
                  <MdOutlineKeyboardArrowDown onClick={() => {}} size={20} />
                </p>
              }
              content={
                <div className="popup-common-filter-dropdown">
                  <div className="normal-flex-column small-gap">
                    <p
                      onClick={() => {
                        setTotals((p) => ({
                          ...p,
                          pending: getTotalNumberFromObjectArray(
                            usersData?.jobs
                              ?.flatMap((e: TAJob) => e?.completed)
                              ?.filter((e: TACompletedJob) => e.status === "pending"),
                            "total_number_claimed_done"
                          ),
                        }));
                      }}
                      key={0}
                      className="small removemargin cursor"
                    >
                      All Jobs
                    </p>
                    {usersData?.jobs?.map((e: TAJob, i: number) => (
                      <p
                        onClick={() => {
                          setTotals((p) => ({
                            ...p,
                            pending: getTotalNumberFromObjectArray(
                              usersData?.jobs
                                ?.filter(
                                  (el: TAJob) => el?.job_id?._id === e?.job_id?._id
                                )
                                ?.flatMap((e: TAJob) => e?.completed)
                                ?.filter((e: TACompletedJob) => e.status === "pending"),
                              "total_number_claimed_done"
                            ),
                          }));
                        }}
                        key={i + 1}
                        className="small removemargin cursor"
                      >
                        {e?.job_id?.job_name}{" "}
                        {`(${getBasedOnSectorReference("banks", e?.job_id?.based_on)?.bank_name})`}
                      </p>
                    ))}
                  </div>
                </div>
              }
            />
          }
        />
        <StatCard
          iconSrc="/images/dashboard/sj.png"
          count={totals?.succesful}
          actionPlan={
            <APopupModal
              trigger="click"
              button={
                <p className="small cursor">
                  Successful Jobs
                  <MdOutlineKeyboardArrowDown onClick={() => {}} size={20} />
                </p>
              }
              content={
                <div className="popup-common-filter-dropdown">
                  <div className="normal-flex-column small-gap">
                    <p
                      onClick={() => {
                        setTotals((p) => ({
                          ...p,
                          succesful: getTotalNumberFromObjectArray(
                            usersData?.jobs
                              ?.flatMap((e: TAJob) => e?.completed)
                              ?.filter((e: TACompletedJob) => e.status === "approved"),
                            "total_number_claimed_done"
                          ),
                        }));
                      }}
                      key={0}
                      className="small removemargin cursor"
                    >
                      All Jobs
                    </p>
                    {usersData?.jobs?.map((e: TAJob, i: number) => (
                      <p
                        onClick={() => {
                          setTotals((p) => ({
                            ...p,
                            succesful: getTotalNumberFromObjectArray(
                              usersData?.jobs
                                ?.filter((el: TAJob) => el?.job_id?._id === e?.job_id?._id)
                                ?.flatMap((e: TAJob) => e?.completed)
                                ?.filter((e: TACompletedJob) => e.status === "approved"),
                              "total_number_claimed_done"
                            ),
                          }));
                        }}
                        key={i + 1}
                        className="small removemargin cursor"
                      >
                        {e?.job_id?.job_name}{" "}
                        {`(${getBasedOnSectorReference("banks", e?.job_id?.based_on)?.bank_name})`}
                      </p>
                    ))}
                  </div>
                </div>
              }
            />
          }
        />
        <StatCard
          iconSrc="/images/dashboard/wallet.png"
          count={formatNumber(
            getTotalNumberFromObjectArray(
              usersData?.wallet?.transactions?.filter(
                (e: TAWalletTransaction) => e?.transaction_type === "credit"
              ),
              "amount"
            )
          )}
          actionPlan={
            <p className="small">
              Earnings
              {/* <MdOutlineKeyboardArrowDown onClick={() => {}} size={20} /> */}
            </p>
          }
        />
      </div>

      <FloralDivider header={"Wallet Analysis"} />

      <div className="dashboard-extra-cont">
        <div className="dashboard-extra-cont-left">
          <LineChart data={dashboardLineChartData(usersData?.wallet?.transactions)} />
        </div>
        <div className="flex-column dashboard-extra-cont-right">
          <div className="flex-row">
            <p className="small removemargin makeTextALittleBigger">Total Due</p>
            <p className="small removemargin makeTextALittleBigger">N0.00</p>
          </div>
          <div className="flex-row">
            <p className="small removemargin makeTextALittleBigger">Cash Out</p>
            <p className="small removemargin makeTextALittleBigger">N0.00</p>
          </div>
          <div className="flex-row">
            <p className="small removemargin makeTextALittleBigger">Balance</p>
            <p className="small removemargin makeTextALittleBigger">
              N{formatNumber(usersData?.wallet?.balance)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
