import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../login.css";
import CountdownTimer from "./countdown";
import AppContext from "../../context/app-context";
import { AuthStages } from "../reset-password";

type Prop = {
  display: boolean;
  setStage: React.Dispatch<React.SetStateAction<AuthStages>>;
  email: string;
  stage: AuthStages;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  setCodeSentFromServer: React.Dispatch<React.SetStateAction<string>>;
  setEmail?: React.Dispatch<React.SetStateAction<string>>;
  codeSentFromServer?: string;
};

const theCode: string[] = [];

const CodeScreen = function ({
  display,
  setStage,
  email,
  stage,
  code,
  setCode,
  setCodeSentFromServer,
}: Prop) {
  const { backendServer, setIsLoading, openNotification } = useContext(AppContext);
  const [disableBtn, setDisableBtn] = useState(false);

  const [codeExpired, setCodeExpired] = useState(false);

  useEffect(() => {
    if (code.length === 4) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function testCode() {
    try {
      setIsLoading(true);
      const codeVerify = await (
        await fetch(`${backendServer}/auth/code/ver/${email}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ token: code }),
          credentials: "include",
        })
      ).json();

      if (codeVerify.status === "Internal server error") throw new Error(codeVerify.message);
      setStage("reset-password");
      setIsLoading(false);
    } catch (err: any) {
      openNotification(err.message);
      setIsLoading(false);
    }
  }

  function handleCodeChange(e: any) {
    if (!e.target.value) {
      const doc: any = document.querySelector("#codeboxcontainer");
      doc?.children[Number(e.target.dataset.key) - 1]?.children[0]?.focus();
    } else {
      const doc: any = document.querySelector("#codeboxcontainer");
      doc?.children[Number(e.target.dataset.key) + 1]?.children[0]?.focus();
    }

    theCode[e.target.dataset.key] = e.target.value;

    setCode(theCode.join(""));
  }

  const onFinish = () => {
    setCodeExpired(true);
    setCodeSentFromServer("");
  };

  if (!display) return;

  return (
    <div className="reset-password-modal-screens-container">
      <img className="rp-wavy-logo" src="/logo.png" alt="DDSA" />
      <div className="flex-row rp-code-box-edit-cont">
        <p className="big blackText boldText">Check your email address</p>
        <p className="small boldText greyText alignTextCenter">
          We sent a four digit code to {email}. Enter the code below:
        </p>
      </div>
      <div id="codeboxcontainer" className="flex-row code-box-edit-cont">
        <div className="code-boxes">
          <input
            maxLength={1}
            data-key={0}
            placeholder="*"
            type="number"
            className="ver-code-input"
            onChange={handleCodeChange}
          />
        </div>
        <div className="code-boxes">
          <input
            maxLength={1}
            data-key={1}
            placeholder="*"
            type="number"
            className="ver-code-input"
            onChange={handleCodeChange}
          />
        </div>
        <div className="code-boxes">
          <input
            maxLength={1}
            data-key={2}
            placeholder="*"
            type="number"
            className="ver-code-input"
            onChange={handleCodeChange}
          />
        </div>
        <div className="code-boxes">
          <input
            maxLength={1}
            data-key={3}
            placeholder="*"
            type="number"
            className="ver-code-input"
            onChange={handleCodeChange}
          />
        </div>
      </div>

      <button disabled={disableBtn} onClick={testCode} className="themeBtn rp-login-btn">
        Reset Password
      </button>

      <CountdownTimer stage={stage} onFinish={onFinish} />

      {codeExpired && (
        <p className="small alignTextCenter">
          Code Expired <br></br>
          <span
            onClick={() => setStage("begin-reset")}
            className="cursor alignTextCenter"
            style={{ textDecoration: "underline", textDecorationColor: "#34204c" }}
          >
            Request Another
          </span>
        </p>
      )}
    </div>
  );
};

export default CodeScreen;
