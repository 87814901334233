import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../banks.css";
import AppContext from "../../../context/app-context.ts";
import { TBankNavs } from "../banks.tsx";
import { IoMdArrowBack } from "react-icons/io";
import { JobCard } from "../../../components/job-card/job-card.tsx";
import { IJobs } from "../../../context/interface.ts";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TBankNavs>>;
  display: boolean;
  screen: TBankNavs;
};

export const JobLists = function ({ display, setScreen, screen }: Props) {
  const [jobListComponents, setjobListComponents] = useState<React.ReactNode[]>([]);

  const { backendServer, openNotification, setIsLoading } = useContext(AppContext);

  useEffect(() => {
    getBanksJobs();
  }, [screen?.data]);

  async function getBanksJobs() {
    if (!screen?.data || screen?.screen !== "bank-jobs") return;
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/jobs/get?based_on=${screen?.data}&job_listing=true`, {
          method: "GET",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setjobListComponents(
        returned?.data?.map((e: IJobs, i: number) => (
          <JobCard setScreen={setScreen} data={e} key={i} />
        ))
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification(
        "We are unable to fetch jobs for you at this moment. Try again in a few minutes."
      );
    }
  }

  if (!display) return <></>;

  return (
    <div className="screen-container job-list-container">
      <p
        className="small cursor fix-back-button"
        onClick={() => {
          setScreen({
            screen: "home",
            data: null,
          });
          setjobListComponents([]);
        }}
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <h3 className="vbig-2">Explore Our Job Plans</h3>
      <p className="small">Consider upgrading for enhanced benefits.</p>

      <div className="flex-row where-jobs-are">{jobListComponents}</div>
    </div>
  );
};
