import React, { useState } from "react";
import "../styles/index.css";
import "./login.css";
import BeginReset from "./reset-password-kids/begin-reset";
import CodeScreen from "./reset-password-kids/code-screen";
import ResetPasswordScreen from "./reset-password-kids/reset-password";
import { Props } from "./login";

export type AuthStages = "reset-password" | "code-screen" | "begin-reset";

type Prop = {
  setShowResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
} & Props;

const ResetPassword = function ({ setShowResetPassword, screen, setScreen }: Prop) {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [stage, setStage] = useState<AuthStages>("begin-reset");
  const [codeSentFromServer, setCodeSentFromServer] = useState("");

  return (
    <div className="login-container">
      <BeginReset
        setEmail={setEmail}
        email={email}
        setStage={setStage}
        display={stage === "begin-reset" && true}
        setCodeSentFromServer={setCodeSentFromServer}
        setScreen={setScreen}
        screen={screen}
        setShowResetPassword={setShowResetPassword}
      />
      <CodeScreen
        setEmail={setEmail}
        email={email}
        setStage={setStage}
        display={stage === "code-screen" && true}
        stage={stage}
        codeSentFromServer={codeSentFromServer}
        setCode={setCode}
        code={code}
        setCodeSentFromServer={setCodeSentFromServer}
        setScreen={setScreen}
        screen={screen}
      />
      <ResetPasswordScreen
        setEmail={setEmail}
        email={email}
        setStage={setStage}
        display={stage === "reset-password" && true}
        setShowResetPassword={setShowResetPassword}
        code={code}
        setScreen={setScreen}
        screen={screen}
      />

      <div className="login-container-two">
        <img className="login-banner-img" src="/images/login-banner.png" alt="DDSA" />
      </div>
    </div>
  );
};

export default ResetPassword;
