import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../banks.css";
import AppContext from "../../../context/app-context.ts";
import { TBankNavs } from "../banks.tsx";
import { IoMdArrowBack } from "react-icons/io";
import { IJobs } from "../../../context/interface.ts";
import { ResourcesTable } from "../../../components/resources-table/resources-table.tsx";
import { FiRefreshCcw } from "react-icons/fi";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TBankNavs>>;
  display: boolean;
  screen: TBankNavs;
};

export const Resources = function ({ display, setScreen, screen }: Props) {
  const [jobsData, setJobData] = useState<IJobs>({} as IJobs);
  const [page, setPage] = useState(0);

  const { backendServer, openNotification, setIsLoading } = useContext(AppContext);

  useEffect(() => {
    getJobDetails();
  }, [screen?.data, screen?.screen]);

  async function getJobDetails() {
    if (!screen?.data || screen?.screen !== "resources") return;
    try {
      setPage(0);
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/jobs/get?id=${screen?.data?.child_job}`, {
          method: "GET",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setJobData(returned?.data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification(
        "We are unable to fetch the details for ths job at the moment. Try again in a few minutes."
      );
    }
  }

  if (!display) return <></>;

  return (
    <div className="screen-container job-list-container">
      <p
        className="small cursor fix-back-button"
        onClick={() => {
          setScreen({
            screen: "job-details",
            data: screen?.data?.parent_job,
          });
          setJobData({} as IJobs);
        }}
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <h3 className="vbig-2">{jobsData?.job_name} Resource List</h3>

      <p className="small">
        Use the provided information to navigate and explore the provided resource list efficiently.
      </p>

      <div style={{ marginTop: 20 }}>
        <div className="table-utilities">
          <div className="table-utilities-extra"></div>
          <div className="table-utilities-icons">
            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={() => getJobDetails()}
            />
          </div>
        </div>

        <ResourcesTable
          page={page}
          setPage={setPage}
          data={jobsData?.job_resources}
          setScreen={setScreen}
        />
      </div>
    </div>
  );
};
