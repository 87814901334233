import { useEffect, useState } from "react";
import "../../styles/index.css";
import "./banks.css";
import { Home } from "./screens/home.tsx";
import { JobLists } from "./screens/job-list.tsx";
import { JobDetails } from "./screens/job-details.tsx";
import { SubmitJobClaim } from "./screens/submit-claim.tsx";
import { Resources } from "./screens/resources.tsx";

export type BankScreenOptions = "home" | "bank-jobs" | "job-details" | "job-claim" | "resources";
export type TBankNavs = {
  screen: BankScreenOptions;
  data: any;
};

export const Banks = function ({ display }: { display: boolean }) {
  const [screen, setScreen] = useState<TBankNavs>({
    screen: "home",
    data: null,
  });

  useEffect(() => {
    return () => {
      if (!display)
        setScreen({
          screen: "home",
          data: null,
        });
    };
  }, [display]);

  if (!display) return;

  return (
    <div>
      <Home setScreen={setScreen} display={screen.screen === "home" && true} />
      <JobLists
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "bank-jobs" && true}
      />
      <JobDetails
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "job-details" && true}
      />

      <SubmitJobClaim
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "job-claim" && true}
      />

      <Resources
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "resources" && true}
      />
    </div>
  );
};
