import React, { useState, useEffect } from "react";
import { Col, Row, Statistic } from "antd";
import "../../styles/index.css";
import { AuthStages } from "../reset-password";
const { Countdown } = Statistic;

type Props = {
  onFinish: any;
  stage: AuthStages;
};

const CountdownTimer = ({ onFinish, stage }: Props) => {
  const [deadline, setDeadline] = useState(Date.now() + 1000 * 60 * 0.2 * 24 * 2 + 1000 * 25);

  useEffect(() => {
    if (stage === "code-screen") setDeadline(Date.now() + 1000 * 60 * 0.2 * 24 * 2 + 1000 * 25);
  }, [stage]);
  if (stage !== "code-screen") return;
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Countdown
          valueStyle={{ fontSize: "small", color: "#34204c" }}
          title=""
          value={deadline}
          onFinish={onFinish}
        />
      </Col>
    </Row>
  );
};
export default CountdownTimer;
