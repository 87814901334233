import React, { useState } from "react";
import "../../styles/index.css";
import "./reset-modal.css";
import "../login.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import BeginReset from "./begin-reset";
import CodeScreen from "./code-screen";
import ResetPasswordScreen from "./reset-password";

type Prop = {
  display: boolean;
  setShowResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
};

type AuthStages = "reset-password" | "code-screen" | "begin-reset";

export const ResetPasswordModal = function ({ display, setShowResetPassword }: Prop) {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [stage, setStage] = useState<AuthStages>("begin-reset");
  const [codeSentFromServer, setCodeSentFromServer] = useState("");

  return (
    <div className="modal-container" style={{ display: `${display ? "flex" : "none"}` }}>
      <div className="modal-body reset-password-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => setShowResetPassword(false)}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="reset-password-modal-cont">
          <BeginReset
            setEmail={setEmail}
            email={email}
            setStage={setStage}
            display={stage === "begin-reset" && true}
            setCodeSentFromServer={setCodeSentFromServer}
            setShowResetPassword={setShowResetPassword}
          />
          <CodeScreen
            setEmail={setEmail}
            email={email}
            setStage={setStage}
            display={stage === "code-screen" && true}
            stage={stage}
            codeSentFromServer={codeSentFromServer}
            setCode={setCode}
            code={code}
            setCodeSentFromServer={setCodeSentFromServer}
          />
          <ResetPasswordScreen
            setEmail={setEmail}
            email={email}
            setStage={setStage}
            display={stage === "reset-password" && true}
            setShowResetPassword={setShowResetPassword}
            code={code}
          />
        </div>
      </div>
    </div>
  );
};
