import { useState, useContext } from "react";
import "../../styles/index.css";
import "./profile.css";
import { ProfileHome } from "./screens/home.tsx";
import { ProfileDocsTable } from "./screens/documents-table.tsx";
import { AttachVerificationDocs } from "./screens/attach-verification-docs.tsx";
import { EditVerificationDocument } from "./screens/edit-verification-docs.tsx";
import AppContext from "../../context/app-context.ts";
import { TAVerificationDocument, TPlanDocRequirementsForUse } from "../../context/interface.ts";

export type ProfileScreenOptions =
  | "home"
  | "documents-table"
  | "attach-verification-docs"
  | "edit-verification-docs";

export type TProfileNavs = {
  screen: ProfileScreenOptions;
  data: any;
};

export const Profile = function ({ display }: { display: boolean }) {
  const [screen, setScreen] = useState<TProfileNavs>({
    screen: "home",
    data: null,
  });

  const { AppConfigs, usersData } = useContext(AppContext);

  function getUserUnUploadedDocuments() {
    const PlansVerificationDocRequirements = AppConfigs.PlansVerificationDocRequirements;

    const userPlanRequirements = PlansVerificationDocRequirements[usersData?.plan];

    const allVerificationsNeededToBeDone: string[] = [];

    userPlanRequirements?.forEach((e: TPlanDocRequirementsForUse) => {
      if (e?.type) {
        if (
          !usersData?.verification_details?.documents?.some((doc: TAVerificationDocument) =>
            e?.values?.includes(doc?.document_name)
          )
        )
          allVerificationsNeededToBeDone.push(e?.type);
      } else {
        if (
          !usersData?.verification_details?.documents?.find(
            (doc: TAVerificationDocument) => doc?.document_name === e
          )
        )
          allVerificationsNeededToBeDone.push(e);
      }
    });

    return allVerificationsNeededToBeDone;
  }

  if (!display) return;

  return (
    <div>
      <ProfileHome
        setScreen={setScreen}
        display={screen.screen === "home" && true}
        getUserUnUploadedDocuments={getUserUnUploadedDocuments}
      />

      <ProfileDocsTable
        setScreen={setScreen}
        display={screen.screen === "documents-table" && true}
        getUserUnUploadedDocuments={getUserUnUploadedDocuments}
      />

      <AttachVerificationDocs
        setScreen={setScreen}
        display={screen.screen === "attach-verification-docs" && true}
        screen={screen}
      />

      <EditVerificationDocument
        setScreen={setScreen}
        display={screen.screen === "edit-verification-docs" && true}
        screen={screen}
      />
    </div>
  );
};
