import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../login.css";
import AppContext from "../../context/app-context";
import { AuthStages } from "../reset-password";
import { Props } from "../login";
import { MDBInput } from "mdb-react-ui-kit";

type Prop = {
  display: boolean;
  setStage: React.Dispatch<React.SetStateAction<AuthStages>>;
  email: string;
  code: string;
  setEmail?: React.Dispatch<React.SetStateAction<string>>;
  codeSentFromServer?: string;
  setShowResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
} & Props;

const ResetPasswordScreen = function ({
  display,
  setStage,
  email,
  setShowResetPassword,
  code,
}: Prop) {
  const [disableBtn, setDisableBtn] = useState(false);
  const [password, setPassword] = useState("");
  const [testPass, setTestPass] = useState("");

  const { backendServer, setIsLoading, openNotification } = useContext(AppContext);

  useEffect(() => {
    if (email && code && password) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function resetPassword() {
    if (password !== testPass) return openNotification("Passwords do not match");

    setIsLoading(true);
    try {
      const returned = await (
        await fetch(`${backendServer}/auth/password/complete`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            token: code,
            password,
          }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      openNotification("Password reset successful!");
      setStage("begin-reset");
      setShowResetPassword(false);
      setIsLoading(false);
    } catch (err: any) {
      openNotification(err.message || "Something went wrong");
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="login-container-one">
      <div className="login-cont">
        <img className="wavy-logo" src="/logo.png" alt="DDSA" />
        <p className="small boldText removemargin">Enter a New Secure Password</p>
        <span className="small removemargin alignTextCenter login-subtitle-text"></span>
        <MDBInput
          title="New Password"
          label="New Password"
          type="password"
          wrapperClass="mdbIn mdb-login-in"
          onChange={(e) => setPassword(e.target.value)}
          labelClass="mdb-dropdown-custom-in"
        />

        <MDBInput
          title="Re-enter New Password"
          label="Re-enter New Password"
          type="password"
          wrapperClass="mdbIn mdb-login-in"
          onChange={(e) => setTestPass(e.target.value)}
          labelClass="mdb-dropdown-custom-in"
        />

        <button disabled={disableBtn} onClick={resetPassword} className="themeBtn login-btn">
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
