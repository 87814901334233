import React, { useContext, useState, useEffect } from "react";
import "../../styles/index.css";
import "./engine-top-bar.css";
import AppContext from "../../context/app-context";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoWalletOutline, IoArrowBack } from "react-icons/io5";
import { NotificationCard } from "../notification-card/notification-card";
import APopupModal from "../a-typical-popup-container/a-typical-popup-container";
import { TANotification } from "../../context/interface";
import { formatNumber } from "../../utils";
import { Tooltip } from "antd";

export type TANotificationScreenOptions = {
  type: "details" | "list";
  data?: TANotification;
};

function EngineTopBar() {
  const [userImage, setUserImage] = useState<string>("");
  const [notificationComps, setNotificationComps] = useState<React.ReactNode[]>([]);
  const [notificationsOnView, setNotificationsOnView] = useState<TANotificationScreenOptions>({
    type: "list",
  });

  const { usersData, getSignedAwsUrl, updateUsersData, backendServer, setIsLoading, changeScreen } =
    useContext(AppContext);

  useEffect(() => {
    (async function () {
      setUserImage(await getSignedAwsUrl(usersData?.profile_picture, "profile-pictures"));
    })();

    //populate notifications
    const reversed = [...usersData?.notifications]?.reverse();

    setNotificationComps(
      reversed?.map((e: TANotification, i: number) => (
        <NotificationCard
          key={i}
          data={e}
          markNotificationAsRead={markNotificationAsRead}
          setNotificationsOnView={setNotificationsOnView}
        />
      ))
    );
  }, [usersData]);

  async function markNotificationAsRead(id?: string) {
    try {
      setIsLoading(true);
      let url: string;

      if (id) url = `${backendServer}/users/notifications/read/${usersData?._id}/${id}`;
      else url = `${backendServer}/users/notifications/read/${usersData?._id}?all=true`;

      await (
        await fetch(url, {
          credentials: "include",
          method: "PATCH",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      updateUsersData();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  return (
    <div className="top-bar-container">
      <p className="big removemargin media-engine-intro"></p>

      <div className="flex-row header-tools">
        <div>
          <APopupModal
            styles={{ padding: 0 }}
            content={
              <div className="header-dropdown">
                {notificationsOnView?.type === "list" && (
                  <>
                    <div className="flex-row notification-header-container">
                      <p className="biggerText boldText removemargin notification-header-container-firstp">
                        Notifications{" "}
                        <div className="notification-count-number ">
                          <span className="small whiteText removemargin">
                            {usersData?.notifications?.filter((e: TANotification) => !e?.is_read)
                              .length < 99
                              ? usersData?.notifications?.filter((e: TANotification) => !e?.is_read)
                                  .length
                              : "99+"}
                          </span>
                        </div>
                      </p>

                      <p
                        className="small greyText removemargin cursor"
                        onClick={() => markNotificationAsRead()}
                      >
                        Mark all as read
                      </p>
                    </div>
                    {notificationComps}
                  </>
                )}

                {notificationsOnView?.type === "details" && (
                  <>
                    <div className="flex-row notification-header-container">
                      <p
                        className="small removemargin notification-header-container-firstp cursor"
                        onClick={() =>
                          setNotificationsOnView({
                            type: "list",
                          })
                        }
                      >
                        <IoArrowBack title="Notifications" size={18} className="not-header-icon" />{" "}
                        Back{" "}
                      </p>
                    </div>

                    <p className="small" style={{ padding: 20 }}>
                      {notificationsOnView?.data?.message || ""}
                    </p>
                  </>
                )}
              </div>
            }
            button={<IoNotificationsOutline size={27} className="cursor not-header-icon" />}
            trigger="click"
          />
        </div>

        <Tooltip
          placement="bottom"
          title={`Wallet Balance: ${formatNumber(usersData?.wallet?.balance)}`}
        >
          <IoWalletOutline size={27} className="cursor" />
        </Tooltip>

        <img
          src={userImage || "/images/user_placeholder.png"}
          alt="ddsa user profile"
          className="top-bar-profile-pic cursor"
          onClick={() => changeScreen("profile")}
        />

        <div
          onClick={() => changeScreen("profile")}
          className="flex-column align-column-left cursor"
          style={{ width: "fit-content" }}
        >
          <p className="small removemargin">{usersData?.last_name}</p>
          <p className="top-bar-status small removemargin">{usersData?.role}</p>
        </div>
      </div>
    </div>
  );
}

export default EngineTopBar;
