import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import "../styles/index.css";
import "./side-nav.css";
import AppContext from "../context/app-context";
import { screensNavKeys, TAppScreens } from "../context/interface";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import {
  MdOutlineDashboard,
  MdOutlineSettings,
  MdPersonOutline,
  MdOutlineAccountBalanceWallet,
  MdOutlineContactSupport,
  MdOutlineCategory,
  MdOutlineLogout,
  MdWorkOutline,
} from "react-icons/md";
import { CiBank } from "react-icons/ci";
import { RiCollapseDiagonalFill, RiCollapseDiagonal2Fill } from "react-icons/ri";

type MenuItem = Required<MenuProps>["items"][number];

type Prop = {
  setSideNav: React.Dispatch<React.SetStateAction<any>>;
  loggedIn: boolean;
};

const SideNav = function ({ setSideNav, loggedIn }: Prop) {
  const [_activeNav, setActiveNav] = useState(
    document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim() || "dashboard"
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const { changeScreen, setIsLoading } = useContext(AppContext);

  const items: MenuItem[] = [
    {
      key: "1",
      icon: <MdOutlineDashboard size={18} />,
      label: "Dashboard",
      onClick: () => navigate("dashboard"),
    },
    {
      key: "2",
      label: "Sectors",
      icon: <MdOutlineCategory size={18} />,
      children: [
        {
          key: "2a",
          label: "Banks",
          icon: <CiBank size={18} />,
          onClick: () => navigate("banks"),
        },
        {
          key: "2k",
          label: "Microfinance",
          icon: <CiBank size={18} />,
          onClick: () => navigate("microfinance"),
          disabled: true,
        },
        {
          key: "2g",
          label: "Fintech",
          icon: <CiBank size={18} />,
          onClick: () => navigate("fintech"),
          disabled: true,
        },
        {
          key: "2b",
          label: "Insurance",
          icon: <CiBank size={18} />,
          onClick: () => navigate("insurance"),
          disabled: true,
        },
        {
          key: "2m",
          label: "Retail/E-commerce",
          icon: <CiBank size={18} />,
          onClick: () => navigate("retail_ecommerce"),
          disabled: true,
        },
        {
          key: "2c",
          label: "Health Care",
          icon: <CiBank size={18} />,
          onClick: () => navigate("health_care"),
          disabled: true,
        },
        {
          key: "2d",
          label: "Hospitality",
          icon: <CiBank size={18} />,
          onClick: () => navigate("hospitality"),
          disabled: true,
        },
        {
          key: "2e",
          label: "Agriculture",
          icon: <CiBank size={18} />,
          onClick: () => navigate("agriculture"),
          disabled: true,
        },
        {
          key: "2f",
          label: "Utilities",
          icon: <CiBank size={18} />,
          onClick: () => navigate("utilities"),
          disabled: true,
        },
        {
          key: "2h",
          label: "Real Estates",
          icon: <CiBank size={18} />,
          onClick: () => navigate("real_estates"),
          disabled: true,
        },
        {
          key: "2i",
          label: "NGOs",
          icon: <CiBank size={18} />,
          onClick: () => navigate("ngos"),
          disabled: true,
        },
        {
          key: "2j",
          label: "Telecommunications",
          icon: <CiBank size={18} />,
          onClick: () => navigate("telecommunications"),
          disabled: true,
        },
        {
          key: "2l",
          label: "Education",
          icon: <CiBank size={18} />,
          onClick: () => navigate("education"),
          disabled: true,
        },
        {
          key: "2n",
          label: "Logistics",
          icon: <CiBank size={18} />,
          onClick: () => navigate("logistics"),
          disabled: true,
        },
      ],
    },
    {
      key: "3",
      icon: <MdWorkOutline size={18} />,
      label: "My Jobs",
      onClick: () => navigate("myjobs"),
    },
    {
      key: "4",
      icon: <MdOutlineAccountBalanceWallet size={18} />,
      label: "Wallet",
      onClick: () => navigate("wallet"),
    },
    {
      key: "5",
      icon: <MdPersonOutline size={18} />,
      label: "Profile",
      onClick: () => navigate("profile"),
    },
    {
      key: "6",
      icon: <MdOutlineSettings size={18} />,
      label: "Settings",
      onClick: () => navigate("settings"),
    },
    {
      key: "7",
      icon: <MdOutlineContactSupport size={18} />,
      label: "Support",
      onClick: () => navigate("support"),
    },
    {
      key: "8",
      icon: <MdOutlineLogout size={18} />,
      label: "Log out",
      onClick: () => {
        setIsLoading(true);
        localStorage.removeItem("ddsaemail");
        localStorage.removeItem("ddsatoken");
        setTimeout(() => {
          document.location.reload();
        }, 1500);
      },
    },
  ];

  useEffect(() => {
    setSideNav({ setActiveNav });

    setSelectedKeys([
      screensNavKeys[
        (document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim() as TAppScreens) ||
          "dashboard"
      ],
    ]);
  }, [loggedIn, setSideNav]);

  useEffect(() => {
    window?.addEventListener("hashchange", () => {
      const hash = document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim() as TAppScreens;
      setSelectedKeys([screensNavKeys[hash]]);
    });
  }, []);

  useLayoutEffect(() => {
    const isMobile = window?.screen?.availWidth < 500;
    if (isMobile) {
      setCollapsed(true);
    } else setCollapsed(false);
  }, []);

  function navigate(screen: TAppScreens) {
    setActiveNav(screen);
    changeScreen(screen);
    document.location.hash = screen;
    setSelectedKeys([screensNavKeys[screen]]);
  }

  return (
    <div className="side-nav-container">
      <div className="inner-conatiner">
        <div className="collaps-nav-indicator-container">
          {collapsed ? (
            <RiCollapseDiagonalFill
              title={`Collapse Navigation`}
              size={18}
              onClick={toggleCollapsed}
              className="cursor"
            />
          ) : (
            <RiCollapseDiagonal2Fill
              title={`Expand Navigation`}
              size={18}
              onClick={toggleCollapsed}
              className="cursor"
            />
          )}
        </div>

        <div className="side-nav-intro flex-row">
          <img src="/logo-full.png" alt="DDSA-Logo" className="ddsa-logo-side-nav" />
        </div>

        <Menu
          // defaultOpenKeys={["sub1"]}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
          items={items}
          style={{ background: "transparent", borderStyle: "none" }}
          className="font"
          selectedKeys={selectedKeys}
        />
      </div>
    </div>
  );
};

export default SideNav;
