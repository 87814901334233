import React, { useMemo, useImperativeHandle, forwardRef } from "react";
import { Divider, notification } from "antd";

const Context = React.createContext({ name: "Default" });

const Notification = forwardRef(({}, ref) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message: string, title?: string, duration?: number) => {
    api.info({
      message: title || "Alert",
      description: <Context.Consumer>{() => message}</Context.Consumer>,
      placement: "topRight",
      style: {
        fontFamily: '"Sora", sans-serif',
      },
      duration: duration || 5,
      showProgress: true,
      pauseOnHover: true,
    });
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  useImperativeHandle(ref, () => {
    return {
      openNotification(message: string, title?: string) {
        openNotification(message, title);
      },
    };
  });

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <Divider />
    </Context.Provider>
  );
});

export default Notification;
