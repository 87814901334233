import { useContext, useEffect, useState } from "react";
import "../../styles/index.css";
import "./verification-indicators.css";
import AppContext from "../../context/app-context.ts";
import { Flex, Progress } from "antd";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { TAVerificationDocument, TPlanDocRequirementsForUse } from "../../context/interface.ts";
import { calculatePercentage, shortenText } from "../../utils/index.ts";
import { Tooltip } from "antd";

const AVFIndicator = ({
  active,
  title,
  number,
}: {
  active: boolean;
  title: string;
  number: number | string;
}) => {
  return (
    <Tooltip placement="bottom" title={title}>
      <div className="flex-column a-vf-indicator">
        <div className="flex-row">
          <IoIosCheckmarkCircle color={active ? "#07B939" : "#B0B2C3"} />
          <div className={`vf-fill-indicator ${active ? "fill-active" : "fill-inactive"}`}></div>
        </div>
        <span className="xsmall avfindicator-numbertext">
          {number} &nbsp;
          {shortenText({ text: title, maxLen: 21 }).text}
        </span>
      </div>
    </Tooltip>
  );
};

export type Prop = {
  title?: string;
  styles?: React.CSSProperties;
  showAllIndicators?: boolean;
};

type VerificationIndicatorResolvedData = {
  allVerificationsNotDone: string[];
  percentageState: number;
  verificationsToBeDone: string[];
  indicatorComponents: React.ReactNode[];
};

export const VerificationIndicators = ({
  title = "Complete Your Profile",
  styles,
  showAllIndicators,
}: Prop) => {
  const {
    usersData,
    AppConfigs,
    openNotification,
    shownOneTimeNotificationAlert,
    setShownOneTimeNotificationAlert,
  } = useContext(AppContext);

  const [resolvedData, setResolvedData] = useState<VerificationIndicatorResolvedData>({
    allVerificationsNotDone: [],
    percentageState: 0,
    verificationsToBeDone: [],
    indicatorComponents: [],
  });

  useEffect(() => {
    calculateVerificationData();
  }, [usersData, AppConfigs]);

  useEffect(() => {
    if (shownOneTimeNotificationAlert?.verifications) return;
    else if (
      resolvedData?.allVerificationsNotDone?.length &&
      !shownOneTimeNotificationAlert?.verifications
    ) {
      openNotification(
        `You have verification documents to submit. Head over to your profile to submit your ${resolvedData?.allVerificationsNotDone?.join(
          ", "
        )}.`,
        "Verification Incomplete"
      );
      setShownOneTimeNotificationAlert((p) => ({ ...p, verifications: true }));
    }
  }, [resolvedData?.allVerificationsNotDone]);

  function sortDataByActiveIndicator(arrayOne: string[], arrayTwo: string[]) {
    const array = arrayOne?.sort((a: any) => {
      if (arrayTwo?.includes(a)) return 1;
      else return -1;
    });

    return array;
  }

  function calculateVerificationData() {
    if (!usersData?._id || !AppConfigs?.PlansVerificationDocRequirements) return;

    const PlansVerificationDocRequirements = AppConfigs.PlansVerificationDocRequirements;

    const userPlanRequirements = PlansVerificationDocRequirements[usersData?.plan];

    const allVerificationsNeededToBeDone: string[] = [];

    userPlanRequirements?.forEach((e: TPlanDocRequirementsForUse) => {
      if (e?.type) {
        if (
          !usersData?.verification_details?.documents?.some((doc: TAVerificationDocument) =>
            e?.values?.includes(doc?.document_name)
          )
        )
          allVerificationsNeededToBeDone.push(e?.type);
      } else {
        if (
          !usersData?.verification_details?.documents?.find(
            (doc: TAVerificationDocument) => doc?.document_name === e
          )
        )
          allVerificationsNeededToBeDone.push(e);
      }
    });

    const percentage = calculatePercentage(
      userPlanRequirements?.length,
      userPlanRequirements?.length - allVerificationsNeededToBeDone?.length
    );

    const verificationsToBeDone = userPlanRequirements?.map((e: TPlanDocRequirementsForUse) => {
      if (e?.type) return e?.type;
      else return e;
    });

    setResolvedData({
      allVerificationsNotDone: allVerificationsNeededToBeDone,
      percentageState: Number(percentage),
      verificationsToBeDone: verificationsToBeDone,
      indicatorComponents: !showAllIndicators
        ? sortDataByActiveIndicator(verificationsToBeDone, allVerificationsNeededToBeDone)
            ?.slice(-4)
            ?.map((e: string, key: number) => (
              <AVFIndicator
                active={!allVerificationsNeededToBeDone?.includes(e)}
                title={e}
                number={`0${key + 1}`}
                key={key}
              />
            ))
        : sortDataByActiveIndicator(verificationsToBeDone, allVerificationsNeededToBeDone)?.map(
            (e: string, key: number) => (
              <AVFIndicator
                active={!allVerificationsNeededToBeDone?.includes(e)}
                title={e}
                number={`0${key + 1}`}
                key={key}
              />
            )
          ),
    });
  }
  return (
    <div className="flex-row vf-indicator-cont" style={{ ...styles }}>
      <div className="flex-column vf-indicator-cont-left">
        <Tooltip placement="top" title={`${resolvedData?.percentageState}% Verification Progress`}>
          <Flex gap="small" wrap>
            <Progress
              type="circle"
              percent={resolvedData?.percentageState}
              size={40}
              strokeColor="#07B939"
            />
          </Flex>
        </Tooltip>
      </div>
      <div className="flex-column align-column-left vf-indicator-cont-right">
        <p className="small greyText removemargin">{title}</p>
        <div className="flex-row align-row-left vf-tracker-row">
          {resolvedData?.indicatorComponents}
        </div>
      </div>
    </div>
  );
};
