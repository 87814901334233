import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../login.css";
import AppContext from "../../context/app-context";
import { AuthStages } from "../reset-password";
import { validateEmail } from "../../utils";
import { MDBInput } from "mdb-react-ui-kit";

type Prop = {
  display: boolean;
  setStage: React.Dispatch<React.SetStateAction<AuthStages>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCodeSentFromServer: React.Dispatch<React.SetStateAction<string>>;
  setShowResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
};

const BeginReset = function ({
  display,
  setStage,
  setEmail,
  email,
  setCodeSentFromServer,
}: Prop) {
  const [disableBtn, setDisableBtn] = useState(false);

  const { setIsLoading, backendServer, openNotification } = useContext(AppContext);

  useEffect(() => {
    if (email && validateEmail(email)) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function beginRest() {
    setIsLoading(true);
    try {
      const returned = await (
        await fetch(`${backendServer}/auth/password/initiate`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: email }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setCodeSentFromServer(String(returned?.data));
      setStage("code-screen");
      setIsLoading(false);
    } catch (err: any) {
      openNotification(err?.message, "Something Went Wrong");
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="reset-password-modal-screens-container">
      <img className="rp-wavy-logo" src="/logo.png" alt="DDSA" />
      <p className="big blackText boldText removemargin"> Verify your email address</p>
      <span className="small removemargin alignTextCenter rp-login-subtitle-text"></span>
      <MDBInput
        title="Email Address"
        label="Email Address"
        type="email"
        wrapperClass="mdbIn rp-mdb-login-in"
        onChange={(e) => setEmail(e.target.value?.toLowerCase())}
        labelClass="mdb-dropdown-custom-in"
      />

      <button disabled={disableBtn} onClick={beginRest} className="themeBtn rp-login-btn">
        Continue
      </button>
    </div>
  );
};

export default BeginReset;
