import { useContext, useState } from "react";
import "../../styles/index.css";
import "./settings.css";
import AppContext from "../../context/app-context";
import { Switch } from "antd";
import { TSettingsOptions } from "../../context/interface";
import { Popconfirm } from "antd";
import { ResetPasswordModal } from "../../auth/reset-password-modal/reset-password-modal";

export const Settings = function ({ display }: { display: boolean }) {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const { backendServer, openNotification, setIsLoading, usersData, updateUsersData } =
    useContext(AppContext);

  async function updateSettings(type: TSettingsOptions, status: boolean) {
    try {
      setIsLoading(true);

      const obj: any = {};

      obj[type] = String(status);

      const returned = await (
        await fetch(`${backendServer}/users/settings/update/${usersData?._id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
            "content-type": "application/json",
          },
          body: JSON.stringify(obj),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setIsLoading(false);
      updateUsersData();
    } catch (err) {
      openNotification("We can't update your settings right now. Try again after some time.");
      setIsLoading(false);
    }
  }

  async function deleteAccount() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/users/action/false/${usersData?._id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
            "content-type": "application/json",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      localStorage.removeItem("ddsaemail");
      localStorage.removeItem("ddsatoken");
      setTimeout(() => {
        document.location.reload();
      }, 2500);
    } catch (err) {
      openNotification("We can't update your settings right now. Try again after some time.");
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="screen-container settings-screen-cont">
      <h3 className="vbig blackText">Settings</h3>

      <div className="settings-section-box">
        <p className="big blackText settings-container-section-header">Notification Preferences</p>

        <div className="a-settings-item-container">
          <div className="flex-column align-column-left">
            <p className="biggerText blackText">Job Credit Notification</p>
            <span className="small greyText">Receive notification when credited for a job</span>
          </div>

          <Switch
            value={usersData?.settings?.Job_Credit_Notification}
            onChange={(e) => updateSettings("Job_Credit_Notification", e)}
            size="small"
          />
        </div>

        <div className="a-settings-item-container">
          <div className="flex-column align-column-left">
            <p className="biggerText blackText">Withdrawal Processed Notification</p>
            <span className="small greyText">
              Receive notification when a withdrawal request is processed
            </span>
          </div>

          <Switch
            value={usersData?.settings?.Withdrawal_Processed_Notification}
            onChange={(e) => updateSettings("Withdrawal_Processed_Notification", e)}
            size="small"
          />
        </div>

        <div className="a-settings-item-container">
          <div className="flex-column align-column-left">
            <p className="biggerText blackText">Wallet Transaction Notification</p>
            <span className="small greyText">
              Receive timely notification for wallet transactions
            </span>
          </div>

          <Switch
            value={usersData?.settings?.Wallet_Transaction_Notification}
            onChange={(e) => updateSettings("Wallet_Transaction_Notification", e)}
            size="small"
          />
        </div>

        <p
          style={{ marginTop: "50px" }}
          className="big blackText settings-container-section-header "
        >
          Account Preferences
        </p>

        <div className="a-settings-item-container">
          <div className="flex-column align-column-left">
            <p className="biggerText blackText">Profile Visibility</p>
            <span className="small greyText">Make my profile visible to all users</span>
          </div>

          <Switch
            value={usersData?.settings?.Profile_Visibility}
            onChange={(e) => updateSettings("Profile_Visibility", e)}
            size="small"
          />
        </div>

        <p
          style={{ marginTop: "50px" }}
          className="big blackText settings-container-section-header "
        >
          Security Settings
        </p>

        <div className="a-settings-item-container">
          <div className="flex-column align-column-left">
            <p className="biggerText blackText">Password Management</p>
            <span onClick={() => setShowResetPasswordModal(true)} className="small blueText cursor">
              Change your password
            </span>
          </div>

          {/* <Switch size="small" /> */}
        </div>

        <div className="a-settings-item-container">
          <div className="flex-column align-column-left">
            <p className="biggerText blackText">Login Alerts</p>
            <span className="small greyText">Receive alerts for new logins on your account</span>
          </div>

          <Switch
            value={usersData?.settings?.Login_Alerts}
            onChange={(e) => updateSettings("Login_Alerts", e)}
            size="small"
          />
        </div>
      </div>

      <div className="settings-section-box settings-bg-color">
        <p className="big blackText settings-container-section-header ">Delete Account</p>

        <p className="small">
          Permanently delete my DDSA account and all my data. This cannot be undone.
        </p>

        <Popconfirm
          title="Delete Your Account"
          description="Are you sure you want to permanently delete your account? This action cannot be reversed."
          okText="Yes, please"
          cancelText="Cancel"
          onConfirm={deleteAccount}
        >
          <button style={{ border: "0.4px solid #AB2727" }} className="themeBtn whiteBg blackText">
            Delete Account
          </button>
        </Popconfirm>
      </div>

      <ResetPasswordModal
        display={showResetPasswordModal}
        setShowResetPassword={setShowResetPasswordModal}
      />
    </div>
  );
};
