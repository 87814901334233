import { TAppPlans } from "../../context/interface";

type subLists = Record<TAppPlans, { descriptions: string[] }>;

export const subLists: subLists = {
  Basic: {
    descriptions: ["Agent Customer Service", "POS Retrieval", "Retail & E-commerce support"],
  },
  Premium: {
    descriptions: [
      "POS Acquisition & Distribution",
      "Aggregator and Agent Acquisition",
      "Microfinance Support & more",
    ],
  },
  Ultimate: {
    descriptions: [
      "Bank Support Services",
      "Advanced Telecommunications Services",
      "Insurance Services & more",
    ],
  },
};
