import React, { useContext } from "react";
import "../../styles/index.css";
import "./job-card.css";
import AppContext from "../../context/app-context.ts";
import { IJobs } from "../../context/interface.ts";
import { appPlansArray, getPriceRange, shortenText } from "../../utils/index.ts";
import { TBankNavs } from "../../screens/banks/banks.tsx";
import { appPlansIndexes } from "../../utils/index.ts";

type Props = {
  data: IJobs;
  setScreen: React.Dispatch<React.SetStateAction<TBankNavs>>;
};

export const JobCard = function ({ data, setScreen }: Props) {
  const { setSubscriptionModal, usersData } = useContext(AppContext);

  return (
    <div
      className={`job-card-container ${
        data?.for_plan === "Ultimate" ? "premium-job-list-card" : ""
      }`}
    >
      <p className="big removemargin boldText alignTextCenter">
        {shortenText({ text: data?.job_name, maxLen: 30 }).text}
      </p>
      <p className="small removemargin alignTextCenter">{data?.for_plan}</p>
      <p className="vbig job-card-price-range alignTextCenter">
        <sup style={{ fontSize: "x-small" }} className="job-card-sup">
          min
        </sup>{" "}
        ₦
        {data?.child_jobs?.length
          ? getPriceRange(
              data?.child_jobs?.map((e) => e?.job_incentive),
              "min"
            )
          : data?.job_incentive}
        <span className="small">&nbsp;per job</span>
      </p>

      <div
        className={`job-subjobs-list-container ${
          data?.for_plan === "Ultimate" ? "premium-job-subjobs-list-container" : ""
        } normal-flex-column small-gap`}
      >
        {data?.child_jobs?.length ? (
          data?.child_jobs?.map((e, i) => (
            <p key={i} className="small removemargin sub-perks">
              <img
                src={`${
                  data?.for_plan === "Ultimate"
                    ? "/images/icons/white-verify.png"
                    : "/images/icons/red-verify.png"
                }`}
                alt="DDSA Jobs"
              />{" "}
              &nbsp; {e?.job_name}
            </p>
          ))
        ) : (
          <>
            <p className="small removemargin sub-perks">
              <img
                src={`${
                  data?.for_plan === "Ultimate"
                    ? "/images/icons/white-verify.png"
                    : "/images/icons/red-verify.png"
                }`}
                alt="DDSA Jobs"
              />{" "}
              &nbsp;{data?.job_category} Job Category
            </p>
            <p className="small removemargin sub-perks">
              <img
                src={`${
                  data?.for_plan === "Ultimate"
                    ? "/images/icons/white-verify.png"
                    : "/images/icons/red-verify.png"
                }`}
                alt="DDSA Jobs"
              />{" "}
              &nbsp;{data?.job_resources?.length} Job Resources
            </p>
            <p className="small removemargin sub-perks">
              <img
                src={`${
                  data?.for_plan === "Ultimate"
                    ? "/images/icons/white-verify.png"
                    : "/images/icons/red-verify.png"
                }`}
                alt="DDSA Jobs"
              />{" "}
              &nbsp;{data?.prerequisites?.length} Job Prerequisites
            </p>
            <p className="small removemargin sub-perks">
              <img
                src={`${
                  data?.for_plan === "Ultimate"
                    ? "/images/icons/white-verify.png"
                    : "/images/icons/red-verify.png"
                }`}
                alt="DDSA Jobs"
              />{" "}
              &nbsp;{data?.proofs_needed?.length} Proof Guides
            </p>
          </>
        )}
      </div>

      <button
        onClick={() => {
          if (appPlansIndexes[usersData?.plan] < appPlansIndexes[data?.for_plan])
            return setSubscriptionModal({
              display: true,
              excludePlans: appPlansArray?.filter((e) => e !== data?.for_plan),
              onComplete: () => {
                setScreen({
                  screen: "job-details",
                  data: data?._id,
                });
              },
            });
          else
            setScreen({
              screen: "job-details",
              data: data?._id,
            });
        }}
        className="themeBtn whiteBg blackText themeBorder"
      >
        Get Started
      </button>
    </div>
  );
};
