import React from "react";
import { TAVerificationDocument } from "../../context/interface";
import { SlOptionsVertical } from "react-icons/sl";
import APopupModal from "../a-typical-popup-container/a-typical-popup-container";
import { extractFileExtension, getDateValue } from "../../utils";
import { TProfileNavs } from "../../screens/profile/profile";

const columns: any = [
  { id: "document_name", label: "Document Name", minWidth: 150 },
  {
    id: "createdAt",
    label: "Date Uploaded",
    minWidth: 150,
    align: "left",
  },
  {
    id: "document_value",
    label: "Attached Document",
    minWidth: 120,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 50,
    align: "center",
  },
];

function sortData(data: any, setScreen?: React.Dispatch<React.SetStateAction<TProfileNavs>>) {
  if (!data) return [];
  const finalArray = data?.map((e: any) => {
    return {
      document_name: e?.document_name,
      createdAt: e?.createdAt ? getDateValue(e?.createdAt) : "-",
      document_value: e?.document_value ? (
        <a
          className="small removemargin"
          download={true}
          href={e?.document_value}
          title="Download File"
        >
          {extractFileExtension(e!.originalFileName)}
        </a>
      ) : (
        "-"
      ),
      status: (
        <div
          className={`${
            e?.is_document_verified
              ? "t-status-approved"
              : e?.createdAt && !e?.is_document_verified
              ? "t-status-processing"
              : !e?._id
              ? "t-status-pending"
              : "t-status-rejected"
          }`}
        >
          <div>
            {e?.is_document_verified
              ? "Approved"
              : e?.createdAt && !e?.is_document_verified
              ? "Processing"
              : !e?._id
              ? "Pending"
              : "Unapproved"}
          </div>
        </div>
      ),
      action: (
        <APopupModal
          content={
            <div className="popup-common-filter-dropdown">
              <div className="normal-flex-column small-gap">
                {e?.document_value && (
                  <a href={e?.document_value} className="small removemargin">
                    Download Document
                  </a>
                )}

                {!e?._id && (
                  <a
                    onClick={() =>
                      setScreen &&
                      setScreen({
                        screen: "attach-verification-docs",
                        data: e,
                      })
                    }
                    href="#profile"
                    className="small removemargin"
                  >
                    Upload Document
                  </a>
                )}

                {e?.document_name && e?.id_number && !e?.is_document_verified && (
                  <a
                    onClick={() =>
                      setScreen &&
                      setScreen({
                        screen: "edit-verification-docs",
                        data: e,
                      })
                    }
                    href="#profile"
                    className="small removemargin"
                  >
                    Edit Document
                  </a>
                )}
              </div>
            </div>
          }
          button={<SlOptionsVertical size={15} />}
          trigger="click"
        />
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
