import { ReactNode } from "react";
import { ConfigProvider, Flex, Popover } from "antd";

type Props = {
  showArrow?: boolean;
  content: ReactNode;
  button: ReactNode;
  text?: string;
  styles?: React.CSSProperties;
  trigger?: "hover" | "click";
  zIndex?: number;
  buttonStyles?: React.CSSProperties;
};

const APopupModal = ({
  showArrow = false,
  content,
  button,
  text,
  styles,
  trigger = "hover",
  zIndex = 1030,
  buttonStyles,
}: Props) => {
  return (
    <ConfigProvider button={{ style: { margin: 4 } }}>
      <Flex vertical justify="center" align="center" className="demo">
        <Flex justify="center" align="center" style={{ whiteSpace: "nowrap", ...buttonStyles }}>
          <Popover
            placement="bottom"
            title={text}
            content={content}
            arrow={showArrow}
            trigger={trigger}
            overlayInnerStyle={{ ...styles }}
            zIndex={zIndex}
          >
            {button}
          </Popover>
        </Flex>
      </Flex>
    </ConfigProvider>
  );
};

export default APopupModal;
