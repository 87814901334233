export const faqData = [
  {
    question: "What is DDSA?",
    answer:
      "DDSA (Direct Digital Sales Agent) is a platform that connects agents with various job opportunities across multiple sectors. Agents can perform tasks, manage accounts, and earn income based on the jobs they complete.",
  },
  {
    question: "What are the different types of agents on DDSA?",
    answer: `<span>
    There are two types of agents on DDSA: <br><br>
<span style="font-weight: bold;">Field Agents:</span> These agents have comprehensive access to most DDSA features, excluding POS transactions. They manage tasks such as account opening, customer service, and more.<br><br><span style="font-weight: bold;">Shop Agents:</span>
 These agents specialise in POS transactions and have selective access to certain DDSA features. They focus on high-priority tasks like transaction counts, account opening, and reactivation.

    </span>`,
  },
  {
    question: `How does the subscription plan work?`,
    answer: `DDSA offers three subscription plans:<br><br>
    <span style="font-weight: bold;">Basic Plan (Free): </span>Limited access to specific sectors, with moderate income potential.<br><br>
    <span style="font-weight: bold;">Premium Plan (Paid): </span>Broader access to most sectors, with higher income potential.<br><br>
    <span style="font-weight: bold;">Ultimate Plan (Paid): </span>Full access to all sectors, with maximum income potential. Agents can upgrade their plan at any time.`,
  },
  {
    question: `How do I upgrade my subscription plan?`,
    answer: `You can upgrade your subscription plan at any time through your account settings. Simply select the plan you wish to upgrade to, complete the payment process, and gain instant access to additional features and sectors.`,
  },
  {
    question: `What documents are required for Premium and Ultimate plans?`,
    answer: `For the Premium and Ultimate plans, you will need to attach specific documents during the onboarding process. This may include proof of identity, CAC registration, or other required documents based on the plan selected.`,
  },
  {
    question: `How does the payment system work?`,
    answer: `Agents earn income based on the jobs they complete. Payments are credited to their wallets, which they can withdraw at any time. The payment distribution is as follows:<br><br>
    <span style="font-weight: bold;">Agent: </span>50%<br>
    <span style="font-weight: bold;">Super Agent: </span>10%<br>
    <span style="font-weight: bold;">Ambassador: </span>10%<br>
    <span style="font-weight: bold;">Project Manager: </span>10%<br>
    <span style="font-weight: bold;">Admin (DDSA): </span>50%<br>`,
  },
  {
    question: `What happens after I complete a job?`,
    answer: `Once you complete a job, your wallet is credited with the appropriate amount. The payment is distributed according to the predefined percentages among agents, super agents, ambassadors, project managers, and the DDSA admin.`,
  },
  {
    question: `How do I manage my account and settings?`,
    answer: `You can manage your account, update your profile, and adjust your settings through the "Profile Settings" section. This includes account management, privacy and security settings, and subscription plan management.`,
  },
  {
    question: `What kind of support is available for agents?`,
    answer: `Agents have access to support through their assigned ambassadors, who help with any issues, provide guidance, and ensure compliance with DDSA standards. Additionally, our customer support team is available to assist with any technical or account-related inquiries.`,
  },
  {
    question: `Can I switch from a Field Agent to a Shop Agent or vice versa?`,
    answer: `Yes, you can switch your role by updating your account settings or contacting support. Depending on your subscription plan, you may need to meet certain criteria or upgrade your plan to switch roles.
`,
  },
];
