import React, { JSXElementConstructor, ReactElement, useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../profile.css";
import AppContext from "../../../context/app-context.ts";
import { TProfileNavs } from "../profile.tsx";
import { IoMdArrowBack } from "react-icons/io";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBInput,
} from "mdb-react-ui-kit";
import { TVeririficationDocsOptions } from "../../../context/interface.ts";
import { fileToBase64 } from "../../../utils/index.ts";

type Props = {
  display: boolean;
  setScreen: React.Dispatch<React.SetStateAction<TProfileNavs>>;
  screen: TProfileNavs;
};

export const AttachVerificationDocs = function ({ display, setScreen, screen }: Props) {
  const [documentTypeOptions, setDocumentTypeOptions] = useState<
    ReactElement<any, string | JSXElementConstructor<any>>[]
  >([]);
  const [idDocFileSrc, setIdDocFileSrc] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [idTypeSelected, setIdTypeSelected] = useState<TVeririficationDocsOptions>(
    "" as TVeririficationDocsOptions
  );
  const [idNumber, setIdNumber] = useState("");
  const [idDoc, setIdDoc] = useState<any>("");

  const { setIsLoading, backendServer, openNotification, updateUsersData, AppConfigs, usersData } =
    useContext(AppContext);

  useEffect(() => {
    if (idDoc && idTypeSelected) setDisableButton(false);
    else setDisableButton(true);
  }, [idNumber, idDoc, idTypeSelected]);

  const handleUploadVerification = async () => {
    const formData = new FormData();
    formData.append("file", idDoc);
    formData.append("document_name", idTypeSelected);
    if (idNumber) formData.append("id_number", idNumber);

    try {
      setIsLoading(true);

      const returned = await (
        await fetch(
          `${backendServer}/users/verification/docs/submit/${usersData?._id}?type=secondary`,
          {
            method: "PUT",
            body: formData,
            headers: {
              enctype: "multipart/form-data",
              token: localStorage.getItem("ddsatoken") || "",
            },
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      openNotification(
        "Thank you for submitting your verification document. We will review and revert shortly.",
        "Verification Document Upload Successful!"
      );
      updateUsersData();
      setScreen({
        screen: "documents-table",
        data: null,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotification("Something went wrong, please try again later.");
    }
  };

  useEffect(() => {
    const foundNested = AppConfigs?.PlansVerificationDocRequirements[usersData?.plan]?.find(
      (e) => e?.type === screen?.data?.document_name
    );

    if (foundNested) {
      setIdTypeSelected("" as TVeririficationDocsOptions);
      setDocumentTypeOptions(
        foundNested?.values?.map((e, i: number) => (
          <MDBDropdownItem key={i} link href="#profile" onClick={() => setIdTypeSelected(e)}>
            {e}
          </MDBDropdownItem>
        ))
      );
    } else if (
      AppConfigs?.PlansVerificationDocRequirements[usersData?.plan]?.find(
        (e) => e === screen?.data?.document_name
      )
    ) {
      setIdTypeSelected(screen?.data?.document_name);
      setDocumentTypeOptions([
        <MDBDropdownItem
          key={0}
          link
          href="#profile"
          onClick={() => setIdTypeSelected(screen?.data?.document_name)}
        >
          {screen?.data?.document_name}
        </MDBDropdownItem>,
      ]);
    }
  }, [screen?.data]);

  if (!display) return <></>;

  return (
    <div className="screen-container profile-screen-cont">
      <p
        className="small cursor fix-back-button"
        onClick={() =>
          setScreen({
            screen: "documents-table",
            data: null,
          })
        }
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <h3 className="vbig-2 blackText">Verify your Identity</h3>

      <div className="flex-column a-little-gap">
        <div className="normal-flex-column width100">
          <p className="small" style={{ marginBottom: 10 }}>
            Upload Your Government-Issued ID*
          </p>

          <MDBDropdown
            id="mdb-dropdown-custom-dropdown"
            style={{ borderRadius: "5px", width: "100%" }}
          >
            <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
              {idTypeSelected || "Select ID Type"}
            </MDBDropdownToggle>

            <MDBDropdownMenu style={{ width: "inherit", maxHeight: 300, overflow: "auto" }}>
              {documentTypeOptions}
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>

        <div className="normal-flex-column width100">
          <p className="small removemargin" style={{ marginBottom: 10 }}>
            Enter ID Number
          </p>

          <MDBInput
            title="Enter Document/ID Number"
            label="Enter Document/ID Number"
            type="text"
            wrapperClass="mdbIn mdbInFullWidth"
            onChange={(e) => {
              setIdNumber(e.currentTarget.value);
            }}
            labelClass="mdb-dropdown-custom-in"
            value={idNumber}
          />
        </div>

        <div className="normal-flex-column width100">
          <p className="small removemargin" style={{ marginBottom: 10 }}>
            Upload Your ID*
          </p>

          <div className="profile-upload-card" title="Upload File">
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.doc,.docx,.pdf"
              onChange={async ({ target: { files } }) => {
                if (files !== null) {
                  try {
                    const file = files[0];
                    setIdDoc(file);
                    const imgSrc = await fileToBase64(file);
                    setIdDocFileSrc(imgSrc);
                  } catch (error) {
                    // console.log(error)
                  }
                }
              }}
            />
            <p className="small greyText removemargin" style={{ textAlign: "center" }}>
              <img alt="Upload File" title="Upload File" src="/images/icons/upload-icon.png" />{" "}
              <br></br>
              Add a clear photo of your Identification <br></br> (jpeg, jpg, png, doc, docx, pdf)
            </p>
          </div>

          <div className="profile-preview-doc">
            <img
              src={idDocFileSrc || "/images/img-placeholder.png"}
              alt="File Preview"
              onError={() => setIdDocFileSrc("/images/file-placeholder.png")}
            />
            {/* <IoMdClose
              onClick={() => {
                setIdDocFileSrc("");
                setIdDoc("");
              }}
              title="Remove File"
              className="cursor"
              size={20}
            /> */}
          </div>
        </div>

        <div className="normal-flex-row a-little-gap profile-uploadver-buttons-cont">
          <button
            style={{ border: "0.4px solid #AB2727" }}
            className="themeBtn whiteBg blackText"
            onClick={() =>
              setScreen({
                screen: "documents-table",
                data: null,
              })
            }
          >
            Cancel
          </button>

          <button onClick={handleUploadVerification} className="themeBtn" disabled={disableButton}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
