import { TAWalletTransaction } from "../../context/interface";
import { getLastFourMonths } from "../../utils";

export const dashboardLineChartData = (trasactions: TAWalletTransaction[]) => {
  const modes = [
    "incentives",
    "cancellation",
    "bonus",
    "others",
    "referral",
    "transfer",
    "job",
    "withdrawal",
  ];

  const months = getLastFourMonths();

  const resolvedData: any[] = [];

  modes?.forEach((e: string) => {
    const dataToPush: {
      id: string;
      data: any[];
    } = {
      id: e,
      data: [],
    };

    //get all transactions for each month and total and then push
    months?.forEach((month) => {
      let total: number = 0;
      trasactions
        ?.filter(
          (transaction) =>
            new Intl.DateTimeFormat("en-us", {
              month: "long",
            }).format(new Date(transaction?.initiated_on)) === month
        )
        ?.filter((transaction) => transaction?.mode === e)
        ?.forEach((e) => {
          total = total + e?.amount;
        });

      dataToPush?.data.push({
        x: month,
        y: total,
      });
    });

    resolvedData.push(dataToPush);
  });

  const filtered = resolvedData?.filter((e) => e?.data?.some((el: any) => el?.y > 0));

  return filtered;
};
