import { PaystackButton } from "react-paystack";

const PaystackHook = ({
  config,
  extraClassName,
  channels = ["card", "ussd", "mobile_money", "bank_transfer"],
}: {
  config: any;
  extraClassName?: string;
  channels?: string[];
}) => {
  return (
    <div>
      <PaystackButton channels={channels} className={`themeBtn ${extraClassName}`} {...config} />
    </div>
  );
};

export default PaystackHook;
