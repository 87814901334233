import { useState, useContext, useEffect } from "react";
import "../../styles/index.css";
import "./subscriptions-modal.css";
import AppContext from "../../context/app-context";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TAppPlans, TSubscriptionModal } from "../../context/interface";
import { subLists } from "./sub-lists";
import { FaCheck } from "react-icons/fa";
import { APP_PAYSTACK_KEY, calcDiscount } from "../../utils";
import { SubscriptionTermsOfServicesModal } from "./subscription-terms-of-service";
import PaystackHook from "../paystack/paystack-hook";

type Prop = {
  setSubscriptionModal: React.Dispatch<React.SetStateAction<TSubscriptionModal>>;
  subscriptionModal: TSubscriptionModal;
};

const SubscriptionModal = function ({ subscriptionModal, setSubscriptionModal }: Prop) {
  const { AppConfigs, usersData, setIsLoading, openNotification, updateUsersData, backendServer } =
    useContext(AppContext);

  const [prices, setPrices] = useState<Record<TAppPlans, number>>({
    Basic: 0,
    Premium: 0,
    Ultimate: 0,
  });

  const [activeSubModeOption, setActiveSubModeOption] = useState<"quarter" | "month" | "year">(
    "quarter"
  );

  const [activePlan, setActivePlan] = useState<TAppPlans>("Basic");
  const [activePlanCode, setActivePlanCode] = useState("");

  const [showTermsOfServiceModal, setShowTermsOFServiceModal] = useState(false);

  useEffect(() => {
    if (activeSubModeOption === "month") {
      setPrices({
        Basic: 0,
        Premium: Number(AppConfigs?.premium_plan_cost),
        Ultimate: Number(AppConfigs?.ultimate_plan_cost),
      });
    } else if (activeSubModeOption === "quarter") {
      setPrices({
        Basic: 0,
        Premium: calcDiscount(
          "quarter",
          Number(AppConfigs?.premium_plan_cost),
          Number(AppConfigs?.quarterly_discount_in_percentage)
        ),
        Ultimate: calcDiscount(
          "quarter",
          Number(AppConfigs?.ultimate_plan_cost),
          Number(AppConfigs?.quarterly_discount_in_percentage)
        ),
      });
    } else if (activeSubModeOption === "year") {
      setPrices({
        Basic: 0,
        Premium: calcDiscount(
          "quarter",
          Number(AppConfigs?.premium_plan_cost),
          Number(AppConfigs?.yearly_discount_in_percentage)
        ),
        Ultimate: calcDiscount(
          "quarter",
          Number(AppConfigs?.ultimate_plan_cost),
          Number(AppConfigs?.yearly_discount_in_percentage)
        ),
      });
    }
  }, [AppConfigs, activeSubModeOption]);

  useEffect(() => {
    if (activePlan === "Premium") {
      if (activeSubModeOption === "month")
        setActivePlanCode(AppConfigs?.paystack_plan_codes?.Premium_Monthly_Plan_Code);
      else if (activeSubModeOption === "quarter")
        setActivePlanCode(AppConfigs?.paystack_plan_codes?.Premium_Quaterly_Plan_Code);
    }

    if (activePlan === "Ultimate") {
      if (activeSubModeOption === "month")
        setActivePlanCode(AppConfigs?.paystack_plan_codes?.Ultimate_Monthly_Plan_Code);
      else if (activeSubModeOption === "quarter")
        setActivePlanCode(AppConfigs?.paystack_plan_codes?.Ultimate_Quarterly_Plan_Code);
    }
  }, [activeSubModeOption, activePlan]);

  async function sendSubscription(reference: any) {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/users/package/upgrade/${activePlan}/${usersData?._id}`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ddsatoken") || "",
          },
          body: JSON.stringify({
            paymentRef: reference,
            paymentMethod: "paystack",
            amount: String(prices[activePlan]),
          }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      openNotification(`Subscription successful!`);
      updateUsersData();
      setSubscriptionModal({
        display: false,
        customData: undefined,
        onComplete: undefined,
      });
      setShowTermsOFServiceModal(false);
      setIsLoading(false);

      subscriptionModal?.onComplete && subscriptionModal?.onComplete();
    } catch (err: any) {
      openNotification(err?.message, "Something Went Wrong");
      setIsLoading(false);
    }
  }

  const successPayment = (reference: string) => {
    sendSubscription(reference);
  };

  const closedPayment = () => {
    console.log("");
  };

  const config = {
    email: usersData?.email,
    amount: prices[activePlan] * 100,
    publicKey: APP_PAYSTACK_KEY,
    metadata: {
      order: {
        type: "Subscription",
        plan: activePlan,
        planCode: activePlanCode,
        amount: prices[activePlan],
        paymentMethod: "paystack",
        cycle: activeSubModeOption,
      },
    },
    plan: activePlanCode,
  };

  const componentProps = {
    ...config,
    text: `I agree`,
    onSuccess: (reference: any) => successPayment(reference),
    onClose: closedPayment,
  };

  return (
    <>
      <div
        className="modal-container"
        style={{ display: `${subscriptionModal?.display ? "flex" : "none"}` }}
      >
        <div className="modal-body subscription-modal-body">
          <div className="close-container-modal">
            <AiOutlineCloseCircle
              onClick={() => {
                setSubscriptionModal({
                  display: false,
                  customData: undefined,
                  onComplete: undefined,
                });
              }}
              className="cursor"
              size={20}
              color="#34204C"
            />
          </div>

          <div className="subscription-modal-cont">
            <h3 className="vbig blackText boldText">Our Subscriptions</h3>
            <p className="small">30% Off on quartely subscriptions</p>

            <div className="toggle-switch">
              <button
                className={`toggle-option ${activeSubModeOption === "quarter" ? "active" : ""}`}
                onClick={() => setActiveSubModeOption("quarter")}
              >
                Quarterly <span className="blackText whiteBg">-30%</span>
              </button>
              <button
                className={`toggle-option ${activeSubModeOption === "month" ? "active" : ""}`}
                onClick={() => setActiveSubModeOption("month")}
              >
                Monthly
              </button>
            </div>

            <div className="subscription-cards-container">
              <div className="subscription-card">
                <p className="big dd-gradient-text">
                  <img alt="ddsa-icon" src="/images/icons/dd-icon.png" />
                  &nbsp;&nbsp;Basic
                </p>

                <p className="small sub-price">
                  ₦{prices?.Basic}
                  <span className="small">/free</span>
                </p>

                <button disabled={true} className="subscription-btn themeBtn">
                  Start for Free
                </button>

                <img className="subscription-hr" alt="ddsa-icon" src="/images/icons/hr-icon.png" />

                <div className="subcription-perks-list-container normal-flex-column small-gap">
                  <p className="biggerText blackText removemargin" style={{ color: "#171717" }}>
                    Whats included
                  </p>
                  {subLists?.Basic?.descriptions?.map((e, i) => (
                    <p key={i} className="small removemargin sub-perks">
                      <FaCheck size={15} /> &nbsp; {e}
                    </p>
                  ))}
                </div>
              </div>

              <div className="subscription-card">
                <p className="big dd-gradient-text">
                  <img alt="ddsa-icon" src="/images/icons/dd-icon.png" />
                  &nbsp;&nbsp;Premium
                </p>

                <p className="small sub-price">
                  ₦{prices?.Premium}
                  <span className="small">/{activeSubModeOption}</span>
                </p>

                <button
                  disabled={
                    usersData?.plan === "Ultimate" ||
                    (subscriptionModal?.excludePlans &&
                      subscriptionModal?.excludePlans?.includes("Premium")) ||
                    (usersData?.plan === "Premium" && true)
                  }
                  className="subscription-btn themeBtn"
                  onClick={() => {
                    setShowTermsOFServiceModal(true);
                    setActivePlan("Premium");
                  }}
                >
                  Get Premium
                </button>

                <img className="subscription-hr" alt="ddsa-icon" src="/images/icons/hr-icon.png" />

                <div className="subcription-perks-list-container normal-flex-column small-gap">
                  <p className="biggerText blackText removemargin" style={{ color: "#171717" }}>
                    Whats included
                  </p>
                  {subLists?.Premium?.descriptions?.map((e, i) => (
                    <p key={i} className="small removemargin sub-perks">
                      <FaCheck size={15} /> &nbsp; {e}
                    </p>
                  ))}
                </div>
              </div>

              <div className="subscription-card">
                <p className="big dd-gradient-text">
                  <img alt="ddsa-icon" src="/images/icons/dd-icon.png" />
                  &nbsp;&nbsp;Ultimate
                </p>

                <p className="small sub-price">
                  ₦{prices?.Ultimate}
                  <span className="small">/{activeSubModeOption}</span>
                </p>

                <button
                  disabled={
                    usersData?.plan === "Ultimate" ||
                    (subscriptionModal?.excludePlans &&
                      subscriptionModal?.excludePlans?.includes("Ultimate"))
                  }
                  className="subscription-btn themeBtn"
                  onClick={() => {
                    setShowTermsOFServiceModal(true);
                    setActivePlan("Ultimate");
                  }}
                >
                  Get Ultimate
                </button>

                <img className="subscription-hr" alt="ddsa-icon" src="/images/icons/hr-icon.png" />

                <div className="subcription-perks-list-container normal-flex-column small-gap">
                  <p className="biggerText blackText removemargin" style={{ color: "#171717" }}>
                    Whats included
                  </p>
                  {subLists?.Ultimate?.descriptions?.map((e, i) => (
                    <p key={i} className="small removemargin sub-perks">
                      <FaCheck size={15} /> &nbsp; {e}
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex-row sub-advert-bg">
              <div className="subscription-advert normal-flex-row">
                <div className="normal-flex-column">
                  <h3 className="vbig blackText">Choose Your Path to Success.</h3>
                  <p className="small">
                    Select a plan that aligns with your goals and experience level. Start your
                    journey today with features designed to elevate your impact and earnings.
                  </p>
                </div>

                <div className="normal-flex-column">
                  <img src="/images/coins.png" alt="DDSA-Success" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SubscriptionTermsOfServicesModal
        showTermsOfServiceModal={showTermsOfServiceModal}
        setShowTermsOFServiceModal={setShowTermsOFServiceModal}
        proceedBtn={
          <PaystackHook
            channels={["card"]}
            config={componentProps}
            extraClassName={"sub-terms-of-service-btn"}
          />
        }
      />
    </>
  );
};

export default SubscriptionModal;
