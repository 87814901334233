import { useState, useImperativeHandle, forwardRef, useEffect, useContext } from "react";
import "../styles/index.css";
import "./engine.css";
import EngineTopBar from "../components/engine-top-bar/engine-top-bar";
import EngineBottomBar from "../components/engine-bottom-bar/engine-bottom-bar";
import { Dashboard } from "../screens/dashboard/dashboard";
import AppContext from "../context/app-context";
import { Settings } from "../screens/settings/settings";
import { TAppScreens } from "../context/interface";
import { Profile } from "../screens/profile/profile";
import { Support } from "../screens/support/support";
import { Banks } from "../screens/banks/banks";
import { Wallet } from "../screens/wallet/wallet";
import { MyJobs } from "../screens/my-jobs/my-jobs";

const Engine = forwardRef(function (
  { sideNav, loggedIn: _loggerIn }: { sideNav: any; loggedIn: boolean | string },
  ref
) {
  const [onView, setOnView] = useState<TAppScreens | string>(
    document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim() || "dashboard"
  );

  const { usersData } = useContext(AppContext);

  useEffect(() => {
    // console.log(usersData);
  }, [usersData]);

  useEffect(() => {
    window?.addEventListener("hashchange", () => {
      const hash = document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim();
      setOnView(hash);
    });
  }, []);

  useImperativeHandle(ref, () => {
    return {
      changeScreen(screen: string) {
        setOnView(screen?.toLowerCase());
        sideNav?.setActiveNav(screen?.toLowerCase());
        document.location.hash = screen?.toLowerCase();
      },
    };
  });

  return (
    <div className="engine-container">
      <EngineTopBar />
      <Dashboard display={onView === "dashboard" && true} />
      <Banks display={onView === "banks" && true} />
      <MyJobs display={onView === "myjobs" && true} />
      <Wallet display={onView === "wallet" && true} />
      <Profile display={onView === "profile" && true} />
      <Settings display={onView === "settings" && true} />
      <Support display={onView === "support" && true} />
      <EngineBottomBar />
    </div>
  );
});

export default Engine;
