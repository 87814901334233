import React from "react";

export type TUserRoles = "Agent" | "Ambassador" | "Super Agent" | "Admin";

export type TAppScreens =
  | "dashboard"
  | "settings"
  | "wallet"
  | "profile"
  | "support"
  | "sectors"
  | "banks"
  | "myjobs"
  | "education"
  | "insurance"
  | "health_care"
  | "hospitality"
  | "agriculture"
  | "utilities"
  | "fintech"
  | "real_estates"
  | "ngos"
  | "telecommunications"
  | "microfinance"
  | "retail_ecommerce"
  | "logistics";

export type TSectorTypes =
  | "banks"
  | "education"
  | "insurance"
  | "health-care"
  | "hospitality"
  | "agriculture"
  | "utilities"
  | "fintech"
  | "real-estates"
  | "ngos"
  | "telecommunications"
  | "microfinance"
  | "retail/e-commerce"
  | "logistics";

export const screensNavKeys: Record<TAppScreens, string> = {
  dashboard: "1",
  sectors: "2",
  banks: "2a",
  myjobs: "3",
  wallet: "4",
  profile: "5",
  settings: "6",
  support: "7",
  insurance: "2b",
  health_care: "2c",
  hospitality: "2d",
  agriculture: "2e",
  utilities: "2f",
  fintech: "2g",
  real_estates: "2h",
  ngos: "2i",
  telecommunications: "2j",
  microfinance: "2k",
  education: "2l",
  retail_ecommerce: "2m",
  logistics: "2n",
};

export type TAppPlans = "Basic" | "Premium" | "Ultimate";

export type TABankAccount = {
  _id: string;
  account_number: string;
  bank_name: string;
  sort_code: string;
  account_name: string;
  is_primary: boolean;
};

export type TAJobProof = {
  _id: string;
  proof_name: string;
  proof_type: "file" | "text" | "other" | "empty" | "form-data";
  value: string;
  comment: string;
};

export type TACompletedJob = {
  _id: string;
  proofs: TAJobProof[];
  status: "pending" | "approved" | "cancelled";
  total_number_claimed_done: number;
  createdAt: Date;
  completedAt: Date;
  admin_comment?: string;
};

export type TAJob = {
  _id?: any;
  job_id: IJobs;
  completed: TACompletedJob[];
};

export type TATransaction = {
  payment_for: "subscription" | "tools" | "forms" | "others";
  initiated_on: Date;
  status: "pending" | "completed" | "cancelled";
  paymentRef: object;
  amount: number;
};

export type TWalletModes =
  | "incentives"
  | "cancellation"
  | "bonus"
  | "others"
  | "referral"
  | "transfer"
  | "job"
  | "withdrawal";

export type TAWalletTransaction = {
  _id: string;
  transaction_type: "debit" | "credit" | "other";
  amount: number;
  initiated_on: Date;
  status: "pending" | "completed" | "cancelled";
  mode: TWalletModes;
  paymentRef: any;
};

export type TWallets = {
  _id: string;
  userID: any;
  balance: number;
  transactions: TAWalletTransaction[];
  is_active: boolean;
};

export type TReferrals = {
  _id: string;
  userID: any;
  referral_code: string;
  users_referred: any[];
  createdAt: Date;
  is_active: boolean;
};

export type TAUserTransaction = {
  payment_for: "subscription" | "tools" | "forms" | "others";
  initiated_on: Date | number;
  status: "pending" | "completed" | "cancelled";
  paymentRef: object;
  amount: number;
  paymentMethod: string;
};

export type TVeririficationDocsOptions =
  | "CAC"
  | "International Passport"
  | "Driver's Licence"
  | "BVN"
  | "NIN"
  | "Letter of Employment"
  | "Other"
  | "Passport Photograph"
  | "Signature"
  | "Utility Bills";

export type TAVerificationDocument = {
  _id: string;
  document_name: TVeririficationDocsOptions;
  document_value: string;
  createdAt: Date | number;
  verifiedAt: Date | number;
  is_document_verified: boolean;
  id_number: string;
};

export type TANotification = {
  _id: string;
  title: string;
  message: string;
  createdAt: Date | number | string;
  is_read: boolean;
  notification_type: "normal" | "broadcasts" | "security" | "account" | "feature" | "wallet";
};

export type TSettingsOptions =
  | "Job_Credit_Notification"
  | "Withdrawal_Processed_Notification"
  | "Wallet_Transaction_Notification"
  | "Profile_Visibility"
  | "Login_Alerts";

export type TSettingsObjRecord = Record<TSettingsOptions, boolean>;

export type TAgentType = "Field" | "Shop";

export interface IUsers {
  _id: string;
  platform_id: string;
  first_name: string;
  last_name: string;
  role: TUserRoles;
  email: string;
  profile_picture: string;
  password?: string;
  about: string;
  mobile: string;
  address: {
    addressString: string;
    geoLocation: any;
    postCode: string;
  };
  transactions: TAUserTransaction[];
  createdAt: Date;
  is_active: boolean;
  is_email_verified: boolean;
  bank_accounts: TABankAccount[];
  who_referred_you: {
    userID: IUsers;
    referral_code: string;
  };
  referral_box: TReferrals;
  wallet: TWallets;
  jobs: TAJob[];
  plan: TAppPlans;
  verification_details: {
    is_documents_verified: boolean;
    documents: TAVerificationDocument[];
    verifiedAt: Date;
    main_doc_id: string;
    main_doc_type: TVeririficationDocsOptions;
  };
  notifications: TANotification[];
  billing_details: {
    cycle: "one-time" | "month" | "quarter" | "year";
    mechanism: "paystack" | "others";
    subscription_code: string;
  };
  settings: TSettingsObjRecord;
  agent_type: TAgentType;
}

export type TAJobResource = {
  _id: string;
  text: string;
  files: string[];
  description: string;
};

export type TAPrerequisites = {
  _id: string;
  name: string;
  description: string;
  is_required: boolean;
};

export type TAProofNeededForJob = {
  _id: string;
  proof_name: string;
  proof_type: "file" | "text" | "other" | "empty" | "form-data";
  is_required: boolean;
};

export interface IJobs {
  _id: string;
  job_name: string;
  job_category: string;
  job_description: string;
  is_active: boolean;
  job_incentive: number;
  job_resources: TAJobResource[];
  prerequisites: TAPrerequisites[];
  for_role: TUserRoles;
  for_plan: TAppPlans;
  proofs_needed: TAProofNeededForJob[];
  for_sector: string;
  based_on: string;
  child_jobs: IJobs[];
  parent?: IJobs;
}

export interface IBanks {
  _id: string;
  jobs: TAJob[];
  sector_ref: any;
  bank_name: string;
  address: {
    addressString: string;
    geoLocation: any;
    postCode: string;
  };
  logo: string;
  is_active: boolean;
  createdAt: Date;
  can_use_externals: boolean;
  theme_color: string;
}

export type TAppSectors =
  | "banks"
  | "education"
  | "insurance"
  | "health-care"
  | "hospitality"
  | "agriculture"
  | "utilities"
  | "fintech"
  | "real-estates"
  | "ngos"
  | "telecommunications"
  | "microfinance"
  | "retail/e-commerce"
  | "logistics";

export type TPlanDocRequirementsForUse = {
  type: string;
  values: TVeririficationDocsOptions[];
} & TVeririficationDocsOptions;

export type TEachPlanDocRequirements = TPlanDocRequirementsForUse[];

export interface IAppConfig {
  MoneySharingConstraints: {
    ambassador_share_per_approved_job_in_percentage: number | string;
    super_agents_share_per_approved_job_in_percentage: number | string;
  };
  premium_plan_cost: string | number;
  ultimate_plan_cost: string | number;
  yearly_discount_in_percentage: string | number;
  quarterly_discount_in_percentage: string | number;
  AppSectors: TAppSectors[];
  AppPlans: TAppPlans[];
  AppRoles: TUserRoles[];
  verificationDocOptions: TVeririficationDocsOptions[];
  PlansVerificationDocRequirements: {
    Basic: TEachPlanDocRequirements;
    Premium: TEachPlanDocRequirements;
    Ultimate: TEachPlanDocRequirements;
  };
  paystack_plan_codes: {
    Ultimate_Monthly_Plan_Code: string;
    Ultimate_Quarterly_Plan_Code: string;
    Premium_Monthly_Plan_Code: string;
    Premium_Quaterly_Plan_Code: string;
  };
}

export type TOneNotimeNotificationAlerts = {
  verifications: boolean;
  account: boolean;
  news: boolean;
};

export type TSubscriptionModal = {
  display: boolean;
  customData?: {
    title: string;
    subtitle: string;
  };
  onComplete?: () => void;
  excludePlans?: TAppPlans[];
};

export interface IMainContext {
  changeScreen: (screen: TAppScreens) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loggedIn: boolean | string;
  usersData: IUsers;
  setUsersData: React.Dispatch<React.SetStateAction<IUsers>>;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean | string>>;
  backendServer: string;
  getSignedAwsUrl: (filename: string, bucketname: string) => any;
  updateUsersData: () => Promise<IUsers>;
  getCurrentHash: () => TAppScreens | "auth";
  getDDSAToken: () => string;
  setStoredContext: React.Dispatch<React.SetStateAction<any>>;
  storedContext: any;
  openNotification: (message: string, title?: string, duration?: number) => void;
  AppConfigs: IAppConfig;
  shownOneTimeNotificationAlert: TOneNotimeNotificationAlerts;
  setShownOneTimeNotificationAlert: React.Dispatch<
    React.SetStateAction<TOneNotimeNotificationAlerts>
  >;
  setSubscriptionModal: React.Dispatch<React.SetStateAction<TSubscriptionModal>>;
  subscriptionModal: TSubscriptionModal;
  contextBanks: IBanks[];
  getBasedOnSectorReference: (sector: TSectorTypes, based_on: string) => any;
  getBasedOnSectorReferenceSmart: (
    sector: TSectorTypes,
    based_on: string
  ) => { data: any; fieldForName: string };
}
