import { useLayoutEffect, useState, useRef, useEffect } from "react";
import "../../styles/index.css";
import "./support.css";
import { IoIosSearch } from "react-icons/io";
import { FAQCard, TFaqCardData } from "./faq-card.tsx";
import { faqData } from "./faq-data.ts";
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp, FaLinkedin } from "react-icons/fa";

export const Support = function ({ display }: { display?: boolean }) {
  const [searchValue, setSearchValue] = useState("");
  const [command, setCommand] = useState("");
  const [faqCompsOnShow, setFaQCompsOnShow] = useState<React.ReactNode[]>([]);

  const faqRef = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    setFaQCompsOnShow(faqData?.map((e: TFaqCardData, i: number) => <FAQCard data={e} key={i} />));
  }, []);

  const handleSearch = () => {
    if (searchValue || command) {
      setFaQCompsOnShow(
        faqData
          .filter(
            (item: TFaqCardData) =>
              item?.question
                ?.toLowerCase()
                .includes(searchValue.toLowerCase() || command?.toLowerCase()) ||
              item?.answer
                ?.toLowerCase()
                .includes(searchValue.toLowerCase() || command?.toLowerCase()) ||
              item?.question
                ?.toLowerCase()
                .includes(
                  searchValue.split(" ")[0]?.toLowerCase() || command.split(" ")[0]?.toLowerCase()
                ) ||
              item?.answer
                ?.toLowerCase()
                .includes(
                  searchValue.split(" ")[1]?.toLowerCase() || command.split(" ")[1]?.toLowerCase()
                )
          )
          ?.map((e: TFaqCardData, i: number) => <FAQCard data={e} key={i} />)
      );
      // faqRef?.current?.scrollIntoView({ behavior: "smooth" });
    } else
      setFaQCompsOnShow(faqData?.map((e: TFaqCardData, i: number) => <FAQCard data={e} key={i} />));
  };

  useEffect(() => {
    handleSearch();
  }, [searchValue, command]);

  if (!display) return <></>;

  return (
    <div className="screen-container support-container">
      <h3 className="vBig blackText boldText">Hello, how can we help you?</h3>

      <div className="attached-in-cont-with-icon support-search-box">
        <div>
          <IoIosSearch />
        </div>
        <input
          type="text"
          className="in"
          placeholder="Search for something"
          onChange={(e) => setSearchValue(e?.target?.value)}
        />
        <button className="themeBtn" onClick={() => handleSearch()}>
          Search
        </button>
      </div>

      {!searchValue && (
        <div className="flex-row support-section-part">
          <div
            className="flex-column section-support-card-container"
            onClick={() => setCommand("Accounts")}
          >
            <div className="flex-row support-section-content-container-icon">
              <img
                className="section-support-card-container-icon"
                src={"/images/dashboard/tjd.png"}
                alt="ddsa-icons"
                title="Accounts & Profile"
              />
            </div>

            <div className="flex-row support-section-content-container">
              <p className="biggerText boldText removemargin blackText">Accounts & Profile</p>
            </div>
          </div>

          <div
            className="flex-column section-support-card-container"
            onClick={() => setCommand("Subscription Plans")}
          >
            <div className="flex-row support-section-content-container-icon">
              <img
                className="section-support-card-container-icon"
                src={"/images/dashboard/tjd.png"}
                alt="ddsa-icons"
                title="Subscription & Plans"
              />
            </div>

            <div className="flex-row support-section-content-container">
              <p className="biggerText boldText removemargin blackText">Subscription & Plans</p>
            </div>
          </div>

          <div
            className="flex-column section-support-card-container"
            onClick={() => setCommand("Sectors Jobs")}
          >
            <div className="flex-row support-section-content-container-icon">
              <img
                className="section-support-card-container-icon"
                src={"/images/dashboard/tjd.png"}
                alt="ddsa-icons"
                title="Sectors & Jobs"
              />
            </div>

            <div className="flex-row support-section-content-container">
              <p className="biggerText boldText removemargin blackText">Sectors & Jobs</p>
            </div>
          </div>
        </div>
      )}

      <h3 ref={faqRef} className="vBig blackText boldText">
        Popular FAQs
      </h3>

      <div className="support-faq-container">
        {faqCompsOnShow?.length ? faqCompsOnShow : "Your search returned no results"}
      </div>

      <div className="flex-column">
        <h3 className="vBig blackText alignTextCenter boldText">Need Support?</h3>

        <p className="small alignTextCenter">
          Can’t find the answer to what you’re looking for?<br></br>Don’t worry we’re here to help.
        </p>

        <p className="small boldText alignTextCenter blackText" style={{ marginTop: 30 }}>
          Contact Us:
        </p>

        <div className="normal-flex-row a-little-gap">
          <FaFacebook
            onClick={() =>
              window?.open(
                "https://web.facebook.com/people/D-Degree-Digital-Hub/100072142423392",
                "_blank"
              )
            }
            className="cursor"
            size={20}
          />
          <FaInstagram
            onClick={() => window?.open("https://www.instagram.com/ddegreedigitalhub", "_blank")}
            className="cursor"
            size={20}
          />
          <FaTwitter
            onClick={() =>
              window?.open(
                "https://twitter.com/x/migrate?tok=7b2265223a222f6465677265655f687562222c2274223a313732373131383737327d18d9eb6f6f7024d6334274588701b6a2",
                "_blank"
              )
            }
            className="cursor"
            size={20}
          />
          <FaWhatsapp
            onClick={() => window?.open("https://wa.me/+2347035802057", "_blank")}
            className="cursor"
            size={20}
          />
          {/* <SiGmail
            onClick={() => window?.open("mailto:info@d-degreedigital.com", "_blank")}
            className="cursor"
            size={20}
          /> */}
          <FaLinkedin
            onClick={() =>
              window?.open("https://www.linkedin.com/company/d-degree-digital-hub", "_blank")
            }
            className="cursor"
            size={20}
          />
        </div>

        <div className="flex-row" style={{ marginTop: 20 }}>
          <p className="small">Email: ddsa@d-degreedigital.com</p>
        </div>
      </div>
    </div>
  );
};
