import React, { useState, useEffect, useContext } from "react";
import "../styles/index.css";
import "./login.css";
import AppContext from "../context/app-context";
import ResetPassword from "./reset-password";
import { MDBInput } from "mdb-react-ui-kit";
import { TAuthScreens } from "./entry";
import { appPassKey } from "../utils";

export type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TAuthScreens>>;
  screen: TAuthScreens;
};

const Login = function ({ setScreen, screen }: Props) {
  const [disableBtn, setDisableBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);

  const { setLoggedIn, backendServer, setIsLoading, setUsersData, openNotification } =
    useContext(AppContext);

  useEffect(() => {
    if (email && password) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function login() {
    setIsLoading(true);
    try {
      const returned = await (
        await fetch(`${backendServer}/auth/login`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            pass: appPassKey,
          },
          body: JSON.stringify({ email, loginPass: password }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      localStorage.setItem("ddsaemail", returned.data.email);
      localStorage.setItem("ddsatoken", returned.data.token);

      const data = await (
        await fetch(`${backendServer}/users/get?email=${returned.data.email}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
            pass: appPassKey,
          },
        })
      ).json();

      if (!data?.data?.is_active || !data?.data?.is_email_verified)
        throw new Error("Please verify your email/account");

      setUsersData(data.data);

      setIsLoading(false);

      setLoggedIn(true);

      openNotification(`Welcome back, ${data.data.first_name}`);

      document.location.hash = "Dashboard";
    } catch (err: any) {
      openNotification(err?.message, "Something Went Wrong");
      setIsLoading(false);
    }
  }

  return (
    <>
      {!showResetPassword && (
        <div className="login-container">
          <>
            <div className="login-container-one">
              <div className="login-cont">
                <img className="wavy-logo" src="/logo.png" alt="DDSA" />
                <p className="big boldText removemargin">Welcome Back</p>
                <span className="small removemargin alignTextCenter login-subtitle-text"></span>
                <MDBInput
                  title="Email Address"
                  label="Email Address"
                  type="email"
                  wrapperClass="mdbIn mdb-login-in"
                  onChange={(e) => setEmail(e.target.value?.toLowerCase())}
                  labelClass="mdb-dropdown-custom-in"
                />

                <MDBInput
                  title="Password"
                  label="Password"
                  type="password"
                  wrapperClass="mdbIn mdb-login-in"
                  onChange={(e) => setPassword(e.target.value)}
                  labelClass="mdb-dropdown-custom-in"
                />

                <div className="flex-row space-between no-padding forgot-remember-login">
                  <p></p>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setShowResetPassword(true);
                    }}
                    href="#auth"
                    className="small blueText"
                  >
                    Forgot Password?
                  </a>
                </div>

                <button disabled={disableBtn} onClick={login} className="themeBtn login-btn">
                  Sign In
                </button>
                <span className="small removemargin alignTextCenter login-subtitle-text">
                  Don't have an account?{" "}
                  <a
                    className="blueText"
                    href="#auth"
                    onClick={(e) => {
                      e.preventDefault();
                      setScreen("signup");
                    }}
                  >
                    Signup
                  </a>
                </span>
              </div>
            </div>
            <div className="login-container-two">
              <img className="login-banner-img" src="/images/login-banner.png" alt="DDSA" />
            </div>
          </>
        </div>
      )}
      {showResetPassword && (
        <ResetPassword
          setScreen={setScreen}
          screen={screen}
          setShowResetPassword={setShowResetPassword}
        />
      )}
    </>
  );
};

export default Login;
