import { formatNumber, getDateValue } from "../../utils";

const columns: any = [
  { id: "id", label: "Invoice ID", minWidth: 120 },
  {
    id: "paid_at",
    label: "Billing Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    align: "left",
  },
  {
    id: "amount",
    label: "Amount (₦)",
    minWidth: 100,
    align: "center",
  },
  {
    id: "plan",
    label: "Plan",
    minWidth: 100,
    align: "center",
  },
];

function sortData(data?: any[]) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      id: e?.id,
      paid_at: getDateValue(e?.paid_at),
      status: (
        <div className={`${e?.status === "success" ? "w-status-active" : "w-status-inactive"}`}>
          <div>{e?.status}</div>
        </div>
      ),
      amount: formatNumber(e?.amount / 100),
      plan: e?.metadata?.order?.plan,
    };
  });

  return finalArray;
}

export { sortData, columns };
