import { useEffect, useState } from "react";
import "../../styles/index.css";
import "./my-jobs.css";
import { Home } from "./screens/home.tsx";
import { ClaimsTableScreen } from "./screens/claims-review.tsx";
import { SubmitClaim } from "./screens/submit-claim.tsx";

export type MyJobsScreenOption =
  | "home"
  | "job-details"
  | "claims-table"
  | "submit-claim"
  | "claim-review";

export type TMyJobNavs = {
  screen: MyJobsScreenOption;
  data: any;
};

export const MyJobs = function ({ display }: { display: boolean }) {
  const [screen, setScreen] = useState<TMyJobNavs>({
    screen: "home",
    data: null,
  });

  useEffect(() => {
    return () => {
      if (!display)
        setScreen({
          screen: "home",
          data: null,
        });
    };
  }, [display]);

  if (!display) return;

  return (
    <div>
      <Home screen={screen} setScreen={setScreen} display={screen.screen === "home" && true} />
      <ClaimsTableScreen
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "claims-table" && true}
      />
      <SubmitClaim
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "submit-claim" && true}
      />
    </div>
  );
};
