import { TAJobResource } from "../../context/interface";
import APopupModal from "../a-typical-popup-container/a-typical-popup-container";
import { extractFileExtension, shortenText } from "../../utils";

const columns: any = [
  { id: "id", label: "", minWidth: 0 },
  { id: "text", label: "Title", minWidth: 120, align: "left" },
  { id: "description", label: "Description", minWidth: 150, align: "left" },
  {
    id: "files",
    label: "Download Files",
    minWidth: 120,
    align: "left",
  },
];

function sortData(data?: TAJobResource[], getSignedAwsUrl?: any) {
  if (!data) return [];
  const finalArray = data?.map(async (e: TAJobResource) => {
    return {
      id: <span style={{ display: "none" }}>{e?._id}</span>,
      text: e?.text,
      description: (
        <div className="normal-flex-row">
          <APopupModal
            button={
              <p className="small removemargin">
                {shortenText({ text: e?.description, maxLen: 40 }).text}
              </p>
            }
            trigger="hover"
            content={
              <div className="popup-common-filter-dropdown resource-table-popup">
                <div className="normal-flex-column small-gap">
                  <span className="small">{e?.description}</span>
                </div>
              </div>
            }
            showArrow={true}
          />
        </div>
      ),
      files: (
        <div className="normal-flex-row">
          <APopupModal
            button={
              <img
                className="cursor"
                src="/images/download-button.png"
                alt="DDSA Download"
                title="Download Files"
              />
            }
            trigger="hover"
            content={
              <div className="popup-common-filter-dropdown resource-table-popup">
                <div className="normal-flex-column small-gap">
                  {await Promise.all(
                    e?.files?.map(async (e: string, i: number) => {
                      const fileLink =
                        getSignedAwsUrl && (await getSignedAwsUrl(e, "job-resources"));
                      return (
                        <span key={i} className="small">
                          -&nbsp;
                          <a title="Download File" href={fileLink} download={true}>
                            {e}
                          </a>
                        </span>
                      );
                    })
                  )}
                </div>
              </div>
            }
            showArrow={true}
          />
        </div>
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
