import React, { useEffect, useRef, useState } from "react";
import "./styles/index.css";
import Engine from "./engine/enigine";
import SideNav from "./navigation/side-nav";
import AppContext from "./context/app-context";
import Loading from "./components/loading/loading";
import {
  IAppConfig,
  IBanks,
  IUsers,
  TAppScreens,
  TOneNotimeNotificationAlerts,
  TSectorTypes,
  TSubscriptionModal,
} from "./context/interface";
import Notification from "./components/notification/notification";
import AuthEntry from "./auth/entry";
import { ConfigProvider } from "antd";
import { popup } from "./vanilla-functions/model";
import { appPassKey } from "./utils";
import SubscriptionModal from "./components/subscriptions/subscriptions-modal";

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean | string>("none");
  const [usersData, setUsersData] = useState<IUsers>({} as IUsers);
  const [sideNav, setSideNav] = useState<any>({ setActiveNav: () => {} });
  const [storedContext, setStoredContext] = useState({ setCourseScreen: () => {} });
  const [contextBanks, setContextBanks] = useState<IBanks[]>([]);
  const [AppConfigs, setAppConfigs] = useState({} as IAppConfig);
  const [shownOneTimeNotificationAlert, setShownOneTimeNotificationAlert] =
    useState<TOneNotimeNotificationAlerts>({ verifications: false, account: false, news: false });

  const [subscriptionModal, setSubscriptionModal] = useState<TSubscriptionModal>({
    display: false,
    customData: undefined,
    onComplete: undefined,
    excludePlans: undefined,
  });

  const engineRef = useRef<any>(null);
  const notificationRef = useRef<any>(null);

  const backendServer = `https://ddsa-node-585992771775.us-central1.run.app/ddsa/1680/api/ddsa-node/v1`;

  const getSignedAwsUrl = async function (filename: string, bucketname: string) {
    if (!filename) return;

    if (filename.split(":")[0] === "https" || filename.split(":")[0] === "http") return filename;

    const imageFetchUrl = backendServer;
    const { data } = await (
      await fetch(`${imageFetchUrl}/file/aws/${bucketname}/${filename}`, {
        method: "GET",
        credentials: "include",
        headers: {
          token: localStorage.getItem("ddsatoken") || "",
        },
      })
    ).json();

    return data;
  };

  const updateUsersData = async function () {
    try {
      const data = await (
        await fetch(`${backendServer}/users/get?email=${localStorage.getItem("ddsaemail")}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      setUsersData(data.data);

      return data?.data;
    } catch (err) {
      popup("Something went wrong");
    }
  };

  async function getAppConfig() {
    try {
      const data = await (
        await fetch(`${backendServer}/auth/app-config/get`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
            pass: appPassKey,
          },
        })
      ).json();

      setAppConfigs(data.data);
    } catch (err) {
      popup("Failed to get application's config.");
    }
  }

  useEffect(() => {
    getAppConfig();
    getContextBanks();
  }, [loggedIn]);

  function getCurrentHash() {
    return document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim() as
      | TAppScreens
      | "auth";
  }

  const getDDSAToken = () => {
    const token = localStorage.getItem("ddsatoken");
    if (token) return token;
    return "";
  };

  async function getContextBanks() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/banks/get`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
            "content-type": "application/json",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setContextBanks(returned?.data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  function getBasedOnSectorReference(sector: TSectorTypes, based_on: string) {
    if (sector === "banks") return contextBanks?.find((bank) => bank?._id === based_on);
  }

  function getBasedOnSectorReferenceSmart(sector: TSectorTypes, based_on: string) {
    if (sector === "banks")
      return {
        data: contextBanks?.find((bank) => bank?._id === based_on),
        fieldForName: "bank_name",
      };
    else
      return {
        data: {},
        fieldForName: "",
      };
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemSelectedColor: "#E70A02",
            itemSelectedBg: "#ffdfde",
          },
          Notification: {
            zIndexPopup: 50000,
          },
          Popover: {
            zIndexPopup: 50000,
          },
          Popconfirm: {
            zIndexPopup: 50000,
          },
        },
        token: {
          colorPrimary: "#E70A02",
          fontFamily: 'font-family: "Poppins", sans-serif',
        },
      }}
    >
      <AppContext.Provider
        value={{
          changeScreen: engineRef?.current?.changeScreen,
          setIsLoading,
          loggedIn,
          usersData,
          setUsersData,
          setLoggedIn,
          backendServer,
          getSignedAwsUrl,
          updateUsersData,
          getCurrentHash,
          getDDSAToken,
          storedContext,
          setStoredContext,
          openNotification: notificationRef?.current?.openNotification,
          AppConfigs,
          shownOneTimeNotificationAlert,
          setShownOneTimeNotificationAlert,
          setSubscriptionModal,
          subscriptionModal,
          contextBanks,
          getBasedOnSectorReference,
          getBasedOnSectorReferenceSmart,
        }}
      >
        <>
          {(!loggedIn || loggedIn === "none") && <AuthEntry />}

          {loggedIn === true && (
            <div>
              <div className="App">
                <SideNav loggedIn={loggedIn} setSideNav={setSideNav} />
                <Engine sideNav={sideNav} ref={engineRef} loggedIn={loggedIn} />
              </div>
            </div>
          )}

          <Loading show={isLoading} />
          <Notification ref={notificationRef} />
          {subscriptionModal?.display && (
            <SubscriptionModal
              subscriptionModal={subscriptionModal}
              setSubscriptionModal={setSubscriptionModal}
            />
          )}
        </>
      </AppContext.Provider>
    </ConfigProvider>
  );
}

export default App;
