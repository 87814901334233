import { useState } from "react";
import "../../styles/index.css";
import "./support.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import parse from "html-react-parser";

export type TFaqCardData = {
  question: string;
  answer: string;
};

export type Prop = {
  data: TFaqCardData;
};

export const FAQCard = function ({ data }: Prop) {
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  return (
    <div className="faq-card-container" onClick={() => setShowAnswer(!showAnswer)}>
      <div className="flex-row faq-card-basics">
        <div className="faq-card-question blackText">
          <p className="biggerText blackText removemargin">{data?.question}</p>
        </div>
        <div className="flex-row faq-card-icon">
          {!showAnswer ? <AiOutlinePlus size={25} /> : <AiOutlineMinus size={25} />}
        </div>
      </div>

      {showAnswer && (
        <div className="faq-card-answer">
          <p className="small removemargin">{parse(data?.answer)}</p>
        </div>
      )}
    </div>
  );
};
