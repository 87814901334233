import { TStateAproof } from "../../screens/my-jobs/screens/submit-claim";
import { extractFileExtension, shortenText } from "../../utils";
import APopupModal from "../a-typical-popup-container/a-typical-popup-container";

const columns: any = [
  { id: "id", label: "", minWidth: 0 },
  { id: "proof_name", label: "Proof Name", minWidth: 100 },
  { id: "proof_type", label: "Type Uploaded", minWidth: 120 },
  {
    id: "value",
    label: "Value",
    minWidth: 120,
    align: "left",
  },
  {
    id: "comment",
    label: "Comment",
    minWidth: 150,
    align: "left",
  },
];

function sortData(data?: TStateAproof[]) {
  if (!data) return [];
  const finalArray = data?.map((e: TStateAproof) => {
    return {
      id: <span style={{ display: "none" }}>{e?.proof_name}</span>,
      proof_name: e?.proof_name,
      proof_type: e?.proof_type,
      value:
        e?.value && typeof e?.value === "string"
          ? extractFileExtension(e?.value)
          : e?.value?.type || "-",
      comment: (
        <APopupModal
          button={
            <div className="normal-flex-row">
              <p className="small removemargin" style={{ textAlign: "center" }}>
                {shortenText({ text: e?.comment || "", maxLen: 30 }).text || "-"}
              </p>
            </div>
          }
          content={
            <div className="popup-common-filter-dropdown claims-table-popup">
              <div className="normal-flex-column small-gap">
                <span className="small">{e?.comment}</span>
              </div>
            </div>
          }
          showArrow={true}
          trigger="hover"
        />
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
