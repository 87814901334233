import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../profile.css";
import AppContext from "../../../context/app-context.ts";
import { TProfileNavs } from "../profile.tsx";
import { IoMdArrowBack } from "react-icons/io";
import { VerificationDocsTable } from "../../../components/verification-docs-table/verification-docs-table.tsx";
import { TAVerificationDocument } from "../../../context/interface.ts";
import { transformFieldToAwsUrl } from "../../../utils/index.ts";

type Props = {
  display: boolean;
  setScreen: React.Dispatch<React.SetStateAction<TProfileNavs>>;
  getUserUnUploadedDocuments: () => string[];
};

type TtableMenu = "All" | "Pending" | "Approved" | "Processing";

export const ProfileDocsTable = function ({
  display,
  setScreen,
  getUserUnUploadedDocuments,
}: Props) {
  const [page, setPage] = useState(0);
  const [verificationDocsNavActive, setVerificationDocsNavActive] = useState<TtableMenu>("All");

  const [dataOnShow, setDataOnShow] = useState<TAVerificationDocument[]>([]);

  const { usersData } = useContext(AppContext);

  useEffect(() => {
    renderDocuments("All");
  }, [usersData]);

  async function renderDocuments(type: "All" | "Approved" | "Processing") {
    if (type === "All") {
      let transformedData: TAVerificationDocument[] = [];

      await (async function () {
        transformedData = await transformFieldToAwsUrl(
          usersData?.verification_details?.documents,
          "document_value",
          "verifications"
        );
      })();

      setDataOnShow([
        ...transformedData,
        ...getUserUnUploadedDocuments()?.map((e: any) => ({
          document_name: e,
          document_value: "",
          createdAt: 0,
          verifiedAt: 0,
          is_document_verified: false,
          id_number: "",
          _id: "",
        })),
      ]);
    }

    if (type === "Processing") {
      let transformedData: TAVerificationDocument[] = [];

      await (async function () {
        transformedData = await transformFieldToAwsUrl(
          usersData?.verification_details?.documents,
          "document_value",
          "verifications"
        );
      })();

      setDataOnShow([...transformedData?.filter((e) => e?.createdAt && !e?.is_document_verified)]);
    }

    if (type === "Approved") {
      let transformedData: TAVerificationDocument[] = [];

      await (async function () {
        transformedData = await transformFieldToAwsUrl(
          usersData?.verification_details?.documents,
          "document_value",
          "verifications"
        );
      })();

      setDataOnShow([...transformedData?.filter((e) => e?.is_document_verified)]);
    }
  }

  async function runNavigatorFilter(type: TtableMenu) {
    if (type === "All") {
      renderDocuments("All");
    }
    if (type === "Pending") {
      setDataOnShow([
        ...getUserUnUploadedDocuments()?.map((e: any) => ({
          document_name: e,
          document_value: "",
          createdAt: 0,
          verifiedAt: 0,
          is_document_verified: false,
          id_number: "",
          _id: "",
        })),
      ]);
    }
    if (type === "Approved") renderDocuments("Approved");
    if (type === "Processing") renderDocuments("Processing");
  }

  if (!display) return <></>;

  return (
    <div className="screen-container profile-screen-cont">
      <p
        className="small cursor fix-back-button"
        onClick={() =>
          setScreen({
            screen: "home",
            data: null,
          })
        }
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <h3 className="vbig-2 blackText">Document Verification Status </h3>

      <div className="vd-table-container">
        <div className="table-utilities">
          <div className="table-utilities-extra">
            <div className="flex-row wallet-table-nav">
              <p
                className={`vd-table-nav-item makeTextALittleBigger cursor ${
                  verificationDocsNavActive === "All" && "vd-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setVerificationDocsNavActive(e.currentTarget.textContent as TtableMenu);
                  runNavigatorFilter("All");
                }}
              >
                All
              </p>
              <p
                className={`vd-table-nav-item makeTextALittleBigger cursor ${
                  verificationDocsNavActive === "Pending" && "vd-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setVerificationDocsNavActive("Pending");
                  runNavigatorFilter("Pending");
                }}
              >
                Pending
              </p>

              <p
                className={`vd-table-nav-item makeTextALittleBigger cursor ${
                  verificationDocsNavActive === "Approved" && "vd-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setVerificationDocsNavActive("Approved");
                  runNavigatorFilter("Approved");
                }}
              >
                Approved
              </p>

              <p
                className={`vd-table-nav-item makeTextALittleBigger cursor ${
                  verificationDocsNavActive === "Processing" && "vd-table-nav-item-active"
                }`}
                onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
                  setVerificationDocsNavActive("Processing");
                  runNavigatorFilter("Processing");
                }}
              >
                Processing
              </p>
            </div>
          </div>
          <div className="table-utilities-icons">
            {/* <CSVLink className="cursor small csv-link" filename={`Transaction Data`} data={[]}>
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink> */}
          </div>
        </div>
        <VerificationDocsTable
          setScreen={setScreen}
          page={page}
          setPage={setPage}
          data={dataOnShow}
        />
      </div>
    </div>
  );
};
