import React, { useState, useEffect, useContext } from "react";
import "../styles/index.css";
import "./login.css";
import AppContext from "../context/app-context";
import { MDBInput } from "mdb-react-ui-kit";
import { TAuthScreens } from "./entry";
import { appPassKey, validateEmail, validateMobile } from "../utils";
import { Flex, Radio } from "antd";

export type TSignupTypes = "Agent" | "Super Agent" | "Ambassador" | "";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TAuthScreens>>;
  screen: TAuthScreens;
};

const Signup = function ({ setScreen, screen }: Props) {
  const [signupType, setSignupType] = useState<TSignupTypes>("");
  const [emailPassed, setEmailPassed] = useState(false);
  const [signupComplete, setSignupComplete] = useState(false);
  const [whatsWrong, setWhatsWrong] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [verificationEmailResent, setVerificationEmailResent] = useState(false);

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile: "",
    address: {
      addressString: "",
    },
    role: "Agent",
    who_referred_you: {
      referral_code: "",
    },
    agent_type: "Field",
  });

  const { backendServer, setIsLoading, openNotification } = useContext(AppContext);

  useEffect(() => {
    if (!data?.first_name) setWhatsWrong("Your first name is required");
    else if (!data?.last_name) setWhatsWrong("Last name is required");
    else if (!data?.password) setWhatsWrong("To proceed, please set a password");
    else if (!data?.mobile || !validateMobile(data?.mobile))
      setWhatsWrong("We need a valid mobile number to proceed");
    else if (!data?.address?.addressString) setWhatsWrong("Please provide your full address");
    else setWhatsWrong("");

    if (
      data?.first_name &&
      data?.last_name &&
      data?.password &&
      data?.address?.addressString &&
      data?.mobile &&
      validateMobile(data?.mobile)
    )
      setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function checkEmail() {
    try {
      if (!data?.email || !validateEmail(data?.email))
        return openNotification("Please enter a valid email address.", "Email Required");

      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/auth/email/exists?email=${data?.email}`, {
          method: "GET",
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned?.message);

      setEmailPassed(true);
      setIsLoading(false);
    } catch (err: any) {
      openNotification("This account already exist, please log in!");
      setIsLoading(false);
      setScreen("login");
    }
  }

  async function signupUser() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/auth/signup?role=${signupType}`, {
          method: "POST",
          credentials: "include",
          headers: {
            "content-type": "application/json",
            pass: appPassKey,
          },
          body: JSON.stringify(data),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned?.message);

      openNotification("Welcome to DDSA. Please verify your email.", `Signup Complete`);
      setSignupComplete(true);
      setIsLoading(false);
    } catch (err: any) {
      openNotification(err?.message, `Error Registering ${signupType}`);
      setIsLoading(false);
      setSignupComplete(false);
    }
  }

  async function resendVerificationEmail() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/auth/resend/email?email=${data?.email}`, {
          method: "GET",
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned?.message);

      openNotification(
        "We have sent you another email. If you are still having trouble verifying your email, please contact support.",
        `Verification Email Resent`
      );
      setVerificationEmailResent(true);
      setIsLoading(false);
    } catch (err: any) {
      openNotification("Error resending verification email. Please try again after some time.");
      setIsLoading(false);
      setVerificationEmailResent(false);
    }
  }

  return (
    <>
      <div className="login-container">
        <div className="login-container-one">
          <div className="login-cont">
            <img className="wavy-logo" src="/logo.png" alt="DDSA" />
            {!signupType && !signupComplete && (
              <>
                <p className="big boldText removemargin">Select Your Role to Get Started</p>
                <span className="small removemargin alignTextCenter login-subtitle-text">
                  Join our thriving network by choosing the role that best fits your expertise.
                  Whether you're an Agent, Super Agent, or Ambassador, we have the tools and
                  resources you need to succeed.
                </span>
              </>
            )}

            {signupType && !signupComplete && (
              <>
                <p className="big boldText removemargin">Join as an {signupType}</p>
                <span className="small removemargin alignTextCenter login-subtitle-text">
                  Fill in your details to get started. As{" "}
                  {signupType === "Super Agent" ? "a" : "an"} {signupType}, you'll be at the
                  forefront of our operations, helping us deliver excellent service and support.
                  Let's get you onboarded!
                </span>
              </>
            )}

            {signupComplete && (
              <>
                <p className="big boldText removemargin">Verify Your Email Address</p>
                <span className="small removemargin alignTextCenter login-subtitle-text">
                  Please check your mailbox and verify email address. Be sure to check your spam
                  folder.
                </span>
              </>
            )}

            {!signupType && !signupComplete && (
              <>
                <div onClick={() => setSignupType("Agent")} className="signup-card-type">
                  <span className="small boldText removemargin">Agent</span>
                </div>

                <div
                  onClick={() => {
                    return openNotification("Service not currently available.");
                    setSignupType("Ambassador");
                  }}
                  className="signup-card-type disabled"
                >
                  <span className="small boldText removemargin">Ambassador</span>
                </div>

                <div
                  onClick={() => {
                    return openNotification("Service not currently available.");
                    setSignupType("Super Agent");
                  }}
                  className="signup-card-type disabled"
                >
                  <span className="small boldText removemargin">Super Agent</span>
                </div>
              </>
            )}

            {signupComplete && (
              <>
                <div
                  onClick={() => {
                    if (verificationEmailResent) return;
                    resendVerificationEmail();
                  }}
                  className={`signup-card-type ${verificationEmailResent && "disabled"}`}
                >
                  <span className="small boldText removemargin">Resend Verification</span>
                </div>
              </>
            )}

            {signupType && !signupComplete && (
              <>
                {!emailPassed && (
                  <div className="attached-in-cont attached-login-in">
                    <input
                      type="email"
                      className="in"
                      placeholder="name@gmail.com"
                      onChange={(e: any) => setData((p) => ({ ...p, email: e?.target?.value }))}
                    />
                    <button className="themeBtn" onClick={checkEmail}>
                      Continue
                    </button>
                  </div>
                )}

                {emailPassed && !signupComplete && (
                  <div className="signup-fields-cont">
                    <div className="universal-select-button-group-double login-select-option">
                      <div
                        className={
                          data?.agent_type === "Field"
                            ? "universal-select-button-group-double-active"
                            : ""
                        }
                        onClick={() => setData((p) => ({ ...p, agent_type: "Field" }))}
                      >
                        Field Agent
                      </div>
                      <div
                        className={
                          data?.agent_type === "Shop"
                            ? "universal-select-button-group-double-active"
                            : ""
                        }
                        onClick={() => setData((p) => ({ ...p, agent_type: "Shop" }))}
                      >
                        Shop Agent
                      </div>
                    </div>

                    <MDBInput
                      required={true}
                      title="First Name"
                      label="First Name*"
                      type="text"
                      wrapperClass="mdbIn mdb-login-in-flat"
                      labelClass="mdb-dropdown-custom-in"
                      onChange={(e: any) =>
                        setData((p) => ({ ...p, first_name: e?.target?.value }))
                      }
                    />

                    <MDBInput
                      required={true}
                      title="Last Name"
                      label="Last Name*"
                      type="text"
                      wrapperClass="mdbIn mdb-login-in-flat"
                      labelClass="mdb-dropdown-custom-in"
                      onChange={(e: any) => setData((p) => ({ ...p, last_name: e?.target?.value }))}
                    />

                    <MDBInput
                      required={true}
                      title="Password"
                      label="Password*"
                      type="password"
                      wrapperClass="mdbIn mdb-login-in-flat"
                      labelClass="mdb-dropdown-custom-in"
                      onChange={(e: any) => setData((p) => ({ ...p, password: e?.target?.value }))}
                    />

                    <MDBInput
                      required={true}
                      title="Phone Number"
                      label="Phone Number*"
                      type="text"
                      wrapperClass="mdbIn mdb-login-in-flat"
                      labelClass="mdb-dropdown-custom-in"
                      onChange={(e: any) => setData((p) => ({ ...p, mobile: e?.target?.value }))}
                    />

                    <MDBInput
                      required={true}
                      title="Residential Address"
                      label="Residential Address*"
                      type="text"
                      wrapperClass="mdbIn mdb-login-in-flat"
                      labelClass="mdb-dropdown-custom-in"
                      onChange={(e: any) =>
                        setData((p) => ({ ...p, address: { addressString: e?.target?.value } }))
                      }
                    />

                    <MDBInput
                      required={false}
                      title="Ambassador Code"
                      label="Ambassador Code"
                      type="text"
                      wrapperClass="mdbIn mdb-login-in-flat"
                      labelClass="mdb-dropdown-custom-in"
                      onChange={(e: any) =>
                        setData((p) => ({
                          ...p,
                          who_referred_you: { referral_code: e.target.value },
                        }))
                      }
                    />

                    <button
                      disabled={disableBtn}
                      onClick={signupUser}
                      className="themeBtn login-btn"
                    >
                      Continue
                    </button>

                    <span className="redText small removemargin">{whatsWrong}</span>
                  </div>
                )}
              </>
            )}

            <span
              className="small removemargin alignTextCenter login-subtitle-text"
              style={{ marginTop: 30 }}
            >
              Already have an account?{" "}
              <a
                className="blueText"
                href="#auth"
                onClick={(e) => {
                  e.preventDefault();
                  setScreen("login");
                }}
              >
                Sign In
              </a>
            </span>

            {!signupComplete && (
              <span className="small removemargin alignTextCenter login-subtitle-text">
                By clicking the “Sign up” button, you are creating a DDSA account and therefore you
                agree to DDSA’s Terms of Use and Privacy Policy.
              </span>
            )}
          </div>
        </div>
        <div className="login-container-two">
          <img className="login-banner-img" src="/images/login-banner.png" alt="DDSA" />
        </div>
      </div>
    </>
  );
};

export default Signup;
