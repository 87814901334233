import React, { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../profile.css";
import AppContext from "../../../context/app-context.ts";
import { copiedToClipboard } from "../../../utils/index.ts";
import APopupModal from "../../../components/a-typical-popup-container/a-typical-popup-container.tsx";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";
import { VerificationIndicators } from "../../../components/verification-indicators/verification-indicators.tsx";
import { EditProfileBasicInfo } from "./edit-basic-info.tsx";
import { TProfileNavs } from "../profile.tsx";
import { IUsers, TAVerificationDocument } from "../../../context/interface.ts";
import { popup } from "../../../vanilla-functions/model.js";

type Props = {
  display: boolean;
  setScreen: React.Dispatch<React.SetStateAction<TProfileNavs>>;
  getUserUnUploadedDocuments: () => string[];
};

export const ProfileHome = function ({ display, setScreen, getUserUnUploadedDocuments }: Props) {
  const [editProfileMode, setEditProfileMode] = useState(false);
  const [unuploadedDocuments, setUploadedDocuments] = useState<string[]>([]);
  const [userImage, setUserImage] = useState<string>("");

  const {
    backendServer,
    openNotification,
    setIsLoading,
    updateUsersData,
    usersData,
    setSubscriptionModal,
    getSignedAwsUrl,
  } = useContext(AppContext);

  useEffect(() => {
    setUploadedDocuments(getUserUnUploadedDocuments());
  }, [usersData]);

  useEffect(() => {
    (async function () {
      setUserImage(await getSignedAwsUrl(usersData?.profile_picture, "profile-pictures"));
    })();
  });

  const handleUploadProfilePicture = async (imageUri: File) => {
    try {
      setIsLoading(true);

      const formData = new FormData();

      formData.append("photo", imageUri);

      const returned = await (
        await fetch(`${backendServer}/users/profile/pic/${usersData?._id}`, {
          method: "PATCH",
          body: formData,
          headers: {
            enctype: "multipart/form-data",
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      updateUsersData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openNotification(
        "We can't update your profile picture right now. Try again after some time."
      );
    }
  };

  function resolvePlans(userData: IUsers): React.ReactNode {
    if (userData?.plan === "Basic")
      return (
        <>
          <div className="normal-flex-row space-between">
            <p className="biggerText removemargin">
              <img alt="ddsa-plans" src="/images/icons/verified-icon.png" /> &nbsp; Current Plan
            </p>
            <p className="biggerText blackText removemargin">Basic Plan</p>
          </div>

          <div className="normal-flex-row space-between">
            <p className="biggerText blackText removemargin">Premium Plan</p>
            <button onClick={() => setSubscriptionModal({ display: true })} className="themeBtn">
              Upgrade Plan
            </button>
          </div>

          <div className="normal-flex-row space-between">
            <p className="biggerText blackText removemargin">Ultimate Plan</p>
            <button onClick={() => setSubscriptionModal({ display: true })} className="themeBtn">
              Upgrade Plan
            </button>
          </div>
        </>
      );
    else if (userData?.plan === "Premium")
      return (
        <>
          <div className="normal-flex-row space-between">
            <p className="biggerText blackText removemargin">Basic Plan</p>
            <button
              disabled={true}
              onClick={() => setSubscriptionModal({ display: true })}
              className="themeBtn"
            >
              Upgrade Plan
            </button>
          </div>

          <div className="normal-flex-row space-between">
            <p className="biggerText removemargin">
              <img alt="ddsa-plans" src="/images/icons/verified-icon.png" /> &nbsp; Current Plan
            </p>
            <p className="biggerText blackText removemargin">Premium Plan</p>
          </div>

          <div className="normal-flex-row space-between">
            <p className="biggerText blackText removemargin">Ultimate Plan</p>
            <button onClick={() => setSubscriptionModal({ display: true })} className="themeBtn">
              Upgrade Plan
            </button>
          </div>
        </>
      );
    else if (userData?.plan === "Ultimate")
      return (
        <>
          <div className="normal-flex-row space-between">
            <p className="biggerText blackText removemargin">Basic Plan</p>
            <button
              disabled={true}
              onClick={() => setSubscriptionModal({ display: true })}
              className="themeBtn"
            >
              Upgrade Plan
            </button>
          </div>

          <div className="normal-flex-row space-between">
            <p className="biggerText blackText removemargin">Premium Plan</p>
            <button
              disabled={true}
              onClick={() => setSubscriptionModal({ display: true })}
              className="themeBtn"
            >
              Upgrade Plan
            </button>
          </div>

          <div className="normal-flex-row space-between">
            <p className="biggerText removemargin">
              <img alt="ddsa-plans" src="/images/icons/verified-icon.png" /> &nbsp; Current Plan
            </p>
            <p className="biggerText blackText removemargin">Ultimate Plan</p>
          </div>
        </>
      );
  }

  if (!display) return <></>;

  return (
    <div className="screen-container profile-screen-cont">
      <h3 className="vbig blackText">Profile</h3>

      <div className="profile-section-box">
        <div className="normal-flex-row small-gap">
          <div className="upload-profile-card cursor">
            <img
              src={userImage || "/images/user_placeholder.png"}
              alt="ddsa user"
              className="profile-pic"
            />
            <img
              src={"/images/icons/camera-icon.png"}
              alt="ddsa user"
              className="profile-pic-annotation"
            />
            <input
              type="file"
              accept=".jpeg,.png,.jpg"
              onChange={async ({ target: { files } }) => {
                if (files !== null) {
                  const file = files[0];
                  try {
                    handleUploadProfilePicture(file);
                  } catch (error) {
                    // console.log(error)
                  }
                }
              }}
            />
          </div>

          <p className="big blackText boldText removemargin">
            {usersData?.first_name} {usersData?.last_name}
            <br></br>
            <span className="small greyText">{usersData?.platform_id}</span>
          </p>
        </div>
      </div>

      {!editProfileMode && (
        <div className="profile-section-box profile-info-box">
          <div className="normal-flex-row space-between">
            <p className="big removemargin">Personal Information</p>

            <img
              className="cursor"
              title="Edit Personal Information"
              src="/images/icons/edit-icon.png"
              alt="Edit Personal Information"
              onClick={() => setEditProfileMode(true)}
            />
          </div>

          <p className="small blackText removemargin">
            Full Name:{" "}
            <span className="greyText">
              {usersData?.first_name} {usersData?.last_name}
            </span>
          </p>

          <p className="small blackText removemargin">
            Phone Number: <span className="greyText">{usersData?.mobile}</span>
          </p>

          <p className="small blackText removemargin">
            Email Address: <span className="greyText">{usersData?.email}</span>
          </p>

          <p className="small blackText removemargin">
            Home Address: <span className="greyText">{usersData?.address?.addressString}</span>
          </p>

          <hr className="border-line"></hr>

          <p className="small blackText removemargin">
            Role:{" "}
            <span className="greyText">
              {usersData?.role} {usersData?.agent_type ? `(${usersData?.agent_type})` : ""}
            </span>
          </p>

          <p className="small blackText removemargin">
            Platform ID:{" "}
            <span className="greyText">
              {usersData?.platform_id} &nbsp;{" "}
              <MdContentCopy
                className="cursor"
                size={17}
                color="#E70A02"
                style={{ marginTop: -2 }}
                onClick={async () => await copiedToClipboard(usersData?.platform_id)}
              />{" "}
            </span>
          </p>

          <p className="normal-flex-row">
            <p className="small blackText removemargin">Current Plan:</p>
            <APopupModal
              button={
                <span className="small blackText greyText cursor">
                  Your Plan <MdOutlineKeyboardArrowDown style={{ marginTop: -2 }} size={20} />
                </span>
              }
              content={
                <div className="popup-common-filter-dropdown subscription-modal-popup">
                  <div className="normal-flex-column small-gap subscription-modal-popup-inner">
                    {resolvePlans(usersData)}
                  </div>
                </div>
              }
              zIndex={100}
            />
          </p>
        </div>
      )}

      {editProfileMode && <EditProfileBasicInfo setEditProfileMode={setEditProfileMode} />}

      <div className="profile-section-box profile-info-box">
        <div className="normal-flex-row space-between">
          <p className="big removemargin">Identification Information</p>

          <img
            className="cursor"
            title="Edit Identification Information"
            src="/images/icons/edit-icon.png"
            alt="Edit Personal Information"
            onClick={() =>
              setScreen({
                screen: "documents-table",
                data: null,
              })
            }
          />
        </div>

        <p className="small blackText removemargin">Document Verification Status</p>

        <div className="profile-avf-indicators-container">
          <VerificationIndicators
            styles={{ justifyContent: "flex-start" }}
            showAllIndicators={true}
            title=""
          />
        </div>

        <p className="small blackText boldText removemargin">Uploaded Documents</p>

        {!usersData?.verification_details?.documents?.length && (
          <p className="small removemargin">No uploaded documents yet.</p>
        )}

        {usersData?.verification_details?.documents?.map((e: TAVerificationDocument, i: number) => (
          <p key={i} className="small blackText removemargin">
            {e?.document_name}: &nbsp;{" "}
            <span
              className={`${
                e?.is_document_verified
                  ? "status-approved"
                  : e?.createdAt && !e?.is_document_verified
                  ? "status-processing"
                  : "status-rejected "
              }`}
            >
              {e?.is_document_verified
                ? "Approved"
                : e?.createdAt && !e?.is_document_verified
                ? "Processing"
                : "Unapproved"}
            </span>
          </p>
        ))}

        <p className="small blackText boldText removemargin" style={{ marginTop: 10 }}>
          Pending Upload
        </p>
        {!unuploadedDocuments?.length && (
          <p className="small removemargin">You have no document pending upload!</p>
        )}
        {unuploadedDocuments?.map((e: string, i: number) => (
          <p key={i} className="small blackText removemargin">
            {e}: &nbsp; <span className="status-pending">Pending</span>
          </p>
        ))}

        <hr className="border-line"></hr>

        <p className="small blackText removemargin" style={{ marginBottom: 40 }}>
          {usersData?.who_referred_you?.userID?.role || "Super Agent"} Code:{" "}
          <span className="greyText">
            {usersData?.who_referred_you?.referral_code || "none"} &nbsp;{" "}
            <MdContentCopy
              className="cursor"
              size={17}
              color="#E70A02"
              style={{ marginTop: -2 }}
              onClick={async () => {
                if (!usersData?.who_referred_you?.referral_code)
                  return popup("You have no Super Agent or Ambassador");
                await copiedToClipboard(usersData?.who_referred_you?.referral_code);
              }}
            />{" "}
          </span>
        </p>
      </div>
    </div>
  );
};
