import { useState, useEffect, useContext } from "react";
import "./bank-list-modal.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBInput } from "mdb-react-ui-kit";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AutoComplete } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

type Prop = {
  display: boolean;
  setaddBankAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddBankModal = ({ display, setaddBankAccountModal }: Prop) => {
  const { backendServer, usersData, setIsLoading, updateUsersData, openNotification } =
    useContext(AppContext);

  const [disableBtn, setDisableBtn] = useState(true);

  const [paystackBanks, setPayStackBank] = useState([]);

  const [bankAccount, setBankAccount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [primary, setPrimary] = useState(false);

  useEffect(() => {
    if (bankAccount && accountNumber && accountName) setDisableBtn(false);
    else setDisableBtn(true);

    if (String(accountNumber).length < 10) setDisableBtn(true);
  });

  useEffect(() => {
    (async () => {
      const { data } = await (await fetch(`https://api.paystack.co/bank`)).json();
      setPayStackBank(data.map((el: any) => ({ value: el?.name })));
    })();
  }, [display]);

  async function handleSubmit() {
    try {
      if (!bankAccount) return openNotification("Not sure but I'm sure something went wrong :(");

      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/users/bank/add/${usersData?._id}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            bank_name: bankAccount,
            account_number: accountNumber,
            account_name: accountName,
            is_primary: primary,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setaddBankAccountModal(false);
      setBankAccount("");
      setAccountNumber("");
      setAccountName("");
      setPrimary(false);
      setIsLoading(false);
      updateUsersData();
      openNotification("Bank account created");
    } catch (err) {
      openNotification("Something went wrong");
      setIsLoading(false);
    }
  }

  return (
    <div
      className="modal-container"
      style={{ display: `${display ? "flex" : "none"}`, zIndex: 30000 }}
    >
      <div className="modal-body add-bank-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setaddBankAccountModal(false);
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="banks-modal-cont">
          <div className="flex-row space-between">
            <p className="big boldText blackText">Add a Bank Account</p>

            <button disabled={disableBtn} className="themeBtn" onClick={handleSubmit}>
              Save
            </button>
          </div>

          <div className="manual-autocomplete-container">
            <AutoComplete
              style={{ width: "100%", height: "42px" }}
              options={paystackBanks}
              placeholder="Choose Bank"
              className="manual-autocomplete-container-input"
              filterOption={(inputValue, option: any) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value: string) => {
                setBankAccount(value);
              }}
              value={bankAccount}
              dropdownStyle={{ zIndex: 40000 }}
            />

            <MdOutlineKeyboardArrowDown className="manual-autocomplete-container-icon" size={20} />
          </div>

          <MDBInput
            title="Account Number"
            label="Account Number"
            type="number"
            wrapperClass="mdbIn mdbInFullWidth"
            onChange={(e) => setAccountNumber(e.target.value)}
            labelClass="mdb-dropdown-custom-in"
            onBlur={() => {
              if (String(accountNumber).length < 10) popup("Invalid Account Number");
            }}
            value={accountNumber}
            onClick={(e) => e.stopPropagation()}
          />

          <MDBInput
            title="Account Name"
            label="Account Name"
            type="text"
            wrapperClass="mdbIn mdbInFullWidth"
            labelClass="mdb-dropdown-custom-in"
            value={accountName}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setAccountName(e.target.value)}
          />

          <div className="flex-row align-row-left">
            <p className="small">
              <input
                type="checkbox"
                onChange={() => {}}
                onClick={() => setPrimary(!primary)}
                checked={primary}
              />
              &nbsp; Make this primary account for withdrawals
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
