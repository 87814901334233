import { TAppPlans } from "../context/interface";
import { popup } from "../vanilla-functions/model";

export const appBaseUrl =
  "https://ddsa-node-585992771775.us-central1.run.app/ddsa/1680/api/ddsa-node/v1";

export const bankAccountUniqueUrlBase = "https://agent.ddsa-live.com/banks/accounts/open";

export function getLatestDate(dates: Date[]): Date {
  if (dates.length === 0) return new Date(Date.now());

  return dates.reduce((latest, current) => {
    return current > latest ? current : latest;
  });
}

export function getPriceRange(prices: number[], specific?: "min" | "max"): string {
  if (prices.length === 0) return "";

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  if (specific === "min") return `${minPrice}`;
  else if (specific === "max") return `${maxPrice}`;
  else return `${minPrice}-${maxPrice}`;
}

export function capitalizeEachWord(word: string): string {
  if (!word) return "";
  const arr = word.split(" ")?.filter((e) => e);
  const mapped = arr.map((e: string) => {
    return e[0].toUpperCase() + e.slice(1).toLowerCase();
  });

  return mapped.join(" ");
}

export function padAccountNumber(accountNumber?: string): string {
  if (!accountNumber) return "";

  if (accountNumber.length <= 8) {
    throw new Error("Account number must be longer than 8 characters.");
  }

  const start = accountNumber?.slice(0, 4);
  const end = accountNumber?.slice(-4);
  const middle = accountNumber?.slice(4, -4)?.replace(/./g, "*");

  return `${start}${middle}${end}`;
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result + "";
      if (base64Data) {
        resolve(base64Data);
      } else {
        reject(new Error("Base64 data not found"));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const getImageUrlFromFile = (file: File, getRes?: (url: string) => void) => {
  const reader = new FileReader();

  let res = "";

  reader.onload = (function (theFile) {
    return function (e) {
      const result = e.target?.result;
      if (typeof result === "string") {
        res = result;
        getRes && getRes(result);
      }
    };
  })(file);

  reader.readAsDataURL(file);

  return res;
};

export function averagePercentageRate(values: number[]): number {
  if (values.length === 0) return 0;

  const total = values.reduce((sum, value) => sum + value, 0);
  const average = total / values.length;
  const percentageRate = (average / 100) * 100;

  return Math.trunc(percentageRate);
}

export function calculatePercentage(totalAmount: number, amount: number): string | number {
  if (!amount || !totalAmount) return 0;
  const percentage = (amount / totalAmount) * 100;
  return Math.trunc(percentage);
}

export function formatNumber(amount: number) {
  return new Intl.NumberFormat("en-us").format(amount);
}

export function getDateValue(date: Date | number, _type: "short" | "long" = "long") {
  if (!date) return "";
  return new Intl.DateTimeFormat("en-us", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(date));
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateMobile = (mobile: string) => {
  return String(mobile)
    .toLowerCase()
    .match(/^(?:(?:(?:\+?234(?:\h1)?|01)\h*)?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/);
};

export function patternMatching(wordone: string, wordtwo: string) {
  if (!wordone || !wordtwo) return false;
  //wrote this for name searching
  let result = wordone?.split("")?.some((e, i) => {
    return String(e)?.toUpperCase() !== String(wordtwo?.split("")[i])?.toUpperCase();
  });
  return !result;
}

export function timeAgo(dateString: string | Date | number): string {
  const givenDate = new Date(dateString);
  const now = new Date();

  const diffInMilliseconds = now.getTime() - givenDate.getTime();

  const millisecondsInMinute = 1000 * 60;
  const millisecondsInHour = millisecondsInMinute * 60;
  const millisecondsInDay = millisecondsInHour * 24;
  const millisecondsInMonth = millisecondsInDay * 30.44; // Average days in a month
  const millisecondsInYear = millisecondsInDay * 365.25; // Average days in a year

  const years = Math.floor(diffInMilliseconds / millisecondsInYear);
  const months = Math.floor((diffInMilliseconds % millisecondsInYear) / millisecondsInMonth);
  const days = Math.floor((diffInMilliseconds % millisecondsInMonth) / millisecondsInDay);
  const hours = Math.floor((diffInMilliseconds % millisecondsInDay) / millisecondsInHour);
  const minutes = Math.floor((diffInMilliseconds % millisecondsInHour) / millisecondsInMinute);

  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  }
}

type TShortenTextArgs = {
  text: string;
  maxLen: number;
  replacer?: string | any;
  callback?: (fullText: string) => void;
};

export const shortenText = (args: TShortenTextArgs) => {
  if (args.text.length < args.maxLen) {
    return { text: args.text, callback: null };
  } else {
    return {
      text: args.text.slice(0, args.maxLen) + (args.replacer || "..."),
      callback: args.callback && args.callback(args.text),
    };
  }
};

export function getTotalNumberFromObjectArray(arr: any[], field: string) {
  let total: number = 0;

  arr?.forEach((e: any) => {
    if (!e[field]) return;

    total = total + Number(e[field]);
  });

  return total;
}

export function getLastFourMonths(): string[] {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const now = new Date();
  const currentMonth = now.getMonth();
  const months = [];

  for (let i = 4; i >= 0; i--) {
    const monthIndex = (currentMonth - i + 12) % 12;
    months.push(monthNames[monthIndex]);
  }

  return months;
}

export async function copiedToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    popup("Copied to clipboard!");
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export function calcDiscount(mode: "quarter" | "year", amount: number, discount: number) {
  const monthsNumber = {
    quarter: 4,
    year: 12,
  };

  return (Number(amount) - (Number(discount) / 100) * Number(amount)) * monthsNumber[mode];
}

export function extractFileExtension(filename: string) {
  const extension = filename?.split(".")[filename?.split(".")?.length - 1];

  let name = "unknown";

  if (extension === "jpeg" || extension === "png" || extension === "jpg") name = "image";
  else name = "document";

  return `${name}.${extension}`;
}

export async function transformFieldToAwsUrl(array: any[], field: string, bucketname: string) {
  try {
    const imageFetchUrl = appBaseUrl;

    const newArray = [...JSON.parse(JSON.stringify(array))];

    const transformed = newArray?.map(async (e: any) => {
      const { data } = await (
        await fetch(`${imageFetchUrl}/file/aws/${bucketname}/${e[field]}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      e.originalFileName = e[field];
      e[field] = data;

      return e;
    });

    return await Promise.all(transformed);
  } catch (err) {
    return array;
  }
}

export async function downloadMultipleFiles(links: string[]): Promise<void> {
  for (const link of links) {
    try {
      const response = await fetch(link);
      if (!response.ok) {
        throw new Error(`Failed to download files`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = link.split("/").pop() || "download";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      popup("Error downloading files");
    }
  }
}

export const APP_PAYSTACK_KEY = "pk_live_63b369abaee0d4266e9b051eb08bd3be0d72d56f";

export const appPassKey = "pass-66966c874edc2a5271f1737f";

export const appPlansIndexes: Record<TAppPlans, number> = {
  Basic: 1,
  Premium: 2,
  Ultimate: 3,
};

export const appPlansArray: TAppPlans[] = ["Basic", "Premium", "Ultimate"];
