import React, { useState, useContext, useEffect } from "react";
import "../styles/index.css";
import "./login.css";
import AppContext from "../context/app-context";
import Login from "./login";
import Signup from "./signup";

export type TAuthScreens = "login" | "signup";

const AuthEntry = function () {
  const [screen, setScreen] = useState<TAuthScreens>("login");

  const {
    loggedIn,
    backendServer,
    setLoggedIn,
    setIsLoading,
    openNotification,
    setUsersData,
    getCurrentHash,
  } = useContext(AppContext);

  async function runAutoLogin() {
    setIsLoading(true);
    try {
      const data = await (
        await fetch(`${backendServer}/users/get?email=${localStorage.getItem("ddsaemail")}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (!data?.data?.is_active || !data?.data?.is_email_verified)
        throw new Error("Authentication error");

      setUsersData(data.data);
      setLoggedIn(true);
      setIsLoading(false);

      if (getCurrentHash() === "auth" || !getCurrentHash()) document.location.hash = "dashboard";
    } catch (err) {
      openNotification && openNotification("Login Failed. Please login again...");
      setIsLoading(false);
      setLoggedIn(false);
      localStorage.removeItem("ddsaemail");
      localStorage.removeItem("ddsatoken");
      document.location.hash = "auth";
    }
  }

  useEffect(() => {
    if (localStorage.getItem("ddsaemail")) runAutoLogin();
    else {
      setLoggedIn(false);
      document.location.hash = "auth";
    }
  }, [backendServer]);

  if (loggedIn === "none") return <></>;

  return (
    <>
      {screen === "login" && <Login screen={screen} setScreen={setScreen} />}
      {screen === "signup" && <Signup screen={screen} setScreen={setScreen} />}
    </>
  );
};

export default AuthEntry;
