import { useState, useEffect, useContext } from "react";
import "./bank-list-modal.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBInput } from "mdb-react-ui-kit";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { TABankAccount } from "../../context/interface";
import { AutoComplete } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

export type TEditBankAccountModalActivity = {
  display: boolean;
  data?: TABankAccount;
};

type Prop = {
  editBankAccountModal: TEditBankAccountModalActivity;
  setEditBankAccountModal: React.Dispatch<React.SetStateAction<TEditBankAccountModalActivity>>;
};

const EditBankModal = ({ editBankAccountModal, setEditBankAccountModal }: Prop) => {
  const { backendServer, setIsLoading, updateUsersData, openNotification, usersData } =
    useContext(AppContext);

  const [disableBtn, setDisableBtn] = useState(true);

  const [paystackBanks, setPayStackBank] = useState([]);

  const [bankAccount, setBankAccount] = useState(editBankAccountModal?.data?.bank_name);
  const [accountName, setAccountName] = useState(editBankAccountModal?.data?.account_name);
  const [accountNumber, setAccountNumber] = useState(editBankAccountModal?.data?.account_number);

  useEffect(() => {
    setBankAccount(editBankAccountModal?.data?.bank_name);
    setAccountName(editBankAccountModal?.data?.account_name);
    setAccountNumber(editBankAccountModal?.data?.account_number);
  }, [editBankAccountModal?.data]);

  useEffect(() => {
    if (bankAccount || accountNumber || accountName) setDisableBtn(false);
    else setDisableBtn(true);

    if (accountNumber && String(accountNumber).length < 10) setDisableBtn(true);
  });

  useEffect(() => {
    (async () => {
      const { data } = await (await fetch(`https://api.paystack.co/bank`)).json();
      setPayStackBank(data.map((el: any) => ({ value: el?.name })));
    })();
  }, [editBankAccountModal?.display]);

  async function handleSubmit() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(
          `${backendServer}/users/bank/update/${usersData?._id}/${editBankAccountModal?.data?._id}`,
          {
            method: "PATCH",
            credentials: "include",
            headers: {
              token: localStorage.getItem("ddsatoken") || "",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              bank_name: bankAccount || editBankAccountModal?.data?.bank_name,
              account_number: accountNumber || editBankAccountModal?.data?.account_number,
              account_name: accountName || editBankAccountModal?.data?.account_name,
            }),
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setIsLoading(false);
      updateUsersData();
      setEditBankAccountModal({
        display: false,
        data: undefined,
      });
      setBankAccount("");
      setAccountNumber("");
      setAccountName("");
      openNotification("The details of your bank account has been updated.", "Update Successful!");
    } catch (err) {
      openNotification("We can't update your bank details right now. Try again after some time.");
      setIsLoading(false);
    }
  }

  return (
    <div
      className="modal-container"
      style={{ display: `${editBankAccountModal?.display ? "flex" : "none"}`, zIndex: 30000 }}
    >
      <div className="modal-body add-bank-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setEditBankAccountModal({
                display: false,
                data: undefined,
              });
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="banks-modal-cont">
          <div className="flex-row space-between">
            <p className="big boldText blackText">Edit a Bank Account</p>

            <button disabled={disableBtn} className="themeBtn" onClick={handleSubmit}>
              Save
            </button>
          </div>

          <div className="manual-autocomplete-container">
            <AutoComplete
              style={{ width: "100%", height: "42px" }}
              options={paystackBanks}
              placeholder="Choose Bank"
              className="manual-autocomplete-container-input"
              filterOption={(inputValue, option: any) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value: string) => {
                setBankAccount(value);
              }}
              value={bankAccount}
              dropdownStyle={{ zIndex: 40000 }}
            />

            <MdOutlineKeyboardArrowDown className="manual-autocomplete-container-icon" size={20} />
          </div>

          <MDBInput
            title="Account Number"
            label="Account Number"
            type="number"
            wrapperClass="mdbIn mdbInFullWidth"
            onChange={(e) => setAccountNumber(e.target.value)}
            labelClass="mdb-dropdown-custom-in"
            onBlur={() => {
              if (String(accountNumber).length < 10) popup("Invalid Account Number");
            }}
            value={accountNumber}
            onClick={(e) => e.stopPropagation()}
          />

          <MDBInput
            title="Account Name"
            label="Account Name"
            type="text"
            wrapperClass="mdbIn mdbInFullWidth"
            labelClass="mdb-dropdown-custom-in"
            value={accountName}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setAccountName(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export { EditBankModal };
