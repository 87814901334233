import React, { StyleHTMLAttributes } from "react";
import "../../styles/index.css";
import "./floral-divider.css";

type Prop = {
  header: string | number;
  customStyles?: React.CSSProperties;
};

export const FloralDivider = function ({ header, customStyles }: Prop) {
  return (
    <div style={customStyles} className="flex-row floral-divider-container">
      <h3 className="big blackText" style={{ fontSize: 25 }}>
        {header}
      </h3>
    </div>
  );
};
