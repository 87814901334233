import React, { useState, useContext, useEffect } from "react";
import "../../styles/index.css";
import "./bank-list-modal.css";
import AppContext from "../../context/app-context";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TABankAccount } from "../../context/interface";
import BankAccountCard from "./a-bank-card";
import { AddBankModal } from "./add-a-bank-account-modal";
import { EditBankModal, TEditBankAccountModalActivity } from "./edit-a-bank-modal";

export type TBankModalActivity = {
  display: boolean;
  data: TABankAccount[];
};

type Prop = {
  bankAccountModal: TBankModalActivity;
  setBankAccountModal: React.Dispatch<React.SetStateAction<TBankModalActivity>>;
};

const BankAccountsModal = function ({ bankAccountModal, setBankAccountModal }: Prop) {
  const [banksComponents, setBankComponents] = useState<React.ReactNode[]>([]);

  const [showAddABankModal, setShowAddABankModal] = useState(false);
  const [editBankModal, setEditBankModal] = useState<TEditBankAccountModalActivity>({
    display: false,
    data: undefined,
  });

  const { usersData } = useContext(AppContext);

  useEffect(() => {
    setBankComponents(
      usersData?.bank_accounts?.map((e, i) => (
        <BankAccountCard data={e} key={i} setEditBankModal={setEditBankModal} />
      ))
    );
  }, [usersData]);

  return (
    <div
      className="modal-container"
      style={{ display: `${bankAccountModal?.display ? "flex" : "none"}` }}
    >
      <div className="modal-body bank-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setBankAccountModal({ display: false, data: [] });
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="banks-modal-cont">
          <div className="flex-row space-between">
            <p className="big boldText blackText">Bank Accounts</p>
            <button
              disabled={usersData?.bank_accounts?.length >= 5 && true}
              onClick={() => setShowAddABankModal(true)}
              className="themeBtn"
            >
              Add
            </button>
          </div>

          <div className="banks-modal-where-bank-accounts-are">
            {banksComponents}
            {!banksComponents?.length && <p className="small">You have no bank accounts yet...</p>}
          </div>
        </div>
      </div>

      <AddBankModal display={showAddABankModal} setaddBankAccountModal={setShowAddABankModal} />

      <EditBankModal
        editBankAccountModal={editBankModal}
        setEditBankAccountModal={setEditBankModal}
      />
    </div>
  );
};

export default BankAccountsModal;
