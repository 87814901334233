import { IUsers, TAJob } from "../../context/interface";
import { getDateValue, getLatestDate, shortenText } from "../../utils";

const columns: any = [
  { id: "id", label: "", minWidth: 0 },
  { id: "job_name", label: "Name", minWidth: 150 },
  { id: "job_description", label: "Description", minWidth: 150 },
  {
    id: "last_submitted_claim",
    label: "Date of Last Claim Submitted",
    minWidth: 100,
    align: "left",
  },
  {
    id: "last_approved_claim",
    label: "Date of Last Approved Claimed",
    minWidth: 100,
    align: "left",
  },
  {
    id: "earnings",
    label: "Earnings (₦)",
    minWidth: 80,
    align: "left",
  },
];

function sortData(data?: TAJob[], usersData?: IUsers, getBasedOnSectorReferenceSmart?: any) {
  if (!data) return [];
  const finalArray = data?.map((e: TAJob) => {
    return {
      id: <span style={{ display: "none" }}>{e?._id}</span>,
      job_name:
        shortenText({ text: e?.job_id?.job_name, maxLen: 50 }).text +
        ` (${(function () {
          const fieldName =
            getBasedOnSectorReferenceSmart &&
            getBasedOnSectorReferenceSmart("banks", e?.job_id?.based_on)?.fieldForName;

          return (
            getBasedOnSectorReferenceSmart &&
            getBasedOnSectorReferenceSmart("banks", e?.job_id?.based_on)?.data[fieldName]
          );
        })()})`,
      job_description: shortenText({ text: e?.job_id?.job_description, maxLen: 80 }).text,
      last_submitted_claim: e?.completed?.length
        ? getDateValue(getLatestDate(e?.completed?.map((e) => e?.createdAt)))
        : "-",
      last_approved_claim: e?.completed?.length
        ? getDateValue(
            getLatestDate(
              e?.completed?.filter((e) => e?.status === "approved")?.map((e) => e?.createdAt)
            )
          )
        : "-",
      earnings: (function () {
        let total = 0;

        usersData?.wallet?.transactions?.forEach((tr) => {
          if (
            tr?.mode === "job" &&
            tr?.transaction_type === "credit" &&
            tr?.status === "completed" &&
            tr?.paymentRef?.reference?.job_id === e?.job_id?._id
          )
            total = total + tr?.amount;
        });

        return total;
      })(),
    };
  });

  return finalArray;
}

export { sortData, columns };
