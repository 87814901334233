import React from "react";
import { timeAgo } from "../../utils";
import "./notification-card.css";
import { TANotificationScreenOptions } from "../engine-top-bar/engine-top-bar";
import { TANotification } from "../../context/interface";

type Prop = {
  data: TANotification;
  markNotificationAsRead: (id?: string) => Promise<any>;
  setNotificationsOnView: React.Dispatch<React.SetStateAction<TANotificationScreenOptions>>;
};

export const NotificationCard = ({
  markNotificationAsRead,
  data,
  setNotificationsOnView,
}: Prop) => {
  return (
    <button
      onClick={async () => {
        await markNotificationAsRead(data?._id);
        setNotificationsOnView({
          type: "details",
          data: data,
        });
      }}
      className={`container-button ${!data?.is_read && "container-button-active"}`}
    >
      <div className="notification-boxes">
        <div className="notification-items">
          <div className="small blackText boldText alignTextLeft">{data?.title?.slice(0, 70)}</div>
          <div className="small alignTextLeft">
            {data?.message?.slice(0, 70)}
            ...
          </div>
          <div className="small alignTextLeft greyText removemargin">
            {timeAgo(data?.createdAt)}
          </div>
        </div>
      </div>
    </button>
  );
};
