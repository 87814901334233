import { useContext, useEffect, useState } from "react";
import "../../../styles/index.css";
import "../wallet.css";
import AppContext from "../../../context/app-context.ts";
import { capitalizeEachWord, formatNumber, getDateValue } from "../../../utils/index.ts";
import { TAWalletTransaction } from "../../../context/interface.ts";
import { TWalletNavs } from "../wallet.tsx";
import { IoMdArrowBack } from "react-icons/io";
import { GoArrowUpRight } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { CSVLink } from "react-csv";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { WalletSubscriptionTable } from "../../../components/wallet-subscription-table/wallet-subscription-table.tsx";

type Props = {
  display: boolean;
  setScreen: React.Dispatch<React.SetStateAction<TWalletNavs>>;
};

export const WalletManageSubscriptions = function ({ display, setScreen }: Props) {
  const [page, setPage] = useState(0);
  const [originalData, setOriginalData] = useState<any>({});
  const [subTableData, setSubTableData] = useState<any[]>([]);
  const [csvData, setcsvData] = useState<any[]>([]);
  const { usersData, setIsLoading, openNotification, backendServer, setSubscriptionModal } =
    useContext(AppContext);

  useEffect(() => {
    getWalletTableData();
  }, [usersData, display]);

  async function getWalletTableData() {
    try {
      if (!display || !usersData?._id) return;
      setPage(0);
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/users/subscription/paystack/get/${usersData?._id}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setSubTableData(returned?.data?.invoices);
      setOriginalData(returned?.data);
      workCSV(returned?.data?.invoices);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setScreen({
        screen: "home",
        data: null,
      });
      openNotification("We are unable to fetch your transactions right now.");
    }
  }

  async function getEditSubscriptionLink() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/users/subscription/paystack/edit/${usersData?._id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            subscription_code: usersData?.billing_details?.subscription_code,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      window.open(returned?.data?.link, "_blank");

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification(
        "Unfortunately, you cannot edit your subscription right now, please try again later.",
        "Subscription Error"
      );
    }
  }

  function workCSV(data: any) {
    setcsvData([
      ["Invoice ID", "Billing Date", "Status", "Amount (₦)", "Plan"],
      ...data?.map(({ id, paid_at, status, amount, metadata }: any) => {
        return [
          id,
          getDateValue(paid_at),
          status,
          formatNumber(amount / 100),
          metadata?.order?.plan,
        ];
      }),
    ]);
  }

  if (!display) return <></>;

  return (
    <div className="screen-container subcription-screen-container">
      <p
        className="small cursor fix-back-button"
        onClick={() =>
          setScreen({
            screen: "home",
            data: null,
          })
        }
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <h3 className="vbig-2 blackText">Manage Subscriptions</h3>

      <div className="flex-row w-sub-card-container">
        <div className="w-sub-cards w-sub-cards-first">
          <div className="flex-row space-between">
            <p className="big blackText boldText removemargin">
              {usersData?.plan} Plan &nbsp;
              <span
                className={`small ${
                  originalData?.status === "active" || originalData?.status === "completed"
                    ? "w-status-active"
                    : "w-status-inactive"
                }`}
              >
                {capitalizeEachWord(originalData?.status)}
              </span>
            </p>

            <p className="biggerText boldText">
              {originalData?.amount / 100} &nbsp;
              <span className="xsmall greyText no-bold">{originalData?.plan?.interval}</span>
            </p>
          </div>

          <div className="flex-row space-between">
            <p className="small blackText boldText removemargin">
              <span className="small greyText no-bold">Next Billing Date</span>
              <br></br>
              {getDateValue(originalData?.next_payment_date)}
            </p>
          </div>

          <hr className="w-hr"></hr>

          <div className="flex-row space-between">
            <p
              onClick={() => setSubscriptionModal({ display: true })}
              className="small blueText cursor removemargin"
            >
              Upgrade Plan <GoArrowUpRight size={20} />
            </p>
          </div>
        </div>

        <div className="w-sub-cards w-sub-cards-second">
          <div className="flex-row space-between">
            <p className="big blackText boldText removemargin">
              Payment Method
              <br></br>
              <span className="small greyText no-bold">Change how you pay for your plan</span>
            </p>
          </div>

          <div className="flex-row space-between">
            <p className="small blackText boldText removemargin">
              {capitalizeEachWord(originalData?.authorization?.card_type)} ending with{" "}
              {originalData?.authorization?.last4}
              <br></br>
              <span className="xsmall greyText no-bold">
                Expiry {originalData?.authorization?.exp_month}/
                {originalData?.authorization?.exp_year}
              </span>
              <br></br>
              <span className="xsmall greyText no-bold">
                <MdEmail color="black" size={20} /> {originalData?.customer?.email}
              </span>
            </p>
            <img
              className="cursor"
              title="Edit Card Details"
              src="/images/icons/edit-icon.png"
              alt="Edit Card Details"
              onClick={getEditSubscriptionLink}
            />
          </div>
        </div>
      </div>

      <p className="big" style={{ marginTop: 40 }}>
        Subscription Transaction History
      </p>

      <div className="w-table-container">
        <div className="table-utilities">
          <div className="table-utilities-extra">
            <div className="flex-row wallet-table-nav"></div>
          </div>
          <div className="table-utilities-icons">
            <CSVLink className="cursor small csv-link" filename={`Transaction Data`} data={csvData}>
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>

            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={getWalletTableData}
            />
          </div>
        </div>
        <WalletSubscriptionTable page={page} setPage={setPage} data={subTableData} />
      </div>
    </div>
  );
};
