import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../banks.css";
import AppContext from "../../../context/app-context.ts";
import { TBankNavs } from "../banks.tsx";
import { StatCard } from "../../../components/stat-card/stat-card.tsx";
import { VerificationIndicators } from "../../../components/verification-indicators/verification-indicators.tsx";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { CSVLink } from "react-csv";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import APopupModal from "../../../components/a-typical-popup-container/a-typical-popup-container.tsx";
import { BanksTable } from "../../../components/banks-table/banks-table.tsx";
import { IoIosSearch } from "react-icons/io";
import { FloralDivider } from "../../../components/floral-divider/floral-divider.tsx";
import { IoOptionsOutline } from "react-icons/io5";
import { IBanks, TACompletedJob, TAJob, TAWalletTransaction } from "../../../context/interface.ts";
import {
  capitalizeEachWord,
  formatNumber,
  getTotalNumberFromObjectArray,
  patternMatching,
  transformFieldToAwsUrl,
} from "../../../utils/index.ts";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TBankNavs>>;
  display: boolean;
};

type TBanksSortByOptions = "Available Jobs" | "Active Banks";

export const Home = function ({ display, setScreen }: Props) {
  const [page, setPage] = useState(0);

  const [transformedBanksData, setTransformedBanksData] = useState<IBanks[]>([]);
  const [originalData, setOriginalData] = useState<IBanks[]>([]);
  const [usersBanks, setUsersBanks] = useState<IBanks[]>([]);
  const [totals, setTotals] = useState({
    pending: 0,
    succesful: 0,
    all: 0,
  });
  const [csvData, setcsvData] = useState<any[]>([]);
  const [showFilters, setShowFilters] = useState({
    sortBy: {
      show: true,
      filtersState: "none" as TBanksSortByOptions,
    },
  });

  const [sortState, setSortState] = useState<Record<TBanksSortByOptions, boolean>>({
    "Available Jobs": true,
    "Active Banks": true,
  });

  const [searchValue, setSearchValue] = useState("");

  const { openNotification, setIsLoading, usersData, contextBanks } = useContext(AppContext);

  useEffect(() => {
    setTotals((p) => ({
      ...p,
      all: getTotalNumberFromObjectArray(
        usersData?.jobs?.flatMap((e) => e?.completed),
        "total_number_claimed_done"
      ),
    }));

    setTotals((p) => ({
      ...p,
      pending: getTotalNumberFromObjectArray(
        usersData?.jobs
          ?.flatMap((e: TAJob) => e?.completed)
          ?.filter((e: TACompletedJob) => e.status === "pending"),
        "total_number_claimed_done"
      ),
    }));

    setTotals((p) => ({
      ...p,
      succesful: getTotalNumberFromObjectArray(
        usersData?.jobs
          ?.flatMap((e: TAJob) => e?.completed)
          ?.filter((e: TACompletedJob) => e.status === "approved"),
        "total_number_claimed_done"
      ),
    }));
  }, [usersData]);

  useEffect(() => {
    getBanks();
  }, [usersData, contextBanks]);

  useEffect(() => {}, [sortState]);

  async function getBanks() {
    try {
      setPage(0);
      setIsLoading(true);

      const transformedData = await transformFieldToAwsUrl([...contextBanks], "logo", "logos");

      setTransformedBanksData(transformedData);
      setOriginalData(transformedData);
      workCSV(transformedData);

      const allBanks = [...contextBanks];

      const allTheUsersBanks: IBanks[] = [];

      allBanks?.forEach((e) => {
        usersData?.jobs?.forEach((job) => {
          if (job?.job_id?.for_sector === "banks" && job?.job_id?.based_on === e?._id)
            if (!allTheUsersBanks?.find((e) => e?._id === job?.job_id?.based_on))
              allTheUsersBanks.push(e);
        });
      });

      setUsersBanks(allTheUsersBanks);

      setIsLoading(false);
    } catch (err) {
      openNotification("We are unable to fetch the banks right now. Please try again later.");
      setIsLoading(false);
    }
  }

  async function searchPlease(e: any) {
    setPage(0);
    const query = e.target.value;

    if (!query) {
      setTransformedBanksData(originalData);
      workCSV(originalData);
      return;
    }

    const foundBanks = originalData?.filter((e: IBanks) => {
      return patternMatching(capitalizeEachWord(query), capitalizeEachWord(e.bank_name));
    });

    setTransformedBanksData(foundBanks);
    workCSV(foundBanks);
  }

  function sortFilter(type: TBanksSortByOptions) {
    setPage(0);
    if (type === "Active Banks") {
      if (sortState["Active Banks"]) {
        setTransformedBanksData(
          [...originalData]?.sort((a: IBanks, b: IBanks) => {
            if (a?.is_active > b?.is_active) return -1;
            else return 1;
          })
        );
        workCSV(
          [...originalData]?.sort((a: IBanks, b: IBanks) => {
            if (a?.is_active > b?.is_active) return -1;
            else return 1;
          })
        );
        setSortState((p) => ({ ...p, "Active Banks": !sortState?.["Active Banks"] }));
      } else {
        setTransformedBanksData(
          [...originalData]?.sort((a: IBanks, b: IBanks) => {
            if (a?.is_active > b?.is_active) return 1;
            else return -1;
          })
        );
        workCSV(
          [...originalData]?.sort((a: IBanks, b: IBanks) => {
            if (a?.is_active > b?.is_active) return 1;
            else return -1;
          })
        );
        setSortState((p) => ({ ...p, "Active Banks": !sortState?.["Active Banks"] }));
      }
    }
    if (type === "Available Jobs") {
      if (sortState["Available Jobs"]) {
        setTransformedBanksData(
          [...originalData]?.sort((a: IBanks, b: IBanks) => {
            if (a?.jobs?.length > b?.jobs?.length) return -1;
            else return 1;
          })
        );
        workCSV(
          [...originalData]?.sort((a: IBanks, b: IBanks) => {
            if (a?.jobs?.length > b?.jobs?.length) return -1;
            else return 1;
          })
        );
        setSortState((p) => ({ ...p, "Available Jobs": !sortState?.["Available Jobs"] }));
      } else {
        setTransformedBanksData(
          [...originalData]?.sort((a: IBanks, b: IBanks) => {
            if (a?.jobs?.length > b?.jobs?.length) return 1;
            else return -1;
          })
        );
        workCSV(
          [...originalData]?.sort((a: IBanks, b: IBanks) => {
            if (a?.jobs?.length > b?.jobs?.length) return 1;
            else return -1;
          })
        );
        setSortState((p) => ({ ...p, "Available Jobs": !sortState?.["Available Jobs"] }));
      }
    }
  }

  function workCSV(data: any) {
    setcsvData([
      ["Bank Name", "Jobs", "Status"],
      ...data?.map(({ is_active, jobs, bank_name }: any) => {
        return [bank_name, jobs?.length, is_active ? "Active" : "Inactive"];
      }),
    ]);
  }

  if (!display) return <></>;

  return (
    <div className="screen-container banks-container">
      <div className="banks-intro-container">
        <div className="banks-intro-container-main">
          <div className="banks-intro-left flex-column align-column-left">
            <h3 className="vBig blackText">
              {" "}
              Welcome, {usersData?.first_name}
            </h3>
            <p className="biggerText greyText boldText">Agent: {usersData?.platform_id}</p>{" "}
            <p className="small greyText boldText">
              Unlock your potential within the DDSA Banks. Explore the wide array of jobs available
              for you.
            </p>
          </div>

          <div className="banks-intro-right flex-column">
            <VerificationIndicators />
          </div>
        </div>
      </div>

      <div className="flex-row ds-stat-container-row">
        <StatCard
          iconSrc="/images/dashboard/tjd.png"
          count={totals?.all}
          actionPlan={
            <APopupModal
              trigger="click"
              button={
                <p className="small cursor">
                  Total Jobs Done
                  <MdOutlineKeyboardArrowDown onClick={() => {}} size={20} />
                </p>
              }
              content={
                <div className="popup-common-filter-dropdown">
                  <div className="normal-flex-column small-gap">
                    <p
                      onClick={() => {
                        setTotals((p) => ({
                          ...p,
                          all: getTotalNumberFromObjectArray(
                            usersData?.jobs?.flatMap((e) => e?.completed),
                            "total_number_claimed_done"
                          ),
                        }));
                      }}
                      key={0}
                      className="small removemargin cursor"
                    >
                      All Banks
                    </p>
                    {usersBanks?.map((e: IBanks, i: number) => (
                      <p
                        onClick={() => {
                          setTotals((p) => ({
                            ...p,
                            all: getTotalNumberFromObjectArray(
                              usersData?.jobs
                                ?.filter((job) => job?.job_id?.based_on === e?._id)
                                ?.flatMap((e) => e?.completed),
                              "total_number_claimed_done"
                            ),
                          }));
                        }}
                        key={i + 1}
                        className="small removemargin cursor"
                      >
                        {e?.bank_name}
                      </p>
                    ))}
                  </div>
                </div>
              }
            />
          }
        />
        <StatCard
          iconSrc="/images/dashboard/pj.png"
          count={totals?.pending}
          actionPlan={
            <APopupModal
              trigger="click"
              button={
                <p className="small cursor">
                  Pending Jobs
                  <MdOutlineKeyboardArrowDown onClick={() => {}} size={20} />
                </p>
              }
              content={
                <div className="popup-common-filter-dropdown">
                  <div className="normal-flex-column small-gap">
                    <p
                      onClick={() => {
                        setTotals((p) => ({
                          ...p,
                          pending: getTotalNumberFromObjectArray(
                            usersData?.jobs
                              ?.flatMap((e: TAJob) => e?.completed)
                              ?.filter((e: TACompletedJob) => e.status === "pending"),
                            "total_number_claimed_done"
                          ),
                        }));
                      }}
                      key={0}
                      className="small removemargin cursor"
                    >
                      All Banks
                    </p>
                    {usersBanks?.map((e: IBanks, i: number) => (
                      <p
                        onClick={() => {
                          setTotals((p) => ({
                            ...p,
                            pending: getTotalNumberFromObjectArray(
                              usersData?.jobs
                                ?.filter((job) => job?.job_id?.based_on === e?._id)
                                ?.flatMap((e: TAJob) => e?.completed)
                                ?.filter((e: TACompletedJob) => e.status === "pending"),
                              "total_number_claimed_done"
                            ),
                          }));
                        }}
                        key={i + 1}
                        className="small removemargin cursor"
                      >
                        {e?.bank_name}
                      </p>
                    ))}
                  </div>
                </div>
              }
            />
          }
        />
        <StatCard
          iconSrc="/images/dashboard/sj.png"
          count={totals?.succesful}
          actionPlan={
            <APopupModal
              trigger="click"
              button={
                <p className="small cursor">
                  Successful Jobs
                  <MdOutlineKeyboardArrowDown onClick={() => {}} size={20} />
                </p>
              }
              content={
                <div className="popup-common-filter-dropdown">
                  <div className="normal-flex-column small-gap">
                    <p
                      onClick={() => {
                        setTotals((p) => ({
                          ...p,
                          succesful: getTotalNumberFromObjectArray(
                            usersData?.jobs
                              ?.flatMap((e: TAJob) => e?.completed)
                              ?.filter((e: TACompletedJob) => e.status === "approved"),
                            "total_number_claimed_done"
                          ),
                        }));
                      }}
                      key={0}
                      className="small removemargin cursor"
                    >
                      All Banks
                    </p>
                    {usersBanks?.map((e: IBanks, i: number) => (
                      <p
                        onClick={() => {
                          setTotals((p) => ({
                            ...p,
                            succesful: getTotalNumberFromObjectArray(
                              usersData?.jobs
                                ?.filter((job) => job?.job_id?.based_on === e?._id)
                                ?.flatMap((e: TAJob) => e?.completed)
                                ?.filter((e: TACompletedJob) => e.status === "approved"),
                              "total_number_claimed_done"
                            ),
                          }));
                        }}
                        key={i + 1}
                        className="small removemargin cursor"
                      >
                        {e?.bank_name}
                      </p>
                    ))}
                  </div>
                </div>
              }
            />
          }
        />
        <StatCard
          iconSrc="/images/dashboard/wallet.png"
          count={formatNumber(
            getTotalNumberFromObjectArray(
              usersData?.wallet?.transactions?.filter(
                (e: TAWalletTransaction) => e?.transaction_type === "credit"
              ),
              "amount"
            )
          )}
          actionPlan={
            <p className="small">
              Earnings
              {/* <MdOutlineKeyboardArrowDown onClick={() => {}} size={20} /> */}
            </p>
          }
        />
      </div>

      <FloralDivider
        customStyles={{
          justifyContent: "flex-start",
          backgroundImage: "none",
          padding: "0px 30px 0px 30px",
        }}
        header={"Jobs Opened and Available"}
      />

      <div className="banks-table-container">
        <div className="table-utilities">
          <div className="table-utilities-extra">
            <div className="attached-in-cont-with-icon-no-button banks-table-search">
              <div>
                <IoIosSearch />
              </div>
              <input
                type="text"
                className="in"
                placeholder="Search banks..."
                onChange={(e) => {
                  setSearchValue(e?.target?.value);
                  searchPlease(e);
                }}
                value={searchValue}
              />
            </div>
          </div>
          <div className="table-utilities-icons">
            <APopupModal
              trigger="click"
              button={
                <IoOptionsOutline
                  className="cursor"
                  title="Sort Data"
                  size={25}
                  onClick={() => {}}
                />
              }
              content={
                <div className="popup-common-filter-dropdown banks-table-dropdown">
                  <p
                    className="small removemargin boldText cursor"
                    onClick={() => {
                      setShowFilters((p) => ({
                        ...p,
                        sortBy: {
                          ...p?.sortBy,
                          show: !p?.sortBy?.show,
                        },
                      }));
                    }}
                  >
                    Sort By <MdOutlineKeyboardArrowDown size={25} style={{ marginTop: -3 }} />
                  </p>

                  {showFilters?.sortBy?.show && (
                    <div className="normal-flex-column small-gap">
                      <p
                        onClick={() => {
                          setShowFilters((p) => ({
                            ...p,
                            sortBy: {
                              ...p?.sortBy,
                              filtersState: "Available Jobs",
                            },
                          }));
                          sortFilter("Available Jobs");
                        }}
                        className="small removemargin cursor"
                      >
                        Jobs Available
                      </p>

                      <p
                        onClick={() => {
                          setShowFilters((p) => ({
                            ...p,
                            sortBy: {
                              ...p?.sortBy,
                              filtersState: "Active Banks",
                            },
                          }));
                          sortFilter("Active Banks");
                        }}
                        className="small removemargin cursor"
                      >
                        Active Banks
                      </p>
                    </div>
                  )}
                </div>
              }
            />

            <CSVLink className="cursor small csv-link" filename={`DDSA Banks Data`} data={csvData}>
              <BsPrinter title="Print CSV" size={20} />
            </CSVLink>

            <FiRefreshCcw
              className="cursor"
              title="Refresh Data"
              size={20}
              onClick={() => getBanks()}
            />
          </div>
        </div>
        <BanksTable
          page={page}
          setPage={setPage}
          data={transformedBanksData}
          setScreen={setScreen}
        />
      </div>
    </div>
  );
};
