import React, { useContext, useState } from "react";
import "../../../styles/index.css";
import "../profile.css";
import AppContext from "../../../context/app-context.ts";
import { MDBInput } from "mdb-react-ui-kit";

type Prop = {
  setEditProfileMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditProfileBasicInfo = ({ setEditProfileMode }: Prop) => {
  const { backendServer, openNotification, setIsLoading, updateUsersData, usersData } =
    useContext(AppContext);

  const [changedState, setChangedState] = useState({
    first_name: usersData?.first_name,
    last_name: usersData?.last_name,
    mobile: usersData?.mobile,
    address: {
      addressString: usersData?.address?.addressString,
    },
  });

  async function updateData() {
    try {
      if (
        !changedState?.first_name ||
        !changedState?.last_name ||
        !changedState?.mobile ||
        !changedState?.address?.addressString
      )
        return;

      setIsLoading(true);

      const obj: any = {
        address: {
          addressString: changedState?.address?.addressString || usersData?.address?.addressString,
        },
      };

      if (changedState?.first_name !== usersData?.first_name)
        obj.first_name = changedState?.first_name;

      if (changedState?.last_name !== usersData?.last_name) obj.last_name = changedState?.last_name;

      if (changedState?.mobile !== usersData?.mobile) obj.mobile = changedState?.mobile;

      if (changedState?.address?.addressString !== usersData?.address?.addressString)
        obj.address.addressString = changedState?.address?.addressString;

      const returned = await (
        await fetch(`${backendServer}/users/profile/edit/${usersData?._id}`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("ddsatoken") || "",
          },
          body: JSON.stringify(obj),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      updateUsersData();

      openNotification("Profile update successful!");

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification(
        "We can't update your profile details right now. Try again after some time."
      );
    }
  }

  return (
    <div className="profile-section-box profile-info-box">
      <div className="normal-flex-row space-between">
        <p className="big removemargin">Personal Information</p>

        <div className="normal-flex-row small-gap">
          <button
            style={{ border: "0.4px solid #AB2727" }}
            className="themeBtn whiteBg blackText"
            onClick={() => setEditProfileMode(false)}
          >
            Cancel
          </button>

          <button onClick={updateData} className="themeBtn">
            Save Changes
          </button>
        </div>

        <MDBInput
          title="First Name"
          label="First Name"
          type="text"
          wrapperClass="mdbIn mdbInFullWidth profile-inputs"
          onChange={(e) => setChangedState((p) => ({ ...p, first_name: e.target?.value }))}
          labelClass="mdb-dropdown-custom-in"
          value={changedState?.first_name}
        />

        <MDBInput
          title="Last Name"
          label="Last Name"
          type="text"
          wrapperClass="mdbIn mdbInFullWidth profile-inputs"
          onChange={(e) => setChangedState((p) => ({ ...p, last_name: e.target?.value }))}
          labelClass="mdb-dropdown-custom-in"
          value={changedState?.last_name}
        />

        <MDBInput
          title="Phone Number"
          label="Phone Number"
          type="text"
          wrapperClass="mdbIn mdbInFullWidth profile-inputs"
          onChange={(e) => setChangedState((p) => ({ ...p, mobile: e.target?.value }))}
          labelClass="mdb-dropdown-custom-in"
          value={changedState?.mobile}
        />

        <MDBInput
          title="Address"
          label="Address"
          type="text"
          wrapperClass="mdbIn mdbInFullWidth profile-inputs"
          onChange={(e) =>
            setChangedState((p) => ({ ...p, address: { addressString: e.target?.value } }))
          }
          labelClass="mdb-dropdown-custom-in"
          value={changedState?.address?.addressString}
        />
      </div>
    </div>
  );
};
